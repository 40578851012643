import React, { useState } from "react";

export function AccessPolicyGochiKidReceiptModal(props) {
  const [policy, setPolicy] = useState(false);

  const handleGoToMenuPrice = () => {
    if (props.choiceMenuId) {
      const accessToken = props.accessToken
        ? "&accessToken=" + props.accessToken
        : "";
      const accessCode = props.accessCode
        ? "&accessCode=" + props.accessCode
        : "";
      const gochiKidGroupId = props.gochiKidGroupId
        ? "&gochiKidGroupId=" + props.gochiKidGroupId
        : "";
      let arr = [];
      props.choiceUserKidIds.forEach((id) => {
        arr.push(`userKidIds=${id}`);
      });
      let userKidIds = arr.join("&");
      if (props.gochiKidUseCodeShopChoice === 1) {
        window.location.href = `/kodomo/use?gochiKidUseCode=1&shopId=${props.choiceShopId}&menuId=${props.choiceMenuId}${gochiKidGroupId}${accessToken}${accessCode}`;
      } else {
        window.location.href = `/kodomo/use?shopId=${props.choiceShopId}&menuId=${props.choiceMenuId}&${userKidIds}${gochiKidGroupId}${accessToken}${accessCode}`;
      }
    } else {
      props.handleClose(false);
      props.handleOpenInputGochiKidPriceModal(
        props.choiceShopId,
        props.gochiKidUnlimitShopChoice
      );
    }
  };

  return (
    <div
      id={5342}
      className={`modal modal-profile modal-profile-01 modal-01 modal-lg history-modal policy-gochi-kid-modal policy-accept-gochi-kid-receipt-modal ${
        props.show ? "is-show" : ""
      }`}
      tabIndex={-1}
    >
      <div className="modal-header-history">
        <button
          className="modal-btn modal-btn-close"
          id="modalClose"
          onClick={() => props.handleClose(true)}
          type="button"
        >
          <svg className="icon">
            <title>閉じる</title>
            <use xlinkHref="/assets/img/common/icon.svg#times" />
          </svg>
        </button>
      </div>
      <div className={`modal-body`}>
        <div className="title">
          レシート利用対応店舗
          <br />
          利用規約
        </div>
        <div className="content-container">
          <div className="body-content">
            <ui>
              <li>
                「こどもごちめし 」は、お子さまの飲食費を支援するサービスです。
              </li>
              <li>
                <span className="text-red text-line-through">
                  お子さまの飲食品以外のご利用・購入は、次回以降ご利用いただけなくなりますのでご注意ください。
                </span>
                <span className="text-red">
                  ※なお、“【お試し企画】ローソンこども食堂”実施期間中は、この限りではありません。
                </span>
              </li>
              <li>
                次回以降ご利用のために、お支払い完了画面表示の際に
                <span className="text-red">
                  必ずレシートをお受け取りください。
                </span>
              </li>
              <li>
                <span className="text-red">
                  受け取ったレシートは、次回以降のご利用時に登録が必要です。
                </span>
              </li>
              <li>
                <span className="text-line-through">
                  レシートに記載されるお子さまの飲食品以外（例：日用品や雑貨など）の登録については、非承認となります。ご利用の際は、必ず飲食品のみの利用・購入をお願い致します。
                </span>
                ※なお、“【お試し企画】ローソンこども食堂”実施期間中は、この限りではありません。
              </li>
            </ui>
          </div>

          <div className="footer-content">
            皆さまのご理解とご協力をお願いいたします。
          </div>
        </div>

        <div className="checkbox-container">
          <div className="field field-checkbox policy-text-box">
            <label>
              <input
                className="before-icon"
                type="checkbox"
                checked={policy}
                onChange={(e) => {
                  setPolicy(e.target.checked);
                }}
              />
              <i className="icon" />
            </label>
            <div className="policy-text-description">
              上記利用規約に同意する
            </div>
          </div>
        </div>

        <div className="btn-container">
          <button
            className="btn btn-rect btn-accept-policy"
            disabled={!policy}
            onClick={() => handleGoToMenuPrice()}
          >
            次へ
          </button>
          <button
            onClick={() => props.handleClose(true)}
            className={"btn btn-outline-rect btn-text"}
          >
            前の画面に戻る
          </button>
        </div>
      </div>
    </div>
  );
}
