import React, { useState, useEffect } from "react";
import MetaTags from 'react-meta-tags';
import queryString from 'query-string';
import moment from 'moment';
import { CustomPagination } from "../../common/Pagination";
import {
  addParamUrl
} from "../../../../utils";
import loadImage from "blueimp-load-image";
import { GochiKidPreviewImageReceiptModal } from "./GochiKidPreviewImageReceiptModal";
import { GochiKidErrorUploadReceiptModal } from "./GochiKidErrorUploadReceiptModal";
import { GochiKidReceiptResultModal } from "./GochiKidReceiptResultModal";
import { GochiKidReceiptConfirmModal } from "./GochiKidReceiptConfirmModal";
import { GochiKidReceiptSuccessModal } from "./GochiKidReceiptSuccessModal";
import LoadingOverlay from "../../common/LoadingOverlay";
import ReceiptService from "../../../../api/services/GochiKid/ReceiptService";
import { HTTP_API } from "../../../../api/HttpClient";
import { GochiKidSurveyModal } from "./GochiKidSurveyModal";
import { SURVEY_QUESTION_TYPE } from "../../../../constants";
import MenuService from "../../../../api/services/MenuService";

export function GochiKidUsed(props) {
  const paramsUrl = queryString.parse(window.location.search);
  const isSite = paramsUrl.isSite;
  const isSiteParam = isSite ? {name: 'isSite', value: isSite} : {};
  const [isLoadMoreBoxStatus, setIsLoadMoreBoxStatus] = useState(false);
  const [imageReceiptFile, setImageReceiptFile] = useState();
  const [imageReceiptFileUrl, setImageReceiptFileUrl] = useState();
  const [showPreviewImgReceiptModal, setShowPreviewImgReceiptModal] = useState(false);
  const [showReceiptResultModal, setShowReceiptResultModal] = useState(false);
  const [showReceiptConfirmModal, setShowReceiptConfirmModal] = useState(false);
  const [showReceiptSuccessModal, setShowReceiptSuccessModal] = useState(false);
  const [showErrorReceiptModal, setShowErrorReceiptModal] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [isErrorReceipt, setIsErrorReceipt] = useState(false);
  const [messageErrorReceiptModal, setMessageErrorReceiptModal] = useState('');
  const [messageErrorModal, setMessageErrorModal] = useState('');
  const [titleReceiptSuccessModal, setTitleReceiptSuccessModal] = useState('');
  const [messageReceiptSuccessModal, setMessageReceiptSuccessModal] = useState('');
  const [uploadReceiptFor, setUploadReceiptFor] = useState('');
  const [gochiKidUseIdChoose, setGochiKidUseIdChoose] = useState(null);
  const [receiptChoose, setReceiptChoose] = useState(null);
  const [itemReceiptArr, setItemReceiptArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [receiptImageBase64, setReceiptImageBase64] = useState(null);

  const [surveyDetails, setSurveyDetails] = useState(null);
  const questions = surveyDetails?.questions || [];
  const [answers, setAnswers] = useState([]);

  const uploadReceiptForStatus = {
    UPLOAD: 'UPLOAD',
    EDIT: 'EDIT',
    REUPLOAD: 'REUPLOAD'
  }

  useEffect(() => {
    //
  }, []);

  const onChangeImgUpload = async (event, gochiKidUseId, uploadFor) => {
    console.log('onChangeImgUpload', event);
    setGochiKidUseIdChoose(gochiKidUseId);

    if (uploadFor) {
      setUploadReceiptFor(uploadFor);
    }

    const file = event.target.files[0];
    // const image = await resizeFile(file);
    let fileName = file.name;
    const re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
    if (!re.exec(fileName)) {
      handleOpenUploadReceiptErrorModal('アップロード可能なファイル形式はJPEG、JPG、PNGのみです。正しい形式のレシート画像を再選択してください。');
      return;
    }

    var ext = fileName.split('.').pop();
    fileName = 'receipt' + gochiKidUseId + '-' + Date.now() + '.' + ext;
    const { resizeImageFile } = await resizeImage(file);
    const image = new File([resizeImageFile], fileName, { type: file.type });
    setImageReceiptFile(image);
    setImageReceiptFileUrl(URL.createObjectURL(image));
    setShowPreviewImgReceiptModal(true);
  };

  const resizeImage = (image) => {
    return new Promise((resolve, reject) => {
      loadImage.parseMetaData(image, (data) => {
        const options = {
          maxWidth: process.env.REACT_APP_RESIZE_MAX_WIDTH,
          canvas: true,
        };

        if (data.exif) {
          options.orientation = data.exif.get('Orientation');
        }

        let fileName = image.name;
        loadImage(
          image,
          (canvas) => {
            try {
              const imageUri = canvas.toDataURL('image/*');

              const resizeImageFile = toBlob(imageUri, fileName, reject);
              resolve({
                resizeImageFile,
              });
            } catch (error) {
              handleOpenUploadReceiptErrorModal('アップロード可能なファイル形式はJPEG、JPG、PNGのみです。正しい形式のレシート画像を再選択してください。');
              return;
            }
          },
          options
        );
      });
    });
  };

  const toBlob = (base64, fileName, reject) => {
    const bin = atob(base64.replace(/^.*,/, ''));
    const buffer = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; i += 1) {
      buffer[i] = bin.charCodeAt(i);
    }

    try {
      const blob = new Blob([buffer.buffer], {
        type: 'image/*',
      });
      blob.name = fileName;
      return blob;
    } catch (e) {
      reject();
      return false;
    }
  };

  const handleClosePreviewImageModal = () => {
    setImageReceiptFile('');
    setImageReceiptFileUrl('');
    setShowPreviewImgReceiptModal(false);
  }

  const handleUploadReceipt = async () => {
    setLoading(true);
    setReceiptImageBase64(null);

    try {
      const response = await ReceiptService.uploadGochiKidReceipt(gochiKidUseIdChoose, imageReceiptFile);

      if (response.status === HTTP_API.SUCCESS) {
        setReceiptChoose(response.data);
        setItemReceiptArr(response.data.ocrDetails ? response.data.ocrDetails : []);
        setReceiptImageBase64({
          image: imageReceiptFileUrl,
          imageBase64: null
        })
        setShowReceiptResultModal(true);
        setShowPreviewImgReceiptModal(false);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      const errorInfo = error.response?.data?.errorInfo;

      if (errorInfo?.code === "exception.errors.ocr.payment.date.error" || errorInfo?.code === "exception.errors.ocr.shop.not.found") {
        handleOpenUploadReceiptErrorModal('利用履歴の情報とレシートの内容が一致しません。利用履歴に対応するレシート画像を再選択してください。');
      } else if (errorInfo?.code === "exception.errors.gochikid.ocr.validation.failure") {
        handleOpenUploadReceiptErrorModal(
          <>
            レシート画像の読み取りができませんでした。以下の内容をご確認いただき、レシート画像を再選択してください。
            <br />
            <br />
            【ご確認事項】
            <br />
            1.レシート全体が鮮明に写っているか（文字がはっきり読み取れる状態）。
            <br />
            2.レシートが破損していないか、必要な情報（日付、店舗名、品目、税種別など）が欠けていないか。
            <br />
            3.ネットワーク接続が安定しているか（通信エラーの可能性がある場合）。
            <br />
            <br />
            上記を確認後、問題が解消しない場合は下記サイトからお問い合わせください。
            <br />
            <a href="https://support.gigi.tokyo/contact/kodomogochimeshi-receipt/" target="_blank">https://support.gigi.tokyo/contact/kodomogochimeshi-receipt/</a>
          </>
        );
      } else {
        handleOpenUploadReceiptErrorModal(
          <>
            内部システムでエラーが発生しました。お手数ですが、下記サイトからお問い合わせください。
            <br />
            <a href="https://support.gigi.tokyo/contact/for-user/" target="_blank">https://support.gigi.tokyo/contact/for-user/</a>
          </>
        );
      }

      setLoading(false);
    }
  }

  const handleCloseGochiKidResultModal = () => {
    if (uploadReceiptFor === uploadReceiptForStatus.EDIT) {
      setShowReceiptResultModal(false);
    } else {
      setShowReceiptResultModal(false);
      setShowPreviewImgReceiptModal(true);
    }
  }

  const handleOpenUploadReceiptErrorModal = message => {
    setMessageErrorReceiptModal(message);
    setShowPreviewImgReceiptModal(false);
    setShowErrorReceiptModal(true);
  }

  const handleCloseErrorReceiptModal = () => {
    setMessageErrorReceiptModal('');
    setShowErrorReceiptModal(false);
  }

  const handleOpenGochiKidReceiptConfirmModal = () => {
    setShowReceiptConfirmModal(true);
    setShowReceiptResultModal(false);
  }

  const handleCloseGochiKidReceiptConfirmModal = () => {
    setShowReceiptConfirmModal(false);
    setShowReceiptResultModal(true);
  }

  const handleSubmitGochiKidReceipt = async () => {
    if (uploadReceiptFor === uploadReceiptForStatus.EDIT) {
      const params = {
        gochiKidReceiptId: receiptChoose.id,
        receiptDetails: []
      };
      let listItemReceipt = [];
  
      itemReceiptArr.forEach(item => {
        const tempItem = {
          gochiKidReceiptDetailId: item.id,
          amount: item.amount,
          taxRate: item.taxRate
        };
        listItemReceipt.push(tempItem);
      });
      params.receiptDetails = listItemReceipt;
      setLoading(true);
  
      try {
        const response = await ReceiptService.updateGochiKidReceipt(params);
  
        if (response.status === HTTP_API.SUCCESS) {
          setShowReceiptSuccessModal(true);
          setTitleReceiptSuccessModal('レシート登録 確認');
          setMessageReceiptSuccessModal('登録確定しました！');
          setShowReceiptConfirmModal(false);
        } else {
          setShowReceiptConfirmModal(false);
          setShowErrorModal(true);
          setIsErrorReceipt(true);
          setMessageErrorModal(
            <>
              内部システムでエラーが発生しました。お手数ですが、下記サイトからお問い合わせください。
              <br />
              <a href="https://support.gigi.tokyo/contact/for-user/" target="_blank">https://support.gigi.tokyo/contact/for-user/</a>
            </>
          );
        }
      } catch (e) {
        console.log('Submit receipt fail', e);
        setShowReceiptConfirmModal(false);
        setShowErrorModal(true);
        setIsErrorReceipt(true);
        setMessageErrorModal(
          <>
            内部システムでエラーが発生しました。お手数ですが、下記サイトからお問い合わせください。
            <br />
            <a href="https://support.gigi.tokyo/contact/for-user/" target="_blank">https://support.gigi.tokyo/contact/for-user/</a>
          </>
        );
      }
  
      setLoading(false);
    } else {
      const params = {
        gochiKidOcrId: receiptChoose.id,
        ocrDetails: []
      };
      let listItemReceipt = [];
  
      itemReceiptArr.forEach(item => {
        const tempItem = {
          gochiKidOcrDetailId: item.id,
          amount: item.amount,
          taxRate: item.taxRate
        };
        listItemReceipt.push(tempItem);
      });
      params.ocrDetails = listItemReceipt;
      setLoading(true);
  
      try {
        const response = await ReceiptService.submitGochiKidReceipt(params);
  
        if (response.status === HTTP_API.SUCCESS) {
          setShowReceiptSuccessModal(true);
          setTitleReceiptSuccessModal('レシート登録 確認');
          setMessageReceiptSuccessModal('登録確定しました！');
          setShowReceiptConfirmModal(false);
        } else {
          setShowReceiptConfirmModal(false);
          setShowErrorModal(true);
          setIsErrorReceipt(true);
          setMessageErrorModal(
            <>
              内部システムでエラーが発生しました。お手数ですが、下記サイトからお問い合わせください。
              <br />
              <a href="https://support.gigi.tokyo/contact/for-user/" target="_blank">https://support.gigi.tokyo/contact/for-user/</a>
            </>
          );
        }
      } catch (e) {
        console.log('Submit receipt fail', e);
        setShowReceiptConfirmModal(false);
        setShowErrorModal(true);
        setIsErrorReceipt(true);
        setMessageErrorModal(
          <>
            内部システムでエラーが発生しました。お手数ですが、下記サイトからお問い合わせください。
            <br />
            <a href="https://support.gigi.tokyo/contact/for-user/" target="_blank">https://support.gigi.tokyo/contact/for-user/</a>
          </>
        );
      }
  
      setLoading(false);
    }
  }

  const handleCloseReceiptSuccessModal = async () => {
    setShowReceiptSuccessModal(false);
    setReceiptChoose(null);
    setGochiKidUseIdChoose(null);
    setItemReceiptArr([]);
    setLoading(true);
    await props.getUserKidGochiKid(props.page, 'latest');
    setLoading(false);
  }

  const handleCheckReceiptByAI = async receiptId => {
    setLoading(true);
    const params = {
      gochiKidReceiptId: receiptId
    }

    try {
      const response = await ReceiptService.checkReceiptByAi(params);

      if (response.status === HTTP_API.SUCCESS) {
        setShowReceiptSuccessModal(true);
        setTitleReceiptSuccessModal('品目再申請 完了');
        setMessageReceiptSuccessModal(<>再申請を完了しました！<br />申請結果確定までしばらくお待ちください。</>);
      } else {
        setShowErrorModal(true);
        setIsErrorReceipt(false);
        setMessageErrorModal(
          <>
            内部システムでエラーが発生しました。お手数ですが、下記サイトからお問い合わせください。
            <br />
            <a href="https://support.gigi.tokyo/contact/for-user/" target="_blank">https://support.gigi.tokyo/contact/for-user/</a>
          </>
        );
      }
    } catch(e) {
      console.log(e);
      setShowErrorModal(true);
      setIsErrorReceipt(false);
      setMessageErrorModal(
        <>
          内部システムでエラーが発生しました。お手数ですが、下記サイトからお問い合わせください。
          <br />
          <a href="https://support.gigi.tokyo/contact/for-user/" target="_blank">https://support.gigi.tokyo/contact/for-user/</a>
        </>
      );
    }
    setLoading(false);
  }

  const handleCheckReceiptByAdmin = async receiptId => {
    setLoading(true);
    const params = {
      gochiKidReceiptId: receiptId
    }

    try {
      const response = await ReceiptService.checkReceiptByAdmin(params);

      if (response.status === HTTP_API.SUCCESS) {
        setShowReceiptSuccessModal(true);
        setTitleReceiptSuccessModal('再々申請 完了');
        setMessageReceiptSuccessModal(<>再々申請を完了しました。<br />レシート登録内容を確認しますので、しばらくお待ちください。</>);
      } else {
        setShowErrorModal(true);
        setIsErrorReceipt(false);
        setMessageErrorModal(
          <>
            内部システムでエラーが発生しました。お手数ですが、下記サイトからお問い合わせください。
            <br />
            <a href="https://support.gigi.tokyo/contact/for-user/" target="_blank">https://support.gigi.tokyo/contact/for-user/</a>
          </>
        );
      }
    } catch(e) {
      console.log(e);
      setShowErrorModal(true);
      setIsErrorReceipt(false);
      setMessageErrorModal(
        <>
          内部システムでエラーが発生しました。お手数ですが、下記サイトからお問い合わせください。
          <br />
          <a href="https://support.gigi.tokyo/contact/for-user/" target="_blank">https://support.gigi.tokyo/contact/for-user/</a>
        </>
      );
    }

    setLoading(false);
  }

  const handleEditReceipt = (gochiUserId, receipt, index) => {
    setReceiptImageBase64(props.listReceiptImageGochiKid[index]);
    setGochiKidUseIdChoose(gochiUserId);
    setReceiptChoose(receipt);
    setItemReceiptArr(receipt.receiptDetails ? receipt.receiptDetails : []);
    setUploadReceiptFor(uploadReceiptForStatus.EDIT);
    setShowReceiptResultModal(true);
  }

  const handleCloseErrorModal = () => {
    setIsErrorReceipt(false);
    setShowErrorModal(false);

    if (isErrorReceipt) {
      setShowReceiptResultModal(true);
    }
  }

  const handleOpenGochiKidSurvey = async (gochiKidUseId, gochiKidGroupId) => {
    setLoading(true);

    try {
      const params = {
        gochiGroupId: gochiKidGroupId,
        surveyType: 3
      };
      const response = await MenuService.getSurvey(params);

      if (response.status === HTTP_API.SUCCESS) {
        setGochiKidUseIdChoose(gochiKidUseId);
        setSurveyDetails(response.data);
        const qs = response.data.questions || [];
        let initialAnwsers = {};

        for (let i = 0; i < qs.length; i++) {
            if (qs[i].questionType === SURVEY_QUESTION_TYPE.TEXT) {
                initialAnwsers[i] = '';
            } else if (qs[i].questionType === SURVEY_QUESTION_TYPE.RADIO) {
                initialAnwsers[i] = '';
            } else if (qs[i].questionType === SURVEY_QUESTION_TYPE.CHECKBOX) {
                initialAnwsers[i] = new Set();
            } else if (qs[i].questionType === SURVEY_QUESTION_TYPE.IMAGE) {
                initialAnwsers[i] = new Set();
            }
        }

        setAnswers(initialAnwsers);
        setShowSurveyModal(true);
      } else {
        alert("フォームの表示に失敗しました。前画面に戻って、もう一度やり直してください。");
      }
    } catch(e) {
      console.log(e);
      alert("フォームの表示に失敗しました。前画面に戻って、もう一度やり直してください。");
    } finally {
      setLoading(false);
    }
  }

  const handleCloseGochiKidSurvey = () => {
    setGochiKidUseIdChoose(null);
    setShowSurveyModal(false);
    setSurveyDetails(null);
    setAnswers([]);
  }

  const handleSubmitGochiKidSurvey = async (answer) => {
    setLoading(true);

    try {
      const response = await MenuService.postSurveyAnswerGochiKid(surveyDetails.surveyId, answer, gochiKidUseIdChoose);

      if (response.status === HTTP_API.SUCCESS) {
        setShowReceiptSuccessModal(true);
        setTitleReceiptSuccessModal('回答完了');
        setMessageReceiptSuccessModal('アンケート回答を完了しました！');
        setShowSurveyModal(false);
        setGochiKidUseIdChoose(null);
        setShowSurveyModal(false);
        setSurveyDetails(null);
        setAnswers([]);
      } else {
        alert('エラーが発生しました');
      }
    } catch (e) {
      console.log(e);
      alert('エラーが発生しました');
    } finally {
      setLoading(false);
    }
  }

  const firstGochiKidUsed = props.searchResults && props.searchResults.length > 0 ? props.searchResults[0] : null;

  return (
    <div className="list-menu-used-gochi-kid">
      <MetaTags>
        <title>自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta name="twitter:description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:image" content="/assets/img/common/ogp.png" />
        <meta property="og:site_name" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:title" content="自分宛てごち｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="/assets/img/common/ogp.png" />
        <meta property="og:description" content="自分宛てごちページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>

      <hr className="mt-48px" />
      {props.userKidData && props.userKidData.userKids && props.userKidData.userKids.length !== 0 && (
        <div className="box-status-user-kid">
          <div className="box-content">
            <div className="header-box">【審査結果】</div>
            <div className={`body-box ${!isLoadMoreBoxStatus ? 'display-none' : ''}`}>
              {(props.userKidData && props.userKidData.poorStatus !== "INIT") && (
                <div className="poor-box">
                  <p className="title">生活保護受給：</p>
                  <p className="text">{props.userKidData?.poorStatus ? (props.userKidData?.poorStatus === "RESUBMIT" ? "再提出依頼中" : ('審査' + (props.userKidData?.poorStatus === "REVIEW" ? "中" : props.userKidData?.poorStatus))) : ''}</p>
                </div>
              )}
              <div className="user-kid-box">
                <p className="title">生年月日：</p>
                <p className="text">審査中 {props.userKidData?.sumChildrenReview || 0}人</p>
                <p className="text">審査OK {props.userKidData?.sumChildrenOk || 0}人</p>
                <p className="text">審査NG {props.userKidData?.sumChildrenNg || 0}人</p>
                <p className="text">再提出依頼中 {props.userKidData?.sumChildrenResubmit || 0}人</p>
              </div>
            </div>
            <div className="load-more">
              <span onClick={() => setIsLoadMoreBoxStatus(!isLoadMoreBoxStatus)}>{isLoadMoreBoxStatus ? "閉じる" : "開く"}</span>
            </div>
          </div>
        </div>
      )}
      <div className="header-list-menu">
        {Object.keys(props.userKidData).length !== 0 && (
          <div
            onClick={props.handleShowPopupRegisterUserKidGochiKid}
            className={`btn-regis-user-kid`}
          >
            <i className="icon-add-user-kid"/>
            {props.userKidData.userKids?.length === 0 ? "利用者登録" : "利用者情報を編集"}
          </div>
        )}
        <div className="btn-policy">
          <span onClick={props.handleShowPolicyGochiKidModal}>
            カード型会員証発行申請
            <br />
            （一部店舗でのみ利用可能）
          </span>
        </div>
        {props.userKidData?.sumChildrenOk > 0 && (
          <>
            <div
              //href={addParamUrl("/mypage/kodomo_use/search_group_shop", [isSiteParam])}
              onClick={() => {
                props.handleShowListGroupSpecialGochiKidModal();
                document.getElementsByTagName('html')[0].classList.add('hide-scroll-background-gochi-kid');
              }}
              className={`btn-go-shop ${props.thanksMessageIsNull || props.isReceiptApproveRequired || props.surveyIsNullGochiKid ? 'disable-btn-go-shop' : ''}`}
            >
              <i className="icon-go-shop"/>
              店舗・メニューを探す
            </div>
            {(props.thanksMessageIsNull || props.isReceiptApproveRequired || props.surveyIsNullGochiKid) && (
              <div className="warning">
                <i className="icon-warning-go-shop"/>
                <div>
                  画面下の利用履歴をご確認のうえ、利用済みメニューへの感謝メッセージの投稿をお願いいたします。
                  <br />
                  また、その他ボタンが表示されている場合は、そちらも押してください。
                  <br />
                  完了後、新たに「こどもごちめし」のメニューをご利用いただけます。
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {props.userKidData?.sumChildrenOk > 0 && (
        <div className="gochi-gochi-used-list mt-24px" id="scrollToElementId">
          <div className={`profile-history ${props.loadingGochiKidUsed ? 'loading': ''}`}>
            <div className="products-group products-group-column">
              <div className="title-gochi-used">利用履歴</div>
              <div className="gochi-kid-used-box">
                {props.searchResults.length > 0 ? (props.searchResults.map((item, key) => {
                  let index = `${item.gochiKidUseId}-${key}`;
                  var image = item.menuImage && item.menuImage.length > 0 && item.menuImage[0].image ? 
                                  item.menuImage[0].image : props.NO_IMAGE_MENU;
                  var shopName = item?.shopName ? item.shopName : null;
                  var menuName = item?.menuName ? item.menuName : null;
                  var userKidNumUse = item?.userKidNumUse ? item.userKidNumUse : 0;
                  var usedAmountPerKid = item.usedAmount ? (item.usedAmount / (userKidNumUse || 1)) : 0;
                  let classNameDisableUpReceipt = '';
                  let elementReceiptStatus;
                  const receipt = item.receipt;

                  if (!receipt) {
                    elementReceiptStatus = (<span className="text-red">未登録</span>);
                  } else if (receipt.status === 1) {
                    elementReceiptStatus = (<span>登録・承認済み</span>);
                  } else if (receipt.status === 2) {
                    elementReceiptStatus = (<span className="text-red">登録済・承認保留</span>);
                  } else if (receipt.status === 3) {
                    elementReceiptStatus = (<span className="text-yallow">登録済・承認待ち</span>);
                  } else if (receipt.status === 4) {
                    elementReceiptStatus = (<span className="text-red">登録済・非承認</span>);
                  }

                  if ((props.thanksMessageIsNull || props.surveyIsNullGochiKid) && receipt?.status !== 1) {
                    classNameDisableUpReceipt = 'btn-gochi-kid-receipt-disable-message-null';
                  } else if (receipt?.status === 1 || receipt?.status === 3 || receipt?.status === 4) {
                    classNameDisableUpReceipt = 'btn-gochi-kid-receipt-disable';
                  }

                  return (
                    <article className="products gochi-kid-item gochi-kid-used" key={index}>
                      <div className="gochi-kid-support-item">
                        <dl className="list-description text-sm">
                          <div className="gochi-kid-digest">
                            <div className="gochi-kid-thumbnail">
                              <div className="thumbnail">
                                <img
                                  src={image}
                                  alt="menu image"
                                  loading="lazy"
                                />
                              </div>
                            </div>
                            <div className="shop-header-group">
                              <div className="shop-header">
                                <div className="shop-name">
                                  {shopName}
                                </div>
                                <h2 className='menu-name'>
                                  {menuName}
                                </h2>
                              </div>
                              <hr />
                              {item.menuId
                                ? 
                                  <div className="price-area">
                                    <span className="unit">¥</span>
                                    <span className="num">
                                      {`${usedAmountPerKid?.toLocaleString()} x ${userKidNumUse}`}
                                    </span>
                                  </div>
                                :
                                  <div className="price-area">
                                    <div>
                                      <div>
                                        <span>利用人数: </span>
                                        <span className="num">{userKidNumUse}</span>
                                        <span className="unit">人</span>
                                      </div>
                                      <div>
                                        <span>合計金額: </span>
                                        <span className="num">{item.usedAmount?.toLocaleString()}</span>
                                        <span className="unit">円</span>
                                      </div>
                                    </div>
                                  </div>
                              }
                            </div>
                          </div>
                          <div className="list-description-item">
                            <dt className="title font-weight-normal">利用日</dt>
                            <dd className="text font-weight-normal">
                              {item.usedAt ? props.formatDate(item.usedAt) : ''}
                            </dd>
                          </div>
                          <div className="list-description-item gochi-detail">
                            <dt className="title font-weight-normal">詳細</dt>
                            <dd className="text font-weight-normal">
                              <button onClick={() => props.handleShowPopupGochiKidUsedDetail(item, key)} className="font-weight-700">
                                詳細を確認する
                              </button>
                            </dd>
                          </div>
                          {item.gochiKidReceipt === 1 && (
                            <div className="list-description-item gochi-detail">
                              <dt className="title font-weight-normal">レシート登録</dt>
                              <dd className="text font-weight-normal font-weight-700">
                                {elementReceiptStatus}
                              </dd>
                            </div>
                          )}
                        </dl>
                        {item.gochiKidReceipt === 1 && receipt?.status === 3 && (
                          <div className="btn-gochi-kid-use receipt-note">
                            <div><i className="icon-receipt-note" /></div>
                            ただいまレシート登録内容を確認しておりますので、しばらくお待ちください。
                          </div>
                        )}
                        <div className="btn-gochi-kid-use">
                          <button
                            className="btn btn-rect btn-sm btn-use-gochi-charge"
                            type="button"
                            onClick={() => {
                              props.handleShowPopupSendMessageGochiKid(item);
                            }}
                            disabled={item.thanksMessage}
                          >
                            {item.thanksMessage ? '感謝メッセージを投稿済み' : '感謝メッセージを投稿する'}
                          </button>
                        </div>
                        {item.surveyFlag === 1 && (
                          <div className="btn-gochi-kid-use">
                            <button
                              className="btn btn-rect btn-sm btn-gochi-kid-survey"
                              type="button"
                              onClick={() => {
                                handleOpenGochiKidSurvey(item.gochiKidUseId, item.gochiKidGroupId);
                              }}
                              disabled={item.surveySubmission}
                            >
                              {item.surveySubmission ? 'アンケート回答済み' : 'アンケート回答'}
                            </button>
                          </div>
                        )}
                        {item.gochiKidReceipt === 1 && (
                          <>
                            {receipt?.status === 2 ? (
                              <>
                                <div className="warning-receipt-box receipt-note">
                                  <div><i className="icon-warning-receipt" /></div>
                                  <div>
                                    品目にお子さまの飲食品以外（日用品や雑貨など）が検知されたため、承認できませんでした。
                                    <br />
                                    （承認されるまでこどもごちめしが利用できません）
                                    <br />
                                    <br />
                                    品目編集が必要な場合は「品目編集後に再申請」ボタンよりお子さまの飲食品以外の品目を削除して再申請してください。
                                    <br />
                                    <br />
                                    既に飲食品のみを登録している場合（判定が誤っている場合）は「現在の品目で再申請」ボタンを押下してください。
                                  </div>
                                </div>
                                <div className="check-receipt-btn-box btn-gochi-kid-use">
                                  <button
                                    className="btn btn-rect btn-sm btn-edit-receipt"
                                    type="button"
                                    onClick={() => handleEditReceipt(item.gochiKidUseId, receipt, key)}
                                  >
                                    品目編集後に再申請
                                  </button>
                                  <button
                                    className={"btn btn-rect btn-sm btn-ai-check-receipt"}
                                    onClick={() => handleCheckReceiptByAI(receipt?.id)}
                                  >
                                    現在の品目で再申請
                                  </button>
                                </div>
                                <div className="admin-check-receipt-box">
                                  <div className="receipt-note receipt-button-note">
                                    <div><i className="icon-error-receipt" /></div>
                                    <div>
                                      上部に配置されている2つのボタンからお子さまの飲食品を再申請しているにもかかわらず、お子さまの飲食品以外だと2回判定された場合は、大変お手数をおかけいたしますが、下記の再々申請ボタンよりご連絡くださいますようお願い申し上げます。
                                      <p className="text-red">なお、この再々申請ボタンを使用した申請で、お子さまの飲食品以外の申請が確認された場合、サービスのご利用を継続いただけなくなりますのでご了承ください。</p>
                                    </div>
                                  </div>
                                  <div className="btn-admin-check-receipt">
                                    <button
                                      onClick={() => handleCheckReceiptByAdmin(receipt?.id)}
                                    >
                                      再々申請する
                                    </button>
                                  </div>
                                </div>
                                <div className="re-up-receipt-box">
                                  <div className="receipt-note receipt-button-note">
                                    <div><i className="icon-error-receipt" /></div>
                                    <div>
                                      レシート登録を誤った場合、<span className="text-red">1アカウントにつき1回限り再登録を承ります。</span>
                                      <br />
                                      再登録するレシートは、お子さまの飲食品のみが記載されていれば、利用履歴の日付や店舗名と異なっていても問題ありません。
                                      <br />
                                      「こどもごちめし」はお子さまの飲食費を支援するサービスで、皆様のご協力で運営されています。
                                      <span className="text-red">次回以降の再登録はお受けできず、サービスのご利用を継続いただけなくなりますのでご了承ください。</span>
                                    </div>
                                  </div>
                                  {props.retryUploadReceiptGochiKid === 0 ? (
                                    <>
                                      <div className="checkbox-container">
                                        <div className="field field-checkbox policy-text-box">
                                          <label>
                                            <input
                                              className="before-icon"
                                              type="checkbox"
                                              checked={policy}
                                              onChange={(e) => {
                                                setPolicy(e.target.checked);
                                              }}
                                            />
                                            <i className="icon" />
                                          </label>
                                          <div className="policy-text-description">
                                            上記注意事項に同意する
                                          </div>
                                        </div>
                                      </div>
                                      <div className="btn-gochi-kid-use">
                                        <label
                                          className={`btn btn-rect btn-sm btn-gochi-kid-receipt ${!policy ? 'btn-gochi-kid-receipt-disable-message-null' : ''}`}
                                          htmlFor={`img-input-${index}`}
                                        >
                                          <input
                                            name="img-input"
                                            id={`img-input-${index}`}
                                            className="input-gochi-kid-receipt"
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => {
                                              onChangeImgUpload(e, item.gochiKidUseId, uploadReceiptForStatus.REUPLOAD);
                                              e.target.value = null;
                                            }}
                                            disabled={!policy}
                                          />
                                          レシート再登録（1回限り）
                                        </label>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="gochi-kid-re-upload-note">以前に1回限りのレシート再登録を対応済みです。</div>
                                  )}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="btn-gochi-kid-use">
                                  <label
                                    className={`btn btn-rect btn-sm btn-use-gochi-charge btn-gochi-kid-receipt ${classNameDisableUpReceipt}`}
                                    htmlFor={`img-input-${index}`}
                                  >
                                    <input
                                      name="img-input"
                                      id={`img-input-${index}`}
                                      className="input-gochi-kid-receipt"
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) => {
                                        onChangeImgUpload(e, item.gochiKidUseId, uploadReceiptForStatus.UPLOAD);
                                        e.target.value = null;
                                      }}
                                      disabled={props.thanksMessageIsNull || props.surveyIsNullGochiKid}
                                    />
                                    {receipt && receipt.status !== 2 ? 'レシート登録済み' : 'レシート登録'}
                                  </label>
                                </div>
                                {(props.thanksMessageIsNull || props.surveyIsNullGochiKid) && receipt?.status !== 1 && (
                                  <div className="btn-gochi-kid-use receipt-note receipt-note-conditional">
                                    <div><i className="icon-receipt-note" /></div>
                                    アンケート回答と感謝メッセージを投稿後、レシートの登録をお願いいたします。
                                  </div>
                                )}
                                {receipt?.status === 4 && (
                                  <div className="btn-gochi-kid-use receipt-note error-receipt-box">
                                    <div><i className="icon-error-receipt" /></div>
                                    <div>
                                      「こどもごちめし」はお子さまの飲食費を支援するサービスで、皆様のご協力で運営されています。
                                      <br />
                                      不正利用が確認されたため、サービスのご利用を継続することができなくなりました。</div>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </article>
                  );
                })) : (
                  <p className="no-gochi-kid-used">現在までの利用履歴はありません。</p>
                )}
              </div>
            </div>
            {props.searchResults.length > 0 && (
              <CustomPagination
                count={Math.ceil(props.totalResult / props.limit)}
                page={props.page}
                onChange={props.handleChangePage}
              />
            )}
          </div>
        </div>
      )}

      {showPreviewImgReceiptModal && (
        <GochiKidPreviewImageReceiptModal
          showing={showPreviewImgReceiptModal}
          imageReceiptFileUrl={imageReceiptFileUrl}
          onClose={handleClosePreviewImageModal}
          onSubmit={handleUploadReceipt}
          onChangeImgUpload={onChangeImgUpload}
          gochiKidUseIdChoose={gochiKidUseIdChoose}
        />
      )}

      {showReceiptResultModal && (
        <GochiKidReceiptResultModal
          showing={showReceiptResultModal}
          onClose={handleCloseGochiKidResultModal}
          onSubmit={handleOpenGochiKidReceiptConfirmModal}
          gochiKidUseIdChoose={gochiKidUseIdChoose}
          receiptChoose={receiptChoose}
          itemReceiptArr={itemReceiptArr}
          setItemReceiptArr={setItemReceiptArr}
          receiptImageBase64={receiptImageBase64}
        />
      )}

      {showReceiptConfirmModal && (
        <GochiKidReceiptConfirmModal
          showing={showReceiptConfirmModal}
          onClose={handleCloseGochiKidReceiptConfirmModal}
          onSubmit={handleSubmitGochiKidReceipt}
        />
      )}

      {showReceiptSuccessModal && (
        <GochiKidReceiptSuccessModal
          showing={showReceiptSuccessModal}
          onClose={handleCloseReceiptSuccessModal}
          title={titleReceiptSuccessModal}
          message={messageReceiptSuccessModal}
        />
      )}

      {showErrorReceiptModal && (
        <GochiKidErrorUploadReceiptModal
          showing={showErrorReceiptModal}
          onClose={handleCloseErrorReceiptModal}
          message={messageErrorReceiptModal}
          onChangeImgUpload={onChangeImgUpload}
          gochiKidUseIdChoose={gochiKidUseIdChoose}
        />
      )}

      {showSurveyModal && (
        <GochiKidSurveyModal
          showing={showSurveyModal}
          onClose={handleCloseGochiKidSurvey}
          questions={questions}
          answers={answers}
          setAnswers={setAnswers}
          surveyDetails={surveyDetails}
          handleSubmitGochiKidSurvey={handleSubmitGochiKidSurvey}
        />
      )}

      {showErrorModal && (
        <div
          className={
            showErrorModal
              ? "custom-modal-container group-special-gochi-kid-modal gochi-kid-preview-image-receipt-modal gochi-kid-upload-receipt-error gochi-kid-receipt-error show-modal"
              : "custom-modal-container group-special-gochi-kid-modal gochi-kid-preview-image-receipt-modal gochi-kid-upload-receipt-error gochi-kid-receipt-error"
          }
          size="sm"
        >
          <div
            className={
              showErrorModal
                ? "modal modal-action modal-dialog is-show history-modal"
                : "modal modal-action modal-dialog history-modal"
            }
          >
            <div className="modal-header-history">
              <button
                className="modal-btn modal-btn-close"
                id="modalClose"
                onClick={() => {
                  handleCloseErrorModal()
                }}
                type="button"
              >
                <svg className="icon">
                  <title>閉じる</title>
                  <use xlinkHref="/assets/img/common/icon.svg#times" />
                </svg>
              </button>
            </div>
            <div className={`modal-body`}>{messageErrorModal}</div>
            <div className="modal-footer">
              <button
                className="btn btn-rect btn-submit"
                onClick={() => {
                  handleCloseErrorModal()
                }}
              >
                前の画面に戻る
              </button>
            </div>
          </div>
          <div className={`back-drop`} />
        </div>
      )}

      <LoadingOverlay loading={loading}/>
    </div>
  );
}
