import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setTempWishListMenuAction } from "../../../actions/action_wishlist_menu";
import { setTempFavoriteMenuAction } from "../../../actions/action_favorite_menu";
import { showNotLoggedModal } from "../../../actions/action_show_not_logged_modal";
import { toggleFavoriteMenu, toggleWishListMenu } from "../../../utils";
import { showWishListModal } from "../../../actions/action_show_wish_list_modal";

const NO_IMAGE = require("../../../assets/img/icons/square_blank.png");

export function MenuInfoShop(props) {
  const userInfo = useSelector((state) => state.userInfo);
  const isLoggedIn = userInfo && userInfo.userId;
  const dispatch = useDispatch();
  const menuId = props.menu?.menuId;
  const shopId = props.menu?.shopId;

  const favoriteState = useSelector((state) => state.favoriteMenu);
  const wishListState = useSelector((state) => state.wishListMenu);

  const tempFavorite =
    favoriteState[menuId] !== undefined ? favoriteState[menuId] : null;
  const tempWishList =
    wishListState[menuId] !== undefined ? wishListState[menuId] : null;
  const setTempFavorite = (isFav) => {
    dispatch(setTempFavoriteMenuAction(menuId, isFav));
  };
  const setTempWishList = (isWishList) => {
    dispatch(setTempWishListMenuAction(menuId, isWishList));
  };

  function onToggleMenuWishList() {
    if (!isLoggedIn) {
      dispatch(showNotLoggedModal(true));
      return;
    }
    const current =
      tempWishList !== null ? tempWishList : props.menu.isWishList;
    console.log("TOGGLE: ", current, tempWishList, props.menu.isWishList);
    toggleWishListMenu(props.menu.menuId, current, () => {
      setTempWishList(!current);
      if (!current) dispatch(showWishListModal(true));
    });
  }

  function onToggleMenuFavorite() {
    if (!isLoggedIn) {
      dispatch(showNotLoggedModal(true));
      return;
    }
    const current =
      tempFavorite !== null ? tempFavorite : props.menu.isFavourite;
    console.log("TOGGLE: ", current, tempFavorite, props.menu.isFavourite);
    toggleFavoriteMenu(props.menu.menuId, current, () => {
      setTempFavorite(!current);
      if (!current) dispatch(showWishListModal(true, true));
    });
  }

  const menuImage =
    props.menu &&
    props.menu.menuImage &&
    props.menu.menuImage.length > 0 &&
    props.menu.menuImage[0].image
      ? props.menu.menuImage[0].image
      : NO_IMAGE;

  const accessToken = props.accessToken ? "&accessToken=" + props.accessToken : "";
  const accessCode = props.accessCode ? "&accessCode=" + props.accessCode : "";
  const gochiKidGroupId = props.gochiKidGroupId ? "&gochiKidGroupId=" + props.gochiKidGroupId : "";

  return (
    <article className={`products bg-white ${props.isShopDetailScreen ? 'list-menu-gochi-kid' : ''}`}>
      {!props.isShopDetailScreen && (
        <Link
          className="products-link"
          to={`/shopdetails/${shopId || "group-menu"}/menu/${menuId}`}
        />
      )}
      <div className="products-thumbnail">
        <div className="thumbnail">
          <img src={menuImage} alt="" loading="lazy" />
        </div>
        <div className="badge-group">
          {props.menu.menuComboDetail?.quantityFree > 0 && (
            <div className="badge badge-black">無料</div>
          )}
          {props.menu.menuComboDetail?.quantityFreeDes && (
            <div className="badge">お得</div>
          )}
        </div>
        <div className="action-group">
          <div
            className={
              (tempWishList !== null ? tempWishList : props.menu.isWishList)
                ? "action action-list is-active"
                : "action action-list is-inactive"
            }
          >
            <button
              type="button"
              onClick={onToggleMenuWishList}
              aria-label="記事を保存"
            >
              <svg className="icon">
                <use xlinkHref="/assets/img/common/icon.svg#list" />
              </svg>
            </button>
          </div>
          <div
            className={
              (tempFavorite !== null ? tempFavorite : props.menu.isFavourite)
                ? "action action-like is-active"
                : "action action-like is-inactive"
            }
          >
            <button
              type="button"
              onClick={onToggleMenuFavorite}
              aria-label="記事を保存"
            >
              <svg className="icon">
                <use xlinkHref="/assets/img/common/icon.svg#heart" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="products-header">
        <div className="text-sm text-gray title-custom">
          {props.menu?.shopName || props.menu?.groupName}
        </div>
        <h2 className="title text-bold">{props.menu?.menuName}</h2>
      </div>
      <div className={`price-area ${props.isShopDetailScreen && props.menu?.gochiKidFreeFlag === 1 ? 'price-area-gochi-kid' : ''}`}>
        <span className="unit">¥</span>
        <span className="num">{props.isShopDetailScreen ? props.menu.includedTaxPrice?.toLocaleString() : props.menu.chargeAmount?.toLocaleString()}</span>
        {props.isShopDetailScreen && props.menu?.gochiKidFreeFlag === 1 && !props.gochiKidGroupId && (
          <div className="gochi-kid-free-set">
            店舗提供無料メニュー
            <br />
            残数：{props.menu?.freeRemainNum}食
          </div>
        )}
      </div>
      {props.menu.menuCombo === 1 && (
        <div className="badge badge-lg badge-red">回数券</div>
      )}
      <div className="products-footer">
        <div className="btn-group btn-group-column">
          <button className="btn btn-sm btn-rect btn-fluid" onClick={() => {
            if (props.isShopDetailScreen && props.gochiKidUseCode === 1) {
              if (props.gochiKidReceipt === 1) {
                props.setGochiKidReceiptShopChoice(1);
                props.handleShowPopupAcceptPolicyGochiKidReceipt(props.choiceShopId, 0, props.menu?.menuId, props.gochiKidUseCode);
              } else {
                props.setGochiKidReceiptShopChoice(0);
                const redirectUrl = `/kodomo/use?gochiKidUseCode=1&shopId=${props.choiceShopId}&menuId=${props.menu?.menuId}${gochiKidGroupId}${accessToken}${accessCode}`;
                window.location.href = redirectUrl;
              }
            } else if (props.isShopDetailScreen && props.numUserKidUse === 1) {
              if (props.choiceUserKid?.amountKidCanUse > 0 && props.choiceUserKid?.amountKidCanUse < props.menu?.includedTaxPrice && props.choiceUserKid.firstUsed === 1 && props.choiceUserKid.flagPoor === 1) {
                props.handleOpenSelectUserKidModal(props.choiceShopId, props.menu?.menuId, props.gochiKidUnlimit, props.menu?.includedTaxPrice, props.gochiKidReceipt);
              } else {
                if (props.gochiKidReceipt === 1) {
                  props.setGochiKidReceiptShopChoice(1);
                  props.handleShowPopupAcceptPolicyGochiKidReceipt(props.choiceShopId, 0, props.menu?.menuId, props.gochiKidUseCode);
                } else {
                  props.setGochiKidReceiptShopChoice(0);
                  const redirectUrl = `/kodomo/use?shopId=${props.choiceShopId}&menuId=${props.menu?.menuId}&userKidIds=${props.choiceUserKidId}${gochiKidGroupId}${accessToken}${accessCode}`;
                  window.location.href = redirectUrl;
                }
              }
            } else if ((props.menu?.gochiKidFreeFlag === 0 && !props.gochiKidGroupId && props.numberSlotCanUse === 0) || (props.gochiKidGroupId && props.numberSlotCanUse === 0)) {
              const errorText = (
                <div>
                  以下いずれかの理由により、ご利用できません。
                  <br />
                  ・利用可能残数がない
                  <br />
                  ・利用可能曜日（時間）ではない
                  <br />
                  ・支援金が不足している
                </div>
              );
              props.handShowErrorUseGochiKidPopup(errorText);
            } else if (props.isShopDetailScreen) {
              props.handleOpenSelectUserKidModal(props.choiceShopId, props.menu?.menuId, props.gochiKidUnlimit, props.menu?.includedTaxPrice, props.gochiKidReceipt);
            }
            //else if (props.isShopDetailScreen && !props.numUserKidUse) {
            //props.handShowErrorUseGochiKidPopup("本日の食堂利用回数を超えました。");
            //}
             else {
              const redirectUrl = `/menu?id=${props.menu?.menuId}`;
              window.open(redirectUrl, "_blank");
            }
          }}>
          {props.isShopDetailScreen ? "利用する" : "購入する"}
          </button>
        </div>
      </div>
    </article>
  );
}
