/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";   
import {Link, Redirect} from 'react-router-dom';  
import {connect} from 'react-redux';  
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import queryString from 'query-string';
import * as constants from '../../../constants';
import { log, addParamUrl } from '../../../utils'; 

import MenuService from "../../../api/services/MenuService";
// import ShopService from "../../../api/services/ShopService";
import {saveInvite} from "../../../actions/action_menu";
import {saveMenuInfo, updateQuantityMenu} from "../../../actions/action_menu";
import {updateMenuPriceInfo, saveInitTotalAmountMenuPrice, saveInitAmount, saveInitMenuPrice} from "../../../actions/action_shop";
import {commentInvite} from "../../../actions/action_comment";
import {addMessageShop} from "../../../actions/action_message_shop";
import {selectFriend} from "../../../actions/action_select_friend"; 
import {saveInitTotalAmount} from "../../../actions/action_menu";
import {savePaymentMethod} from "../../../actions/action_payment";
import {showLoading} from "../../../actions/action_loading";
import {saveGuestInfo} from "../../../actions/action_guest";
import {showError} from "../../../actions/action_error_alert";
import {addMessageCard} from "../../../actions/action_message_card";
import {isMobile} from "react-device-detect";
import Header from "../../layout/header/Header";
// import ShowMoreText from 'react-show-more-text';
import ListShop from '../../common/ListShop';
import SurveyForm from "../../common/SurveyForm";
import {Modal} from 'react-bootstrap'
import PopupErrorGochiCharge from "../../common/PopupErrorGochiCharge";
import moment from "moment";
import ClearCacheInstructionPopup from "./ClearCacheInstructionPopup";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const ATONE_POPUP_TIMEOUT = 600000;

class InviteConfirm extends Component {
	constructor(){
		super()
		this.state = {
			menuId: null,
			redirectToReferrer: false,
			buttonDisabled: false,
			paymentType: sessionStorage.getItem('selectPayment') ? parseInt(sessionStorage.getItem('selectPayment')) : constants.PAYMENT_TYPE_CREDIT_CARD,
            redirectUrl: '',
            redirectUrlStripe: '',
            paramShashoku: '',
			src: '',
			seeMorePriceDetail: false,
			showListShopPopup: false,
			shopId: null,
			initMenuPrice: 100,
			crfsToken: '',
			joinMogugoriRank: false,
			isMogugori: false,
			isSite: '',
			renderSurvey: true,
      isOpenJoinMogugoriRankPopup: false,
      isShowErrorGochiCharge: false,
      titleErrorGochiCharge: '',
      contentErrorGochiCharge: '',
      textButtonErrorGochiCharge: '',
      urlErrorGochiCharge: '',
      isShowClearCacheInstructionPopup: false
		}
		this.handleBackFromFinish = this.handleBackFromFinish.bind(this);
		this.startAtoneCheckout = this.startAtoneCheckout.bind(this);
		this.submitInviteRequest = this.submitInviteRequest.bind(this);
		this.handleShowListShops = this.handleShowListShops.bind(this);
		this.handleCloseListShopPopup= this.handleCloseListShopPopup.bind(this);
		this.handleSeeMorePriceDetail = this.handleSeeMorePriceDetail.bind(this);
		this.generateRandomString = this.generateRandomString.bind(this);
		this.goFinish = this.goFinish.bind(this);
    this.handleJoinRankCheckBox = this.handleJoinRankCheckBox.bind(this);
    this.showPopupErrorGochiCharge = this.showPopupErrorGochiCharge.bind(this);
		this.handleAtoneVisibilityChange = this.handleAtoneVisibilityChange.bind(this);
	}
	generateRandomString (length = 32) {
		var text = "";
		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

		for (var i = 0; i < length; i++) { text += possible.charAt(Math.floor(Math.random() * possible.length)); }
		this.setState({
		crfsToken: text,
		})
		console.log('crfs token', text);
		return text;
	}

	handleSeeMorePriceDetail() {
		if(this.state.seeMorePriceDetail) {
			this.setState({
				seeMorePriceDetail: false
			});
		} else {
			this.setState({
				seeMorePriceDetail: true
			});
		}
	}

	handleShowListShops() {
		this.setState({
			showListShopPopup: true
		});
	};

	handleCloseListShopPopup() {
		this.setState({
			showListShopPopup: false
		});
	};

	UNSAFE_componentWillMount(){ 
		let url = window.location.search;
		let params = queryString.parse(url);
		var menuId = params.id;
		var shopId = params.shopId;
        const fromShashoku = params.fromShashoku;
		var src = params.src;
		var isSite = params.isSite;

		if (src) {
			this.setState({
				src: src
			});
		}

		if (isSite) {
			this.setState({
				isSite: isSite
			});
		}

		if (isSite === 'mogugori') {
			this.setState({
				isMogugori: true
			});
		}

		this.setState({
			menuId: menuId
		});
		this.props.showLoading(false);
		this.setState({
			buttonDisabled: false
		})

		if(shopId) {
			this.setState({
				shopId: shopId
			});
		}

        if (fromShashoku) {
            this.setState({
				paramShashoku: `&fromShashoku=${encodeURIComponent(fromShashoku)}`
			});
        }

		// Scroll to top when show page
		window.scrollTo(0,0);
	}

	handleBackFromFinish() {
		// Show loading to prevent user actions
		this.props.showLoading(true);
		this.setState({ renderSurvey: false });
		setTimeout(() => {
			// Hide loading and re-render survey in case back from finish page
			console.log('Hide loading and re-render survey');
			this.props.showLoading(false);
			this.setState({ renderSurvey: true });
		}, 10000);
	}

	handleAtoneVisibilityChange() {
		if (window.atoneCheckoutStartTime && window.document.visibilityState === 'visible') {
			const d = new Date();
			const remainTime = ATONE_POPUP_TIMEOUT - (d.getTime() - window.atoneCheckoutStartTime);
			console.log('handleAtoneVisibilityChange remainTime: ' + remainTime);
			if (remainTime < 0) {
				this.clearAtoneTimeout();
				this.forceCloseAtonePopup();
			} else {
				console.log('handleAtoneVisibilityChange re-setTimeout');
				if (typeof window.atoneTimeoutId === "number") {
					clearTimeout(window.atoneTimeoutId);
				}
				window.atoneTimeoutId = setTimeout(function () {
					this.forceCloseAtonePopup();
				}.bind(this), remainTime);
			}
		}
	}

	clearAtoneTimeout() {
		window.atoneCheckoutStartTime = null;
		if (typeof window.atoneTimeoutId === "number") {
			console.log('clearAtoneTimeout');
			clearTimeout(window.atoneTimeoutId);
		}
	}

	forceCloseAtonePopup() {
		window.atoneCheckoutStartTime = null;
		if (window.Atone.properties()?.payment?.checksum) {
			window.postMessage({ msg: 'cancelled' });
			window.Atone.merge({ payment: {} });
			alert('一定時間内に操作が行われなかったため、支払いを中断させていただきました。\nこの後、まれにお支払いが正常に完了し、商品が購入されている場合がございます。\nその際は「購入履歴」またはご登録のメールにて購入状況をご確認ください。');
		}
	}

	async startAtoneCheckout(atoneJson, redirectUrl) {
		console.log("Atone JSON: " + JSON.stringify(atoneJson));
		const options = {
			payment: atoneJson,
			succeeded: function (response) {
				this.clearAtoneTimeout();
				console.log('response succeeded ' + JSON.stringify(response));
				const successUrl = redirectUrl + `&checkoutId=${response.id}`;
				this.setState({ redirectUrl: successUrl });
				window.location.href = successUrl;
			}.bind(this)
		};
		if (localStorage.getItem("atoneToken")) {
			options.pre_token = localStorage.getItem("atoneToken");
		}
		if (window.Atone) {
			window.Atone.merge(options);
			window.Atone.sync();
			// Sleep to wait sync done
			await new Promise(resolve => setTimeout(resolve, 1000));
			window.Atone.start();
			// Force close Atone popup after 10 minutes
			this.clearAtoneTimeout();
			const d = new Date();
			window.atoneCheckoutStartTime = d.getTime();
			window.atoneTimeoutId = setTimeout(function () {
				this.forceCloseAtonePopup();
			}.bind(this), ATONE_POPUP_TIMEOUT);
			// Redmine 126450: Handle visibility change to fix bug for iPhone
			window.document.removeEventListener("visibilitychange", this.handleAtoneVisibilityChange);
			window.document.addEventListener("visibilitychange", this.handleAtoneVisibilityChange);
		} else if (!window.Atone && this.state.paymentType === constants.PAYMENT_TYPE_ATONE) {
			this.props.showError(true, "※広告ブロック等の理由により、atone決済が表示されません。atone決済をご利用ご希望の方は、ご利用のブラウザを変更してお試しください。", "");
		}
	}

	submitInviteRequest(e){
		e.preventDefault(); 
		const url = window.location.href;
		const arr = url.split("/");
		const domain = arr[0] + "//" + arr[2];
		var inviteContent = {
			"gochiUserType": this.props.friend.gochiUserType,
			"situation": this.props.friend.friendInfo && this.props.friend.friendInfo.situation ? this.props.friend.friendInfo.situation.trim() : "",
			// "message": this.props.comment ? this.props.comment.trim() : "",
			"message": this.props.messageCard && this.props.messageCard.thankYouText ? this.props.messageCard.thankYouText.trim() : "",
			"shopMessage": this.props.messageShop.messageText ? this.props.messageShop.messageText.trim() : "",
			"showShopMessage": this.props.messageShop.publicMessage ? this.props.messageShop.publicMessage : 0,
			"gochiPlatformType": constants.GOCHI_PLATFORM_WEB,
			"domain": domain.replace(/http:\/\/|https:\/\//g, ''),
			"paymentType": this.state.paymentType || constants.PAYMENT_TYPE_CREDIT_CARD,
			"surveySubmissionId": this.state.surveySubmissionId,
			// "thankYouText": this.props.messageCard && this.props.messageCard.thankYouText ? this.props.messageCard.thankYouText.trim() : "",
			"giftCardId": this.props.friend.gochiUserType !== constants.GOCHI_TYPE_YOURSELF 
							&& ((this.props.menuInfo && this.props.menuInfo.menuChildType && this.props.menuInfo.menuChildType !== 3) || (Object.entries(this.props.menuInfo).length === 0))
								&& this.props.messageCard && this.props.messageCard.giftCardId ? this.props.messageCard.giftCardId : null,
			"joinMogugoriRank": this.state.joinMogugoriRank ? 1 : 0,
			"userAgent": navigator.userAgent
		};

		if (this.state.isMogugori) {
			inviteContent = { ...inviteContent,
								joinMogugori: 1,
								mogugoriShopId: parseInt(sessionStorage.getItem('mogugoriShopId'))
							}
		}

		if (sessionStorage.getItem("emailBookingCampaign") && sessionStorage.getItem("secretCode") && sessionStorage.getItem("monthBookingCampaign") && sessionStorage.getItem("campaignId")) {
			inviteContent = {
				...inviteContent,
				"emailBookingCampaign": sessionStorage.getItem("emailBookingCampaign"),
				"secretCode": sessionStorage.getItem("secretCode"),
				"monthBookingCampaign": sessionStorage.getItem("monthBookingCampaign"),
				"campaignId": sessionStorage.getItem("campaignId"),
			};
            if (this.props.menuInfo?.campaignId || constants.MENU_EVENT_HIDDEN_GOCHI_USER_TYPE.includes(this.props.menuInfo?.menuEventId)) {
                inviteContent = {
                    ...inviteContent,
                    "gochiUserType": constants.GOCHI_TYPE_YOURSELF 
                };
            }
		}

		// menu PRICE
		if (!this.state.menuId && this.state.shopId) {
			inviteContent = {
				...inviteContent,
				"gochiType": "PRICE",
				"shopId": this.props.shopInfo.shopId,
				"image": "https://dbclearyktrjk.cloudfront.net/images/gochi_price_ver2.jpg",
				"amount": this.props.shopInfo.initAmount,
				"amountWithTax": this.props.shopInfo.updatedMenuPriceInfo ? this.props.shopInfo.updatedMenuPriceInfo.amountWithTax : this.state.initMenuPrice,
				"couponCount": this.props.shopInfo.updatedMenuPriceInfo ? parseInt(this.props.shopInfo.updatedMenuPriceInfo.quantity) : 1,
			};
		} else {
			inviteContent = {
				...inviteContent,
				"shopId": this.props.menuInfo.shopId,
				"requestCode": this.state.menuId,
				"groupId": this.props.menuInfo.groupId,
				"gochiType": "MENU",
				"menuId": this.props.menuInfo.menuId,
				"couponCount": this.props.menuInfo.updatedMenuInfo ? parseInt(this.props.menuInfo.updatedMenuInfo.quantity) : 1,
				"image": this.props.menuInfo.menuImage && (this.props.menuInfo.menuImage.length > 0) ? this.props.menuInfo.menuImage[0].image : "",
				"src": this.props.menuInfo.menuNCount && this.props.menuInfo.menuNCount.src ? this.props.menuInfo.menuNCount.src : this.state.src,
				// "domain": process.env.REACT_APP_DOMAIN_URL.replace(/http:\/\/|https:\/\//g, ''),
			};
		}

		console.log('Clear old session');
		sessionStorage.removeItem('fullInviteContent');
		sessionStorage.removeItem('paypayResponse');
		
		if (this.props.userInfo.isGuest) {
			inviteContent = {
				...inviteContent,
				"preShopId": null,
				"gochiRegisterId": 0,
				"gochiUserId": null,
				"customerEmail": this.props.guestInfo && this.props.guestInfo.customerEmail,
				"customerName": this.props.guestInfo && this.props.guestInfo.customerName,
			};

			if (this.state.paymentType === constants.PAYMENT_TYPE_SELF_CHECKOUT) {
				this.onCreateInviteNoPayment(inviteContent);
			} else {
				this.createGuestInvite(inviteContent);
			}
		}else{
			inviteContent = {
				...inviteContent,
				"gochiRegisterId": this.props.userInfo.userId ? this.props.userInfo.userId : null,
				"gochiUserId": this.props.friend ? this.props.friend.selectedFriendId : "",
				"paymentSource": ((!this.state.paymentType || this.state.paymentType === constants.PAYMENT_TYPE_STRIPE || this.state.paymentType === constants.PAYMENT_TYPE_VERITRANS)
													&& this.props.paymentMethod)
													? this.props.paymentMethod.token : "",
			};
			if (this.state.paymentType !== constants.PAYMENT_TYPE_PAYPAY) {
				inviteContent = {
					...inviteContent,					
					"paymentCapture": "CAPTURE",
				}
			} else {
				inviteContent = {
					...inviteContent,
					"paymentCapture": "",
				}
			}

			if (this.state.paymentType === constants.PAYMENT_TYPE_SELF_CHECKOUT) {
				this.onCreateInviteNoPayment(inviteContent);
			} else {
				this.onCreateInvite(inviteContent);
			}
		}
	}

	async onCreateInvite(inviteContent) {
		const { t } = this.props;
		this.props.showLoading(true);
		this.setState({buttonDisabled: true});
		let windowReference;
		try {
			if (inviteContent) {
				let result = null;
				this.setState({
					redirectUrl: ''
				});
				this.setState({
					redirectUrlStripe: ''
				});
				if (!this.state.paymentType || this.state.paymentType === constants.PAYMENT_TYPE_VERITRANS) {
					// create invite with payment Credit Card (Veritrans - 3D Secure)
					result = await MenuService.create3dsCheckout(
						inviteContent
					);
					log(["create checkout session Veritrans 3D Secure", result]);
					if (result.status === 200) {
						this.props.showLoading(false);
						console.log('Prepare before redirect to 3D Secure');
						// setItem to empty object because can not removeItem after back from Veritrans 3D Secure
						sessionStorage.setItem('fullInviteContent', JSON.stringify({}));

						var bookingParam = (sessionStorage.getItem("emailBookingCampaign") ? '&emailBookingCampaign=' + sessionStorage.getItem("emailBookingCampaign") : '') + (sessionStorage.getItem("secretCode") ? '&secretCode=' + sessionStorage.getItem("secretCode") : '') + (sessionStorage.getItem("monthBookingCampaign") ? '&monthBookingCampaign=' + sessionStorage.getItem("monthBookingCampaign") : '') + (sessionStorage.getItem("campaignId") ? '&campaignId=' + sessionStorage.getItem("campaignId") : '');
						var isSiteParam = this.state.isSite ? '&isSite=' + this.state.isSite : '';
						const successUrl = !this.state.menuId && this.state.shopId
									? `/invite-finish?shopId=${this.state.shopId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}${bookingParam}${isSiteParam}`
									: `/invite-finish?id=${this.state.menuId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}${bookingParam}${isSiteParam}`;
						this.setState({ redirectUrl: successUrl });

						if (isMobile) {
							// Not open new tab on Mobile device because it can cause exception when user block open pop-up
							window.document.open();
							window.document.write(result.data.veritransHtmlResponse);
							window.document.close();
						} else {
							// Open new tab
							windowReference = window.open();
							windowReference.document.open();
							windowReference.document.write(result.data.veritransHtmlResponse);
							windowReference.document.close();
						}
					} else if (result.status === 406) { 
						let messageErr = t('pages.invite.Error create gochi');
						if (result.data.code === 'exception.errors.gochi.event.total.pre.amount') {
							messageErr = '申し訳ありません。\nこの商品は現在、先着販売の上限に達しており、ご購入いただけません。\n他のお客様の決済状況によって、今後購入できる可能性がありますが、状況によってはご購入いただけない場合もございます。';
						}
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
						return this.setState({
							errorMessage: messageErr,
							buttonDisabled: false
						});
					} else if (result.status === 402) {
						let messageErr = '3Dセキュア支払い実行時にエラーが発生しました。\nGOCHIプラットフォームのクレジット支払いは 3Dセキュア対応のカードのみとなっています。\n他の支払い手段をお試し下さい。\n3Dセキュア対応に関する詳細は カード発行会社に問い合わせ下さい。';
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
						return this.setState({
							errorMessage: messageErr,
							buttonDisabled: false
						});
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.not.found') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_not_found');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.total.limited') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_total_limited');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.booking.duplicate') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_booking_duplicate');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.params.invalid') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_params_invalid');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.activecode.invalid') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_activecode_invalid');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.booking.not.found') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_booking_not_found');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.localwallet.purchased') {
						this.showPopupErrorGochiCharge('本チケットは1度しか購入できません。マイページで購入したチケットをご確認ください。\n※購入されたチケットにチャージしたい場合は、マイページからお願いいたします。', "", "マイページ", "/mypage");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.security.has.csrf.token') {
						this.props.showError(true, t('pages.invite.error create gochi user'), "");
						this.props.showLoading(false);
						this.generateRandomString();
						return this.setState({
							errorMessage: t('pages.invite.error create gochi user'),
							buttonDisabled: false
						});
					} else if (result.status === 404 || result.status === 400 || result.status === 500) {
						this.props.showError(true, t('pages.invite.error create gochi user'), "");
						this.props.showLoading(false);
						return this.setState({
							errorMessage: t('pages.invite.error create gochi user'),
							buttonDisabled: false
						});
					} else if (result.status === 409) {
						this.showPopupErrorGochiCharge('現在ご覧いただいている商品は、販売停止またはチャージ機能停止のため、ご購入やチャージを行うことができません。', "", "関連商品を探す", "/products");
						this.props.showLoading(false);
						return this.setState({
							errorMessage: '現在ご覧いただいている商品は、販売停止またはチャージ機能停止のため、ご購入やチャージを行うことができません。',
							buttonDisabled: false
						});
					} else if (result.status === 504) { 
						this.props.showError(true, t('pages.invite.error create gochi with user'), "", t('pages.invite.go to mypage'), '/mypage?id='+this.state.menuId + (this.state.isSite ? '&isSite=' + this.state.isSite : ''));
						this.props.showLoading(false);
						return this.setState({
							errorMessage: t('pages.invite.error create gochi with user'),
							buttonDisabled: false
						});
					} else {
						this.generateRandomString();
						this.props.showError(true, t('pages.invite.error create gochi user'), "");
						this.props.showLoading(false);
						return this.setState({
							errorMessage: t('pages.invite.error create gochi user'),
							buttonDisabled: false
						});
					}
				} else if (this.state.paymentType === constants.PAYMENT_TYPE_STRIPE) {
					// create invite with payment Credit Card (Stripe)
					result = await MenuService.createInvite(
						inviteContent,
						this.state.crfsToken
					);

					log(result);
					if (result.status === 200) {
						// Not save data to props.menuInfo incase buy menu Price
						if(this.state.menuId && !this.state.shopId) {
							this.props.saveInvite(result.data);
						}

						var fullInviteContent = result.data;
						fullInviteContent.menuName = this.props.menuInfo.menuName;
						fullInviteContent.description = this.props.menuInfo.description;
						fullInviteContent.surveySubmissionId = this.state.surveySubmissionId;
						sessionStorage.setItem('fullInviteContent', JSON.stringify(fullInviteContent));
						sessionStorage.removeItem('messageCard');
						this.setState({
							errorMessage: "",
							redirectToReferrer: true,
						});
						this.props.showLoading(false);

						// set flag bookingCampaignFinish when gen gochi success
						const bookingCampaignFinish = sessionStorage.getItem("emailBookingCampaign") && sessionStorage.getItem("secretCode") && sessionStorage.getItem("monthBookingCampaign") && sessionStorage.getItem("campaignId") ? 1 : 0;
						sessionStorage.setItem("bookingCampaignFinish", bookingCampaignFinish);

					} else if (result.status === 406) { 
						let messageErr = t('pages.invite.Error create gochi');
						if (result.data.code === 'exception.errors.gochi.event.total.pre.amount') {
							messageErr = '申し訳ありません。\nこの商品は現在、先着販売の上限に達しており、ご購入いただけません。\n他のお客様の決済状況によって、今後購入できる可能性がありますが、状況によってはご購入いただけない場合もございます。';
						}
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
						return this.setState({
							errorMessage: messageErr,
							buttonDisabled: false
						});
					} else if (result.status === 402) {
						let messageErr = t('pages.invite.error create gochi using stripe incase other charging');
						if (result.data) {
							if (result.data.stripe_code === 'expired_card') {
								messageErr = t('pages.invite.error create gochi using stripe incase expired card');
							} else if (result.data.stripe_code === 'amount_too_small') {
								messageErr = t('pages.invite.error create gochi using stripe incase amount to small');
							} else if (result.data.stripe_code === 'insufficient_funds') {
								messageErr = t('pages.invite.error create gochi using stripe incase insufficient funds');
							} else if (result.data.stripe_code === 'card_velocity_exceeded') {
								messageErr = t('pages.invite.error create gochi using stripe incase card velocity exceeded');
							} 
							// subscription error
							else if (this.props.menuInfo.subscription === 1) {
								messageErr = t('pages.invite.error subsription failed');
							} 
							// Veritrans errors
							else if (result.data.veritrans_error_code === 'AG64000000000000') {
								messageErr = t('pages.invite.error create gochi using stripe incase expired card');
							} else if (result.data.veritrans_error_code === 'AG44000000000000') {
								messageErr = t('pages.invite.error create gochi using stripe incase insufficient funds');
							} else if (result.data.veritrans_error_code === 'AG45000000000000') {
								messageErr = t('pages.invite.error create gochi using stripe incase card velocity exceeded');
							} else if (result.data.veritrans_error_code === 'AG70000000000000' || result.data.veritrans_error_code === 'AG49000000000000' || result.data.veritrans_error_code === 'AG33000000000000') {
								messageErr = t('pages.invite.error create gochi using veritrans incase request denied');
							}
							// other cases
							else {
								messageErr = t('pages.invite.error create gochi user');
							}
						} 
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
						return this.setState({
							errorMessage: messageErr,
							buttonDisabled: false
						});
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.not.found') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_not_found');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.total.limited') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_total_limited');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.booking.duplicate') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_booking_duplicate');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.params.invalid') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_params_invalid');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.activecode.invalid') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_activecode_invalid');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.booking.not.found') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_booking_not_found');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.localwallet.purchased') {
            this.showPopupErrorGochiCharge('本チケットは1度しか購入できません。マイページで購入したチケットをご確認ください。\n※購入されたチケットにチャージしたい場合は、マイページからお願いいたします。', "", "マイページ", "/mypage");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.security.has.csrf.token') {
						this.props.showError(true, t('pages.invite.error create gochi user'), "");
						this.props.showLoading(false);
						this.generateRandomString();
						return this.setState({
							errorMessage: t('pages.invite.error create gochi user'),
							buttonDisabled: false
						});
					} else if (result.status === 404 || result.status === 400 || result.status === 500) {
						this.props.showError(true, t('pages.invite.error create gochi user'), "");
						this.props.showLoading(false);
						return this.setState({
							errorMessage: t('pages.invite.error create gochi user'),
							buttonDisabled: false
						});
					} else if (result.status === 409) {
						this.showPopupErrorGochiCharge('現在ご覧いただいている商品は、販売停止またはチャージ機能停止のため、ご購入やチャージを行うことができません。', "", "関連商品を探す", "/products");
						this.props.showLoading(false);
						return this.setState({
							errorMessage: '現在ご覧いただいている商品は、販売停止またはチャージ機能停止のため、ご購入やチャージを行うことができません。',
							buttonDisabled: false
						});
					} else if (result.status === 410) {
						this.props.showLoading(false);
						this.generateRandomString();
						this.setState({
							isShowClearCacheInstructionPopup: true,
							buttonDisabled: false
						});
					} else if (result.status === 504) { 
						this.props.showError(true, t('pages.invite.error create gochi with user'), "", t('pages.invite.go to mypage'), '/mypage?id='+this.state.menuId + (this.state.isSite ? '&isSite=' + this.state.isSite : ''));
						this.props.showLoading(false);
						return this.setState({
							errorMessage: t('pages.invite.error create gochi with user'),
							buttonDisabled: false
						});
					} else {
						this.generateRandomString();
						this.props.showError(true, t('pages.invite.error create gochi user'), "");
						this.props.showLoading(false);
						return this.setState({
							errorMessage: t('pages.invite.error create gochi user'),
							buttonDisabled: false
						});
					}
				// create invite with payment not Stripe
				} else {
					if (this.state.paymentType === constants.PAYMENT_TYPE_ONEPAY_ALIPAY ||
						this.state.paymentType === constants.PAYMENT_TYPE_ONEPAY_LINEPAY ||
						this.state.paymentType === constants.PAYMENT_TYPE_ONEPAY_MERUPAY ||
						this.state.paymentType === constants.PAYMENT_TYPE_WECHATPAY) {
						windowReference = window.open();
					}
					result = await MenuService.createSession(
						inviteContent
					);
					log(result);
					if (result.status === 200) {
						var paypayResponse = result.data;
						paypayResponse.menuName = this.props.menuInfo.menuName;
						paypayResponse.description = this.props.menuInfo.description;
						sessionStorage.setItem('paypayResponse', JSON.stringify(paypayResponse));
						var bookingParam = (sessionStorage.getItem("emailBookingCampaign") ? '&emailBookingCampaign=' + sessionStorage.getItem("emailBookingCampaign") : '') + (sessionStorage.getItem("secretCode") ? '&secretCode=' + sessionStorage.getItem("secretCode") : '') + (sessionStorage.getItem("monthBookingCampaign") ? '&monthBookingCampaign=' + sessionStorage.getItem("monthBookingCampaign") : '') + (sessionStorage.getItem("campaignId") ? '&campaignId=' + sessionStorage.getItem("campaignId") : '');
						var isSiteParam = this.state.isSite ? '&isSite=' + this.state.isSite : '';

						if (this.state.paymentType === constants.PAYMENT_TYPE_PAYPAY) {
							this.props.showLoading(false);
							this.setState({
								redirectUrl: !this.state.menuId && this.state.shopId ? 
									`/invite-finish?shopId=${this.state.shopId}&redirectFromPaypay=true&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}${bookingParam}${isSiteParam}` : 
										`/invite-finish?id=${this.state.menuId}&redirectFromPaypay=true&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}${bookingParam}${isSiteParam}`
							});
							if (isMobile) {
								window.location.href = result.data.redirectUrl;
							} else {
								window.open(result.data.redirectUrl, '_blank');
							}
						} else if (this.state.paymentType === constants.PAYMENT_TYPE_ATONE) {
							const redirectUrl = !this.state.menuId && this.state.shopId
								? `/invite-finish?shopId=${this.state.shopId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}${bookingParam}${isSiteParam}`
								: `/invite-finish?id=${this.state.menuId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}${bookingParam}${isSiteParam}`;
							await this.startAtoneCheckout(result.data.atoneJson, redirectUrl);
							this.setState({buttonDisabled: false});
							this.props.showLoading(false);
						} else {
							this.props.showLoading(false);
							// Payment type is Alipay or Linepay or Merupay
							// this.setState({
							// 	redirectUrl: `/invite-finish?id=${this.state.menuId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}&paymentGateway=onepay`
							// });
							const redirectUrl = !this.state.menuId && this.state.shopId ?
								`/invite-finish?shopId=${this.state.shopId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}&paymentGateway=onepay${bookingParam}${isSiteParam}`
								:
								`/invite-finish?id=${this.state.menuId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}&paymentGateway=onepay${bookingParam}${isSiteParam}`;
							// this.setState({
							// 	redirectToReferrer: true
							// });

							if (this.state.paymentType === constants.PAYMENT_TYPE_ONEPAY_ALIPAY && !isMobile) {
								// Show QR code
								windowReference.location = `${process.env.REACT_APP_DOMAIN_URL}/alipay-qr-code?value=${encodeURIComponent(result.data.redirectUrl)}&ts=${Date.now()}`;
								window.location.href = redirectUrl;
							} else {
								windowReference.location = result.data.redirectUrl;
								setTimeout(() => {
									try {
										const refHostname = windowReference.location.hostname;
										if (refHostname !== 'mobile.alipay.com')
											windowReference.close();
									} catch (e) {}
									window.location.href = redirectUrl;
								}, 5000);
							}
							
							// Payment type = WechatPay
							if (this.state.paymentType === constants.PAYMENT_TYPE_WECHATPAY) {
								if (!isMobile) {
									// Show QR code
									windowReference.location = `${process.env.REACT_APP_DOMAIN_URL}/wechatpay-qr-code?value=${encodeURIComponent(result.data.redirectUrl)}&ts=${Date.now()}`;
									window.location.href = redirectUrl;
								} else {
									window.location.href = result.data.redirectUrl;
								}
							}
						}

						// set flag bookingCampaignFinish when gen gochi success
						const bookingCampaignFinish = sessionStorage.getItem("emailBookingCampaign") && sessionStorage.getItem("secretCode") && sessionStorage.getItem("monthBookingCampaign") && sessionStorage.getItem("campaignId") ? 1 : 0;
						sessionStorage.setItem("bookingCampaignFinish", bookingCampaignFinish);

     					return null;
					} else if (result.status === 406) { 
						let messageErr = t('pages.invite.Error create gochi');
						if (result.data.code === 'exception.errors.gochi.event.total.pre.amount') {
							messageErr = '申し訳ありません。\nこの商品は現在、先着販売の上限に達しており、ご購入いただけません。\n他のお客様の決済状況によって、今後購入できる可能性がありますが、状況によってはご購入いただけない場合もございます。';
						}
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
						if (windowReference) {
							windowReference.close();
						}
						return this.setState({
							errorMessage: messageErr,
							buttonDisabled: false
						})
					} else if (result.status === 409) { 
						this.showPopupErrorGochiCharge('現在ご覧いただいている商品は、販売停止またはチャージ機能停止のため、ご購入やチャージを行うことができません。', "", "関連商品を探す", "/products");
						this.props.showLoading(false);
						if (windowReference) {
							windowReference.close();
						}
						return this.setState({
							errorMessage: '現在ご覧いただいている商品は、販売停止またはチャージ機能停止のため、ご購入やチャージを行うことができません。',
							buttonDisabled: false
						})
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.not.found') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_not_found');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.total.limited') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_total_limited');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.booking.duplicate') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_booking_duplicate');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.params.invalid') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_params_invalid');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.activecode.invalid') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_activecode_invalid');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.booking.not.found') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_booking_not_found');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.localwallet.purchased') {
            this.showPopupErrorGochiCharge('本チケットは1度しか購入できません。マイページで購入したチケットをご確認ください。\n※購入されたチケットにチャージしたい場合は、マイページからお願いいたします。', "", "マイページ", "/mypage");
						this.props.showLoading(false);
            if (windowReference) {
							windowReference.close();
						}
					} else if (result.status === 404 || result.status === 400 || result.status === 500) {
						this.props.showError(true, t('pages.invite.error create gochi user'), "");
						this.props.showLoading(false);
						if (windowReference) {
							windowReference.close();
						}
						return this.setState({
							errorMessage: t('pages.invite.error create gochi user'),
							buttonDisabled: false
						})
					} else {
						this.props.showError(true, t('pages.invite.error create gochi with guest'), "");
						this.props.showLoading(false);
						if (windowReference) {
							windowReference.close();
						}
						return this.setState({
							errorMessage: t('pages.invite.error create gochi with guest'),
							buttonDisabled: false
						})
					}
				}
			} else {
				this.props.showError(true, "There is something wrong", "");
				this.props.showLoading(false);
				this.setState({
					errorMessage: "Fail",
					buttonDisabled: false
				})
			}
		} catch (error) {
			log(error);
			this.props.showError(true, t('other.Unexpected error'), "");
			this.props.showLoading(false);
			if (windowReference) {
				windowReference.close();
			}
			this.setState({
				errorMessage: "Fail",
				buttonDisabled: false
			})
		}
	}

	async createGuestInvite(inviteContent) {
		const { t } = this.props;
		this.props.showLoading(true);
		this.setState({buttonDisabled: true});
		let windowReference;
		try { 
			if (inviteContent) {
				let result = null;
				this.setState({
					redirectUrl: ''
				});
				this.setState({
					redirectUrlStripe: ''
				});
				var bookingParam = (sessionStorage.getItem("emailBookingCampaign") ? '&emailBookingCampaign=' + sessionStorage.getItem("emailBookingCampaign") : '') + (sessionStorage.getItem("secretCode") ? '&secretCode=' + sessionStorage.getItem("secretCode") : '') + (sessionStorage.getItem("monthBookingCampaign") ? '&monthBookingCampaign=' + sessionStorage.getItem("monthBookingCampaign") : '') + (sessionStorage.getItem("campaignId") ? '&campaignId=' + sessionStorage.getItem("campaignId") : '');
				var isSiteParam = this.state.isSite ? '&isSite=' + this.state.isSite : '';

				if (!this.state.paymentType || this.state.paymentType === constants.PAYMENT_TYPE_STRIPE) {
					// create invite of guest with payment Stripe
					// windowReference = window.open();
					result = await MenuService.createSession(
						inviteContent
					);

					log(["error guest", result]);
					if (result.status === 200) {
						var content = {
							...inviteContent,
							"sessionId": result.data.stripeSessionId,
							"situation": this.props.friend.friendInfo && this.props.friend.friendInfo.situation ? this.props.friend.friendInfo.situation.trim() : "",
							"image": this.props.menuInfo.menuImage && (this.props.menuInfo.menuImage.length > 0) ? this.props.menuInfo.menuImage[0].image : "",
							"message": this.props.comment ? this.props.comment.trim() : "",
							"shopMessage": this.props.messageShop.messageText ? this.props.messageShop.messageText.trim() : "", // error encode-decode base 64 and encode-decode uri with symbol %|％
							"showShopMessage": this.props.messageShop.publicMessage ? this.props.messageShop.publicMessage : 0,
							"gochiUserType": this.props.friend.gochiUserType,
							"gochiRegisterId": 0,
							"gochiUserId": this.props.friend ? this.props.friend.selectedFriendId : null,
							"completed": false,
							"surveySubmissionId": this.state.surveySubmissionId
						}

						var contentComplete = {
							"sessionId": result.data.stripeSessionId,
							"shopId": inviteContent.shopId,
							"groupId": this.props.menuInfo.groupId,
							"preShopId": null,
							"gochiType": inviteContent.gochiType,
							"menuId": inviteContent.menuId,
							"situation": this.props.friend.friendInfo && this.props.friend.friendInfo.situation ? this.props.friend.friendInfo.situation.trim() : "",
							"image": this.props.menuInfo.menuImage && (this.props.menuInfo.menuImage.length > 0) ? this.props.menuInfo.menuImage[0].image : "",
							"message": this.props.comment ? this.props.comment.trim() : "",
							"shopMessage": this.props.messageShop.messageText ? this.props.messageShop.messageText.trim() : "", // error encode-decode base 64 and encode-decode uri with symbol %|％
							"showShopMessage": this.props.messageShop.publicMessage ? this.props.messageShop.publicMessage : 0,
							"gochiUserType": this.props.friend.gochiUserType,
							"gochiRegisterId": 0,
							"gochiUserId": null,
							"customerEmail": this.props.guestInfo && this.props.guestInfo.customerEmail,
							"customerName": this.props.guestInfo && this.props.guestInfo.customerName,
							"src": inviteContent.src,
							"joinMogugoriRank": this.state.joinMogugoriRank ? 1 : 0
						}
						console.log('save invite content');
						sessionStorage.setItem('inviteContent', JSON.stringify(contentComplete));
						sessionStorage.setItem('fullInviteContent', JSON.stringify(content));

						// this.props.showLoading(false);
						// this.state.stripe.redirectToCheckout({
						// 	sessionId: result.data.stripeSessionId
						// }).then(function (data) {
						// 	if(data.error) {
						// 		this.props.showError(true, data.error.message, "");
						// 		this.props.showLoading(false);
						// 		return this.setState({
						// 			errorMessage: data.error.message,
						// 			buttonDisabled: false
						// 		})
						// 	}
						// });
						let urlStripe = "/stripeCheckout?sessionId=" + result.data.stripeSessionId;
						// windowReference.location = urlStripe;
						// window.open("about:blank", "/stripeCheckout?sessionId=" + result.data.stripeSessionId, '_blank');
						this.props.showLoading(false);
						this.setState({
							redirectUrlStripe: !this.state.menuId && this.state.shopId ? `/invite-finish?shopId=${this.state.shopId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}${bookingParam}${isSiteParam}` : 
												`/invite-finish?id=${this.state.menuId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}${bookingParam}${isSiteParam}`
						});
						if (isMobile) {
							window.location.href = urlStripe;
						} else {
							window.open(urlStripe, '_blank');
						}

						// set flag bookingCampaignFinish when gen gochi success
						const bookingCampaignFinish = sessionStorage.getItem("emailBookingCampaign") && sessionStorage.getItem("secretCode") && sessionStorage.getItem("monthBookingCampaign") && sessionStorage.getItem("campaignId") ? 1 : 0;
						sessionStorage.setItem("bookingCampaignFinish", bookingCampaignFinish);

					} else if (result.status === 406) { 
						let messageErr = t('pages.invite.Error create gochi');
						if (result.data.code === 'exception.errors.gochi.event.total.pre.amount') {
							messageErr = '申し訳ありません。\nこの商品は現在、先着販売の上限に達しており、ご購入いただけません。\n他のお客様の決済状況によって、今後購入できる可能性がありますが、状況によってはご購入いただけない場合もございます。';
						}
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
						return this.setState({
							errorMessage: messageErr,
							buttonDisabled: false
						})
					} else if (result.status === 402) {
						let messageErr = t('pages.invite.error create gochi using stripe incase other charging');
						if (result.data) {
							if (result.data.stripe_code === 'expired_card') {
								messageErr = t('pages.invite.error create gochi using stripe incase expired card');
							} else if (result.data.stripe_code === 'amount_too_small') {
								messageErr = t('pages.invite.error create gochi using stripe incase amount to small');
							} else if (result.data.stripe_code === 'insufficient_funds') {
								messageErr = t('pages.invite.error create gochi using stripe incase insufficient funds');
							} else if (result.data.stripe_code === 'card_velocity_exceeded') {
								messageErr = t('pages.invite.error create gochi using stripe incase card velocity exceeded');
							}
						} 
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
						return this.setState({
							errorMessage: messageErr,
							buttonDisabled: false
						});
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.not.found') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_not_found');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.total.limited') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_total_limited');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.booking.duplicate') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_booking_duplicate');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.params.invalid') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_params_invalid');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.activecode.invalid') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_activecode_invalid');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.booking.not.found') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_booking_not_found');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.localwallet.purchased') {
            this.showPopupErrorGochiCharge('本チケットは1度しか購入できません。マイページで購入したチケットをご確認ください。\n※購入されたチケットにチャージしたい場合は、マイページからお願いいたします。', "", "マイページ", "/mypage");
						this.props.showLoading(false);
					} else if (result.status === 404 || result.status === 400 || result.status === 500) {
						this.props.showError(true, t('pages.invite.error create gochi user'), "");
						this.props.showLoading(false);
						return this.setState({
							errorMessage: t('pages.invite.error create gochi user'),
							buttonDisabled: false
						})
					} else if (result.status === 409) {
						this.showPopupErrorGochiCharge('現在ご覧いただいている商品は、販売停止またはチャージ機能停止のため、ご購入やチャージを行うことができません。', "", "関連商品を探す", "/products");
						this.props.showLoading(false);
						return this.setState({
							errorMessage: '現在ご覧いただいている商品は、販売停止またはチャージ機能停止のため、ご購入やチャージを行うことができません。',
							buttonDisabled: false
						})
					} else {
						this.props.showError(true, t('pages.invite.error create gochi with guest'), "");
						this.props.showLoading(false);
						return this.setState({
							errorMessage: t('pages.invite.error create gochi with guest'),
							buttonDisabled: false
						})
					}
				} else if (this.state.paymentType === constants.PAYMENT_TYPE_VERITRANS) {
					// Create session checkout for Veritrans
					this.props.showLoading(false);
					window.pop.show();
					result = await MenuService.createSession(
						inviteContent
					);
					this.setState({buttonDisabled: false});
					log(["guest create checkout session Veritrans", result]);
					if (result.status === 200) {
						const options = {
							autoReturn: true,
							autoReturnDelay: 5,
							onSuccess: function () {
								log('success pop');
								const successUrl = !this.state.menuId && this.state.shopId
									? `/invite-finish?shopId=${this.state.shopId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}${bookingParam}${isSiteParam}`
									: `/invite-finish?id=${this.state.menuId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}${bookingParam}${isSiteParam}`;
								this.setState({ redirectUrl: successUrl });
								window.location.href = successUrl;

								this.handleBackFromFinish();
							}.bind(this),
							onFailure: function () {
								log('failure pop');
								// Wait for Veritrans Pop clean its styles (overflow: hidden)
								setTimeout(() => {
									this.props.showError(true, '3Dセキュア支払い実行時にエラーが発生しました。\nGOCHIプラットフォームのクレジット支払いは 3Dセキュア対応のカードのみとなっています。\n他の支払い手段をお試し下さい。\n3Dセキュア対応に関する詳細は カード発行会社に問い合わせ下さい。', "");
								}, 200);
							}.bind(this),
							onIncomplete: function () {
								log('incomplete pop');
							}
						};
						window.pop.pay(result.data.checkoutToken, options);

						// set flag bookingCampaignFinish when gen gochi success
						const bookingCampaignFinish = sessionStorage.getItem("emailBookingCampaign") && sessionStorage.getItem("secretCode") && sessionStorage.getItem("monthBookingCampaign") && sessionStorage.getItem("campaignId") ? 1 : 0;
						sessionStorage.setItem("bookingCampaignFinish", bookingCampaignFinish);

					} else {
						window.pop.hide();
						switch (result.status) {
							case 406:
								if (result.data.code === 'exception.errors.gochi.event.total.pre.amount') {
									const messageErr = '申し訳ありません。\nこの商品は現在、先着販売の上限に達しており、ご購入いただけません。\n他のお客様の決済状況によって、今後購入できる可能性がありますが、状況によってはご購入いただけない場合もございます。';
									this.props.showError(true, messageErr, "");
								} else {
									this.props.showError(true, t('pages.invite.Error create gochi'), "");
								}
								break;
							case 404:
							case 400:
								let messageErr;
								if (result.data.code === 'exception.errors.gochi.campaign.params.invalid') {
									messageErr = this.props.t('pages.bookingCamaign.campaign_params_invalid');
									this.props.showError(true, messageErr, "");
								} else if (result.data.code === 'exception.errors.gochi.campaign.activecode.invalid') {
									messageErr = this.props.t('pages.bookingCamaign.campaign_activecode_invalid');
									this.props.showError(true, messageErr, "");
								} else if (result.data.code === 'exception.errors.gochi.campaign.booking.not.found') {
									messageErr = this.props.t('pages.bookingCamaign.campaign_booking_not_found');
									this.props.showError(true, messageErr, "");
								} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.not.found') {
									messageErr = this.props.t('pages.bookingCamaign.campaign_not_found');
									this.props.showError(true, messageErr, "");
								} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.total.limited') {
									messageErr = this.props.t('pages.bookingCamaign.campaign_total_limited');
									this.props.showError(true, messageErr, "");
								} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.booking.duplicate') {
									messageErr = this.props.t('pages.bookingCamaign.campaign_booking_duplicate');
									this.props.showError(true, messageErr, "");
								} else if (result.data.code === 'exception.errors.localwallet.purchased') {
                  this.showPopupErrorGochiCharge('本チケットは1度しか購入できません。マイページで購入したチケットをご確認ください。\n※購入されたチケットにチャージしたい場合は、マイページからお願いいたします。', "", "マイページ", "/mypage");
								} else {
									this.props.showError(true, t('pages.invite.error create gochi user'), "");
								}
								break;
							case 500:
								this.props.showError(true, t('pages.invite.error create gochi user'), "");
								break;
							case 409:
								this.showPopupErrorGochiCharge('現在ご覧いただいている商品は、販売停止またはチャージ機能停止のため、ご購入やチャージを行うことができません。', "", "関連商品を探す", "/products");
								break;
							default:
								this.props.showError(true, t('pages.invite.error create gochi with guest'), "");
						}
					}
				} else {
					// create invite of guest with payment not Credit Card (Stripe/Veritrans)
					if (this.state.paymentType === constants.PAYMENT_TYPE_ONEPAY_ALIPAY ||
						this.state.paymentType === constants.PAYMENT_TYPE_ONEPAY_LINEPAY ||
						this.state.paymentType === constants.PAYMENT_TYPE_ONEPAY_MERUPAY ||
						this.state.paymentType === constants.PAYMENT_TYPE_WECHATPAY) {
						windowReference = window.open();
					}
					result = await MenuService.createSession(
						inviteContent
					);
					if (result.status === 200) {
						if (this.state.paymentType === constants.PAYMENT_TYPE_PAYPAY) {
							this.props.showLoading(false);
							sessionStorage.setItem('paypayResponse', JSON.stringify(result.data));
							this.setState({
								redirectUrl: !this.state.menuId && this.state.shopId ? `/invite-finish?shopId=${this.state.shopId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}${bookingParam}${isSiteParam}` : 
													`/invite-finish?id=${this.state.menuId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}${bookingParam}${isSiteParam}`
							});
							if (isMobile) {
								window.location.href = result.data.redirectUrl;
							} else {
								window.open(result.data.redirectUrl, '_blank');
							}
						} else if (this.state.paymentType === constants.PAYMENT_TYPE_ATONE) {
							const redirectUrl = !this.state.menuId && this.state.shopId
								? `/invite-finish?shopId=${this.state.shopId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}${bookingParam}${isSiteParam}`
								: `/invite-finish?id=${this.state.menuId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}${bookingParam}${isSiteParam}`;
							await this.startAtoneCheckout(result.data.atoneJson, redirectUrl);
							this.setState({buttonDisabled: false});
							this.props.showLoading(false);
						} else {
							this.props.showLoading(false);
							// Payment type is Alipay or Linepay or Merupay
							// this.setState({
							// 	redirectUrl: `/invite-finish?id=${this.state.menuId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}&paymentGateway=onepay`
							// });
							const redirectUrl = !this.state.menuId && this.state.shopId ? `/invite-finish?shopId=${this.state.shopId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}&paymentGateway=onepay${bookingParam}${isSiteParam}` : 
										`/invite-finish?id=${this.state.menuId}&merchantPaymentId=${result.data.merchantPaymentId}&gochiUserType=${this.props.friend.gochiUserType}&paymentGateway=onepay${bookingParam}${isSiteParam}`;
							// this.setState({
							// 	redirectToReferrer: true
							// });
							if (this.state.paymentType === constants.PAYMENT_TYPE_ONEPAY_ALIPAY && !isMobile) {
								// Show QR code
								windowReference.location = `${process.env.REACT_APP_DOMAIN_URL}/alipay-qr-code?value=${encodeURIComponent(result.data.redirectUrl)}&ts=${Date.now()}`;
								window.location.href = redirectUrl;
							} else {
								windowReference.location = result.data.redirectUrl;
								setTimeout(() => {
									try {
										const refHostname = windowReference.location.hostname;
										if (refHostname !== 'mobile.alipay.com')
											windowReference.close();
									} catch (e) {}
									window.location.href = redirectUrl;
								}, 5000);
							}

							// Payment type = WechatPay
							// if (this.state.paymentType === constants.PAYMENT_TYPE_WECHATPAY && !isMobile) {
							// 	// Show QR code
							// 	windowReference.location = `${process.env.REACT_APP_DOMAIN_URL}/wechatpay-qr-code?value=${encodeURIComponent(result.data.redirectUrl)}&ts=${Date.now()}`;
							// 	window.location.href = redirectUrl;
							// } else {
							// 	windowReference.location = result.data.redirectUrl;
							// 	setTimeout(() => {
							// 		try {
							// 			const refHostname = windowReference.location.hostname;
							// 			if (refHostname !== 'https://pay.weixin.qq.com/')
							// 				windowReference.close();
							// 		} catch (e) {}
							// 		window.location.href = redirectUrl;
							// 	}, 5000);
							// }
						}

						// set flag bookingCampaignFinish when gen gochi success
						const bookingCampaignFinish = sessionStorage.getItem("emailBookingCampaign") && sessionStorage.getItem("secretCode") && sessionStorage.getItem("monthBookingCampaign") && sessionStorage.getItem("campaignId") ? 1 : 0;
						sessionStorage.setItem("bookingCampaignFinish", bookingCampaignFinish);

						return null;
					} else if (result.status === 406) { 
						let messageErr = t('pages.invite.Error create gochi');
						if (result.data.code === 'exception.errors.gochi.event.total.pre.amount') {
							messageErr = '申し訳ありません。\nこの商品は現在、先着販売の上限に達しており、ご購入いただけません。\n他のお客様の決済状況によって、今後購入できる可能性がありますが、状況によってはご購入いただけない場合もございます。';
						}
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
						if (windowReference) {
							windowReference.close();
						}
						return this.setState({
							errorMessage: messageErr,
							buttonDisabled: false
						})
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.not.found') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_not_found');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.total.limited') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_total_limited');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.booking.duplicate') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_booking_duplicate');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.params.invalid') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_params_invalid');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.activecode.invalid') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_activecode_invalid');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.gochi.campaign.booking.not.found') {
						let messageErr;
						messageErr = this.props.t('pages.bookingCamaign.campaign_booking_not_found');
						this.props.showError(true, messageErr, "");
						this.props.showLoading(false);
					} else if (result.status === 400 && result.data.code === 'exception.errors.localwallet.purchased') {
            this.showPopupErrorGochiCharge('本チケットは1度しか購入できません。マイページで購入したチケットをご確認ください。\n※購入されたチケットにチャージしたい場合は、マイページからお願いいたします。', "", "マイページ", "/mypage");
						this.props.showLoading(false);
					} else if (result.status === 404 || result.status === 400 || result.status === 500) {
						this.props.showError(true, t('pages.invite.error create gochi user'), "");
						this.props.showLoading(false);
						if (windowReference) {
							windowReference.close();
						}
						return this.setState({
							errorMessage: t('pages.invite.error create gochi user'),
							buttonDisabled: false
						})
					} else if (result.status === 409) {
						this.showPopupErrorGochiCharge('現在ご覧いただいている商品は、販売停止またはチャージ機能停止のため、ご購入やチャージを行うことができません。', "", "関連商品を探す", "/products");
						this.props.showLoading(false);
						if (windowReference) {
							windowReference.close();
						}
						return this.setState({
							errorMessage: '現在ご覧いただいている商品は、販売停止またはチャージ機能停止のため、ご購入やチャージを行うことができません。',
							buttonDisabled: false
						})
					} else {
						this.props.showError(true, t('pages.invite.error create gochi with guest'), "");
						this.props.showLoading(false);
						if (windowReference) {
							windowReference.close();
						}
						return this.setState({
							errorMessage: t('pages.invite.error create gochi with guest'),
							buttonDisabled: false
						})
					}
				}
			} else {
				this.props.showError(true, "There is something wrong", "");
				this.props.showLoading(false);
				this.setState({
					errorMessage: "Fail",
					buttonDisabled: false
				})
			}
		} catch (error) {
			log(error);
			this.props.showError(true, "There is something wrong", "");
			this.props.showLoading(false);
			if (windowReference) {
				windowReference.close();
			}
			this.setState({
				errorMessage: "Fail",
				buttonDisabled: false
			})
		}
	}

	async onCreateInviteNoPayment(inviteContent) {
		const { t } = this.props;
		this.props.showLoading(true);
		this.setState({buttonDisabled: true});
		try {
			if (inviteContent) {
				let result = null;
				this.setState({
					redirectUrl: ''
				});
				this.setState({
					redirectUrlStripe: ''
				});

				result = await MenuService.createInviteNoPayment(
					inviteContent,
					this.state.crfsToken
				);

				log(result);
				if (result.status === 200) {
					// Not save data to props.menuInfo incase buy menu Price
					if(this.state.menuId && !this.state.shopId) {
						this.props.saveInvite(result.data);
					}

					var fullInviteContent = result.data;
					fullInviteContent.menuName = this.props.menuInfo.menuName;
					fullInviteContent.description = this.props.menuInfo.description;
					fullInviteContent.surveySubmissionId = this.state.surveySubmissionId;
					sessionStorage.setItem('fullInviteContent', JSON.stringify(fullInviteContent));
					sessionStorage.removeItem('messageCard');
					this.setState({
						errorMessage: "",
						redirectToReferrer: true,
					});
					this.props.showLoading(false);
				} else if (result.status === 406) { 
					this.generateRandomString();
					let messageErr = t('pages.invite.Error create gochi');
					if (result.data.code === 'exception.errors.gochi.event.total.pre.amount') {
						messageErr = '申し訳ありません。\nこの商品は現在、先着販売の上限に達しており、ご購入いただけません。\n他のお客様の決済状況によって、今後購入できる可能性がありますが、状況によってはご購入いただけない場合もございます。';
					}
					this.props.showError(true, messageErr, "");
					this.props.showLoading(false);
					return this.setState({
						errorMessage: messageErr,
						buttonDisabled: false
					});
				} else if (result.status === 400 && result.data.code === 'exception.errors.localwallet.purchased') {
          this.showPopupErrorGochiCharge('本チケットは1度しか購入できません。マイページで購入したチケットをご確認ください。\n※購入されたチケットにチャージしたい場合は、マイページからお願いいたします。', "", "マイページ", "/mypage");
					this.props.showLoading(false);
				} else if (result.status === 404 || result.status === 400 || result.status === 500) {
					this.generateRandomString();
					this.props.showError(true, t('pages.invite.error create gochi user'), "");
					this.props.showLoading(false);
					return this.setState({
						errorMessage: t('pages.invite.error create gochi user'),
						buttonDisabled: false
					});
				} else if (result.status === 409) {
					this.generateRandomString();
					this.showPopupErrorGochiCharge('現在ご覧いただいている商品は、販売停止またはチャージ機能停止のため、ご購入やチャージを行うことができません。', "", "関連商品を探す", "/products");
					this.props.showLoading(false);
					return this.setState({
						errorMessage: '現在ご覧いただいている商品は、販売停止またはチャージ機能停止のため、ご購入やチャージを行うことができません。',
						buttonDisabled: false
					});
				} else if (result.status === 504) { 
					this.generateRandomString();
					this.props.showError(true, t('pages.invite.error create gochi with user'), "", t('pages.invite.go to mypage'), '/mypage?id='+this.state.menuId + (this.state.isSite ? '&isSite=' + this.state.isSite : ''));
					this.props.showLoading(false);
					return this.setState({
						errorMessage: t('pages.invite.error create gochi with user'),
						buttonDisabled: false
					});
				} else {
					this.generateRandomString();
					this.props.showError(true, t('pages.invite.error create gochi user'), "");
					this.props.showLoading(false);
					return this.setState({
						errorMessage: t('pages.invite.error create gochi user'),
						buttonDisabled: false
					});
				}
			} else {
				this.generateRandomString();
				this.props.showError(true, "There is something wrong", "");
				this.props.showLoading(false);
				this.setState({
					errorMessage: "Fail",
					buttonDisabled: false
				})
			}
		} catch (error) {
			log(error);
			this.props.showError(true, "There is something wrong", "");
			this.props.showLoading(false);
			this.setState({
				errorMessage: "Fail",
				buttonDisabled: false
			})
		}
	}

	async getUrl () {
		let url = '';
		if (this.state.redirectUrl) {
			url = this.state.redirectUrl;
		} else if (this.state.redirectUrlStripe) {
			url = this.state.redirectUrlStripe;
		}
		return url;
	}

	async goFinish () {
		let windowReference;
		windowReference = window.open();
		let url = await this.getUrl ();
		windowReference.location = url;
	}

	componentDidMount(){
		let url = window.location.search;
		let params = queryString.parse(url);
		var redirectFromStripe = params.redirectFromStripe;
		var menuId = params.id;
        this.setState({
			menuId: menuId
        });

		var shopId = params.shopId;
		if (shopId) {
			this.setState({
				shopId: shopId
			});
		}

		if (this.state.isMogugori) {
			this.setState({
				joinMogugoriRank: false
			})
		}

		this.generateRandomString();

		if (window.Stripe) {
	    this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)});
		} else {
			const stripeJs = document.querySelector('#stripe-js');
			if (stripeJs) {
				stripeJs.addEventListener('load', () => {
					// Create Stripe instance once Stripe.js loads
					this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)});
				});
			}
		}

		if(!menuId && shopId) {
			if (!this.props.shopInfo.updatedMenuPriceInfo) {
				var shopInfo = JSON.parse(sessionStorage.getItem('shopInfo'));

				if (shopInfo) {
					if (shopInfo.updatedMenuPriceInfo) {
						this.props.updateMenuPriceInfo(shopInfo.updatedMenuPriceInfo);
					} else {
						if (shopInfo.initTotalAmount) {
							this.props.saveInitTotalAmountMenuPrice(shopInfo.initTotalAmount);
						}
					}
					if (shopInfo.initAmount) {
						this.props.saveInitAmount(shopInfo.initAmount);
					}
					if (shopInfo.includedTaxMenuPrice) {
						this.props.saveInitMenuPrice(shopInfo.includedTaxMenuPrice);
					}
				}
			}
		} else {
			if (!this.props.menuInfo.updatedMenuInfo) {
				var menuInfo = JSON.parse(sessionStorage.getItem('menuInfo'));
	
				if (menuInfo) {
					if (menuInfo.updatedMenuInfo) {
						this.props.updateQuantityMenu(menuInfo.updatedMenuInfo);
					}else{
						if (menuInfo.initTotalAmount) {
							this.props.saveInitTotalAmount(menuInfo.initTotalAmount);
						}
					}
				}
			}
		}

		if (!this.props.messageShop.messageText || !this.props.messageShop.publicMessage) {
			var messageShop = JSON.parse(sessionStorage.getItem('messageShop')); 
			if (messageShop) {
				this.props.addMessageShop(messageShop.messageText, messageShop.publicMessage);
			}
		}

		if (this.props.friend.gochiUserType === constants.GOCHI_TYPE_YOURSELF) {
			var selectFriend = JSON.parse(sessionStorage.getItem('selectFriend'));
			const isMenuEventHiddenGochiUserType = constants.MENU_EVENT_HIDDEN_GOCHI_USER_TYPE.includes(this.props.menuInfo?.menuEventId);
			if (selectFriend && !isMenuEventHiddenGochiUserType) {
				this.props.selectFriend(selectFriend.selectedFriendId, selectFriend.friendType, selectFriend.friendInfo, selectFriend.gochiUserType);
			}
		}

		var comment = sessionStorage.getItem('comment') ? sessionStorage.getItem('comment') : ''; 
		if (comment) {
			this.props.commentInvite(comment);
		}

		if (!this.props.userInfo.isGuest) {
			if (!this.props.paymentMethod || Object.entries(this.props.paymentMethod).length === 0) {
				var payment = JSON.parse(sessionStorage.getItem('paymentMethod')); 
				this.props.savePaymentMethod(payment);
			}
		}else {
			if (this.props.menuInfo?.menuChildType === 5) {
				window.history.pushState(null, null, '/menu?id=' + this.state.menuId + this.state.paramShashoku);
				let link = '/menu?id=' + this.state.menuId + this.state.paramShashoku;
				window.location.href = link;
			}
			var guestInfo = sessionStorage.getItem('guestInfo');
			if (guestInfo) {
				this.props.saveGuestInfo(JSON.parse(guestInfo));
			}
		}

		if(!this.props.messageCard || (this.props.messageCard && (this.props.messageCard.thankYouText === undefined || this.props.messageCard.thankYouText === null))) {
			var messageCard = sessionStorage.getItem('messageCard') ? sessionStorage.getItem('messageCard') : null; 
			if (messageCard) {
				messageCard = JSON.parse(messageCard);
				this.props.addMessageCard(messageCard.thankYouText, messageCard.thankYouImage, messageCard.giftCardId);
			}
		}

		if (redirectFromStripe) {
			var inviteContent = JSON.parse(sessionStorage.getItem('inviteContent')); 

			if (inviteContent) {
				MenuService.getDetailMenu(inviteContent.menuId).then(response => {  
					if (this.state.menuId && !this.state.shopId) {
						this.props.saveMenuInfo(response.data);
					}
				}).catch(error => {
				});
			}
		}

		// initialize Atone
		if (window.Atone) {
			window.Atone.config({
				pub_key: process.env.REACT_APP_ATONE_PUB_KEY,
				payment: {},
				// This is called upon finishing the authentication or user registration.
				authenticated: function (authenticationToken, userNo) {
					console.log('authentication token ' + authenticationToken);
					console.log('user no ' + userNo);
					localStorage.setItem("atoneToken", authenticationToken);
				},
				// Call upon closing the modal.
				cancelled: function () {
					this.clearAtoneTimeout();
					console.log("cancelled and clear timeout");
				}.bind(this),
				// Call after pressing the button to close the form after the payment has returned as NG.
				failed: function (response) {
					this.clearAtoneTimeout();
					console.log('response failed ' + response);
				}.bind(this),
				// Call after the form has been automatically closed when the payment has returned as OK.
				succeeded: function (response) {
					this.clearAtoneTimeout();
					console.log('response succeeded ' + JSON.stringify(response));
				}.bind(this),
				// Call when an error occurs.
				error: function (name, message, errors) {
					console.log('response error name ' + name);
					console.log('response error message ' + message);
					console.log('response error errors ' + JSON.stringify(errors));
				}
			});
		} else if (!window.Atone && this.state.paymentType === constants.PAYMENT_TYPE_ATONE) {
			this.props.showError(true, "※広告ブロック等の理由により、atone決済が表示されません。atone決済をご利用ご希望の方は、ご利用のブラウザを変更してお試しください。", "");
		}
	} 

  handleJoinRankCheckBox(e) {
    let joinMogugoriRank = e.target.checked;
    this.setState({
      joinMogugoriRank: joinMogugoriRank
    });
    if (joinMogugoriRank) {
      this.setState({
        isOpenJoinMogugoriRankPopup: true
      });
    }
  }

  showPopupErrorGochiCharge(title, content, textButton, url) {
    this.setState({
      isShowErrorGochiCharge: true,
      titleErrorGochiCharge: title,
      contentErrorGochiCharge: content,
      textButtonErrorGochiCharge: textButton,
      urlErrorGochiCharge: url
    });
  }

	render() {
		log(["props of invite confirm", this.props]);
		var friend = this.props.friend.friendInfo;
		const { t } = this.props;
		var srcParam = this.state.src ? '&src=' + this.state.src : '';
		var isSiteParam = this.state.isSite ? {name: 'isSite', value: this.state.isSite} : {};

		var bookingParam = (sessionStorage.getItem("emailBookingCampaign") ? '&emailBookingCampaign=' + sessionStorage.getItem("emailBookingCampaign") : '') + (sessionStorage.getItem("secretCode") ? '&secretCode=' + sessionStorage.getItem("secretCode") : '') + (sessionStorage.getItem("monthBookingCampaign") ? '&monthBookingCampaign=' + sessionStorage.getItem("monthBookingCampaign") : '') + (sessionStorage.getItem("campaignId") ? '&campaignId=' + sessionStorage.getItem("campaignId") : '');

		var userInfo = this.props.userInfo;
        var link_login = addParamUrl("/menu?id=" + this.state.menuId + srcParam + this.state.paramShashoku, [isSiteParam]);
		var link_buygochi = addParamUrl("/invite?id=" + this.state.menuId + srcParam + this.state.paramShashoku, [isSiteParam]);
		let hasSession = sessionStorage.getItem('selectPayment');
		if(!hasSession) {
			window.history.pushState(null, null, link_buygochi);
			return <Redirect to={link_buygochi} />
		}
        let isLoggedIn = false;
		if (userInfo) {
            if (userInfo.userId) {
                isLoggedIn = true;
            }
			if (userInfo.isLogined === false) {
				if (!this.state.menuId && this.state.shopId) {
					window.history.pushState(null, null, addParamUrl('/invite?shopId=' + this.state.shopId + srcParam, [isSiteParam]));
					let link2 = addParamUrl('/invite?shopId=' + this.state.shopId + srcParam, [isSiteParam]);
					// return <Redirect to='/login' />
					return <Redirect to={link2} />
				} else {
					window.history.pushState(null, null, addParamUrl('/invite?id=' + this.state.menuId + srcParam, [isSiteParam]));
					return <Redirect to={link_login} />
				}
			}
		} else {
			if (!this.state.menuId && this.state.shopId) {
				window.history.pushState(null, null, addParamUrl('/invite?shopId=' + this.state.shopId + srcParam, [isSiteParam]));
				let link2 = addParamUrl('/invite?shopId=' + this.state.shopId + srcParam, [isSiteParam]);
				// return <Redirect to='/login' />
				return <Redirect to={link2} />
			} else {
				window.history.pushState(null, null, addParamUrl('/invite?id=' + this.state.menuId + srcParam, [isSiteParam]));
				return <Redirect to={link_login} />
			}
		}
		// Check if key on URL not in (id, shopId)
		if (!this.state.menuId && !this.state.shopId) {
			window.history.pushState(null, null, addParamUrl('/menu?id=' + srcParam, [isSiteParam]));
			let linkToLogin = addParamUrl("/menu?id=" + srcParam + this.state.paramShashoku, [isSiteParam]);
			return <Redirect to={linkToLogin} />
		}
		var userName = "";
		if (this.props.friend.gochiUserType === constants.GOCHI_TYPE_ANYONE) {
			userName = "&userName=" + userInfo.displayName;
		}

		var link = "";
		if(!this.state.menuId && this.state.shopId) {
			link = addParamUrl("/invite-finish?shopId="+ this.state.shopId + userName + srcParam + bookingParam, [isSiteParam]);
		} else {
			link = addParamUrl("/invite-finish?id="+ this.state.menuId + userName + srcParam + bookingParam, [isSiteParam]);
		}

		if (this.state.paymentType === constants.PAYMENT_TYPE_ONEPAY_ALIPAY || this.state.paymentType === constants.PAYMENT_TYPE_ONEPAY_LINEPAY || this.state.paymentType === constants.PAYMENT_TYPE_ONEPAY_MERUPAY) {
			link = this.state.redirectUrl;
		}
		const redirectToReferrer = this.state.redirectToReferrer;
		if (redirectToReferrer === true) {
			if(!this.state.menuId && this.state.shopId) {
				window.history.pushState(null, null, addParamUrl('/invite-confirm?shopId=' + this.state.shopId + srcParam + this.state.paramUsername + this.state.paramEmail + this.state.paramShashoku, [isSiteParam]));
				let linkShop = addParamUrl("/invite-finish?shopId=" + this.state.shopId + userName + srcParam + bookingParam, [isSiteParam]);
				return <Redirect to={linkShop} />
			} else {
				window.history.pushState(null, null, addParamUrl('/invite-confirm?id=' + this.state.menuId + srcParam + this.state.paramUsername + this.state.paramEmail + this.state.paramShashoku, [isSiteParam]));
				return <Redirect to={link} />
			}
        }

        const expirationEndDate = this.props.menuInfo.menuComboDetail && this.props.menuInfo.menuComboDetail.expirationEndDate ? new Date(this.props.menuInfo.menuComboDetail.expirationEndDate) : null; 

		// const selectPayment = parseInt(sessionStorage.getItem('selectPayment'));
		// let selectPaymentStr = '';
		// switch (selectPayment) {
		// 	case constants.PAYMENT_TYPE_ONEPAY_ALIPAY:
		// 		selectPaymentStr = t('pages.invite.payment alipay');
		// 		break;
		// 	case constants.PAYMENT_TYPE_ONEPAY_LINEPAY:
		// 		selectPaymentStr = t('pages.invite.payment linepay');
		// 		break;
		// 	case constants.PAYMENT_TYPE_ONEPAY_MERUPAY:
		// 		selectPaymentStr = t('pages.invite.payment merupay');
		// 		break;
		// 	case constants.PAYMENT_TYPE_PAYPAY:
		// 		selectPaymentStr = t('pages.invite.payment paypay');
		// 		break;
		// 	case constants.PAYMENT_TYPE_STRIPE:
		// 		selectPaymentStr = t('pages.invite.payment other');
		// 		break;		
		// 	default:
		// 		break;
		// }
		let today = new Date();
		let expiredDayOfMenu = today.setDate(today.getDate() + 180);
		const menuExpiration = Object.entries(this.props.menuInfo).length > 0 && this.props.menuInfo.menuExpiration ? new Date(this.props.menuInfo.menuExpiration) : new Date(expiredDayOfMenu);
		const ticketFree = this.props.menuInfo.menuComboDetail && this.props.menuInfo.menuComboDetail.quantityFree && this.props.menuInfo.menuComboDetail.quantityFree !== 0 ? this.props.menuInfo.menuComboDetail.quantityFree : 0;

		const displayFeeBurden = (Object.entries(this.props.menuInfo).length > 0 ? (this.props.menuInfo.feeSystem !== 0 && (!this.props.menuInfo.feePaidByShop || typeof this.props.menuInfo.feePaidByShop === 'undefined')) : (Object.entries(this.props.shopInfo).length > 0 && this.props.shopInfo.feeSystem !== 0 && (!this.props.shopInfo.feePaidByShop || typeof this.props.shopInfo.feePaidByShop === 'undefined')));
		const displayCampaignDiscount = (Object.entries(this.props.menuInfo).length > 0 && this.props.menuInfo.discountRate !== 0) || (Object.entries(this.props.shopInfo).length > 0 && this.props.shopInfo.discountRate !== 0);
		const feePaidByShop = Object.entries(this.props.menuInfo).length > 0 ? (this.props.menuInfo.feePaidByShop && typeof this.props.menuInfo.feePaidByShop !== 'undefined' ? this.props.menuInfo.feePaidByShop : false) : (Object.entries(this.props.shopInfo).length > 0 && this.props.shopInfo.feePaidByShop && typeof this.props.shopInfo.feePaidByShop !== 'undefined') ? this.props.shopInfo.feePaidByShop : false;

		const menuEventId = this.props.menuInfo?.menuEventId;

		const isNoPayment2 = this.props.menuInfo?.noPayment === 2;
		const isGochiCharge = this.props.menuInfo?.menuChildType === 5;
		const chargeAmountZero = this.props.menuInfo?.chargeAmount === 0;

        const isCampaign = this.props.menuInfo?.campaignId ? true : false;
    const isMenuEventHiddenGochiUserType = constants.MENU_EVENT_HIDDEN_GOCHI_USER_TYPE.includes(this.props.menuInfo?.menuEventId);
		if (this.props.menuInfo?.menuChildType === 3) {
			sessionStorage.removeItem('messageCard');
		}

		return (
			<>
				<div className="content">
					<Header
						title={(this.props.shopInfo.shopName || (this.props.groupInfo ? this.props.groupInfo.groupName : ''))} 
						isLoggedIn={isLoggedIn}
						t={t} 
					/>
					{/* <div className="heading-page">
						<div className="heading-img text-center">
							<div className="heading-ttl ex-ttl-gochi">{t('pages.invite.Put the food')}</div>
						</div>
					</div> */}

					{this.state.renderSurvey && !!menuEventId && (
						<SurveyForm surveyType={1} menuEventId={menuEventId} 
							onInitSuccess={() => this.setState({showSurvey: true})} 
							onSubmitSuccess={(surveySubmissionId) => this.setState({showSurvey: false, surveySubmissionId})} 
							onInitFailed={() => this.setState({showSurvey: false})}/>
					)}

					{(!menuEventId || (menuEventId && this.state.showSurvey === false)) && <div className="input-infor">
						<div className="mt-3">
							<ul className="progressbar align-center">
								<li className="step-choice"><strong>{t('steps.step_choice')}</strong></li>
								<li className="step-select"><strong>{t('steps.step_select')}</strong></li>
								<li className="step-confirm active"><strong>{t('steps.step_confirm')}</strong></li>
								<li className="step-complete"><strong>{t('steps.step_complete')}</strong></li>
							</ul>
						</div>
						{((this.props.friend.gochiUserType && this.props.friend.gochiUserType !== constants.GOCHI_TYPE_YOURSELF) || (!this.props.friend.gochiUserType)) 
							&& (this.props.menuInfo && this.props.menuInfo.taxType !== 'DONATION') 
							&& (this.props.menuInfo && this.props.menuInfo.menuChildType !== 3) && !isCampaign && !this.state.isMogugori && !isMenuEventHiddenGochiUserType && (
							<>
								<div className="fs-16 text-center mt-1">
									{t('pages.invite.The following cards will be given')}
								</div>
								<div className="line-horizontal mt-1 mb-3"></div>
								<div className="box-card align-center">
									<img
										// width="100%"
										className="mr-3"
										src={this.props.messageCard.thankYouImage}
										alt="Thank you card"
									/>
								</div>
							</>
						)}
						{this.props.menuInfo &&
							<div className="dish-infor mt-4">
								<div className="media">
									<div className="dish-image">
										<figure className="mb-0 dish-fig">
											{Object.entries(this.props.menuInfo).length === 0 ? 
												<img
													src={require("../../../assets/img/icons/gochi_price.jpg")}
													alt={t('pages.menu.Menu Price')}
													width="100%"
												/>
												:
												<img 
													src={this.props.menuInfo.menuImage && 
																	this.props.menuInfo.menuImage.length > 0 ? 
																	this.props.menuInfo.menuImage[0].image : 
																	require("../../../assets/img/icons/gochi_noimg-ver4.jpg")}
													alt={this.props.menuInfo.menuName} width="130" height="130"
												/>
											}
										</figure>
										{this.props.menuInfo && this.props.menuInfo.menuCombo === 1 && (
											<div>
											<div className="triangle-label">
												<span>{t('pages.invite.Coupon')}</span>
											</div>
											{ticketFree !== 0 && 
												<div className={(feePaidByShop && typeof feePaidByShop !== 'undefined') ? "bonus-label has-feePaidByShop" : "bonus-label"}>
													<span className="clr-fff">
														{`+`}{t('pages.invite.Advantageous (total)', { freeTicketCount: ticketFree, totalCoupon: ticketFree + this.props.menuInfo.menuComboDetail.quantity})}
													</span>
												</div>
											}
										</div>
										)}
									</div>
									<div className="media-body dish-body pl-2">
										<div className="mt-0 dish-link fs-19">
											{Object.entries(this.props.menuInfo).length > 0 ? this.props.menuInfo.menuName : t('pages.menu.Menu Price')}
											{Object.entries(this.props.menuInfo).length > 0 && this.props.menuInfo.menuCombo === 1 && (
												<>
													<br />
													{this.props.menuInfo.menuComboDetail ? this.props.menuInfo.menuComboDetail.includedTaxPriceCombo : 0}円ｘ
													{this.props.menuInfo.menuComboDetail ? this.props.menuInfo.menuComboDetail.quantity + ticketFree : 0}{t('pages.login.sheet')}
													{ticketFree !== 0 ? `(${t('pages.invite.Include N ticket free', {number: ticketFree})})` : null}
												</>
											)}
											<div className="line-horizontal menu-name-ln pd-bold"></div>
											<div className="fs-19">
												{Object.entries(this.props.menuInfo).length > 0 ? 
													this.props.menuInfo.updatedMenuInfo ? this.props.menuInfo.updatedMenuInfo.totalAmount.toLocaleString() : Object.entries(this.props.menuInfo).length !== 0 && this.props.menuInfo.initTotalAmount && this.props.menuInfo.initTotalAmount.toLocaleString()
													: 
													this.props.shopInfo.updatedMenuPriceInfo ? this.props.shopInfo.updatedMenuPriceInfo.totalAmount.toLocaleString() : this.props.shopInfo.initTotalAmount && this.props.shopInfo.initTotalAmount.toLocaleString()
												}
												<span className="fs-13">円</span>
											</div>
										</div>

										{/* {this.props.shopInfo && (
											<div className="local-shop fs-13 mt-1"><i className="icon-location"></i>{this.props.shopInfo.prefectures + this.props.shopInfo.city + this.props.shopInfo.address + this.props.shopInfo.building}</div>
										)} */}

										{/* <div className="dish-price fs-14 font-weight-bold">
											¥{this.props.menuInfo.includedTaxPrice && this.props.menuInfo.includedTaxPrice.toLocaleString()}円<span className="fs-12">（{this.props.menuInfo.taxType === 'DONATION' ? t('pages.invite.TaxDonation') : t('pages.invite.Tax')}{this.props.menuInfo.excludedTaxPrice && this.props.menuInfo.excludedTaxPrice.toLocaleString()}円）</span>
										</div> */}

										{/* <div className="dish-price font-weight-bold mt-2">
											<span className="fs-19 clr-000000">
												{Object.entries(this.props.menuInfo).length > 0 ? 
													this.props.menuInfo.updatedMenuInfo ?
														this.props.menuInfo.updatedMenuInfo.quantity :
														(this.props.menuInfo.menuNCount && this.props.menuInfo.menuNCount.count ? this.props.menuInfo.menuNCount.count : 1)
													: 
													this.props.shopInfo.updatedMenuPriceInfo ? 
													this.props.shopInfo.updatedMenuPriceInfo.quantity : 1
												}
											</span>
											<span className="fs-12 clr-000000">{t('pages.invite.pieces')}</span>
										</div> */}
									</div>
								</div>
								{this.props.menuInfo && this.props.menuInfo.description && (
									<div className="fs-12 des-menu mt-1 break-line">
										{/* <ShowMoreText
											lines={3}
											more={t('pages.mypage.showmore')}
											less={t('pages.mypage.showless')}
											anchorClass='showmore'
											onClick={this.executeOnClick}
											expanded={false}
											width={0}
										>	
											<span className='break-all'>
												{this.props.menuInfo.description}
											</span>
										</ShowMoreText> */}
										{this.props.menuInfo.description}
									</div>
								)}
								
								{this.props.menuInfo.menuComboDetail && 
									this.props.menuInfo.menuComboDetail.quantityFreeDes && (
										<>
											<div className="arrow-up"></div>
											<div className="div-free-ticket-desc break-line break-word">
												<div className="font-weight-bold">{t('pages.invite.Bonus')}</div>
												<div>{this.props.menuInfo.menuComboDetail.quantityFreeDes}</div>
											</div>
										</>
								)}
								{ticketFree !== 0 && (
									<div className={(feePaidByShop && typeof feePaidByShop !== 'undefined') ? "mt-2 has-feePaidByShop" : "mt-2"}>
										<span className="menu-combo-total-coupons">
											{`+`}{t('pages.invite.Advantageous (total)', { freeTicketCount: ticketFree, totalCoupon: ticketFree + this.props.menuInfo.menuComboDetail.quantity})}
										</span>
									</div>
								)}
								{feePaidByShop && typeof feePaidByShop !== 'undefined' && (
									<div className="mt-2 mb-3">
										<span className="menu-combo-total-coupons">
											{t('pages.invite.Fee paid by shop')}
										</span>
									</div>
								)}

								{this.props.menuInfo.menuExpiration && (
									<div className="mt-2">
										<span className="fs-12 clr-000000">使用期限</span>
										<span className="text-red fs-12 mt-2 ml-2 font-weight-bold">{moment(this.props.menuInfo?.menuExpiration).format('YYYY年MM月DD日まで')}</span>
									</div>
								)}

								{/*{this.props.menuInfo.menuComboDetail && 
									this.props.menuInfo.menuComboDetail.expiration && (
									<div>
										<span className="fs-12 clr-000000">{t('pages.login.usage period')}</span>
										<span className="text-red fs-12 mt-2 ml-2 font-weight-bold">{t('pages.login.available days', {days: this.props.menuInfo.menuComboDetail.expiration})}</span>
									</div>
								)}

								{expirationEndDate && (
									<div>
										<span className="fs-12 clr-000000">{t('pages.login.usage period')}</span>
										<span className="text-red fs-13 mt-2 font-weight-bold ml-2">
											{t('pages.login.available to date', {date: `${expirationEndDate.getFullYear()}/${expirationEndDate.getMonth() +
												1 < 10 ? '0' : ''}${expirationEndDate.getMonth() +
												1}/${expirationEndDate.getDate()}`})}
										</span>
									</div>
								)}*/}
								{this.props.menuInfo.menuComboDetail && 
									this.props.menuInfo.menuComboDetail.numberUsePerDay === 1 && (
										<div>
											<span className="fs-12 clr-000000">{t('pages.login.usage limit')}</span>
											<span className="text-red fs-13 font-weight-bold ml-2">{t('pages.login.1 sheet per day')}</span>
										</div>
								)}
								{this.props.friend && (this.props.friend.gochiUserType !== constants.GOCHI_TYPE_YOURSELF || !this.props.friend.gochiUserType) && (
									<div className="fs-18 space-pre-line break-word mt-2" style={{"padding": "0 10px"}}>
										{this.props.messageCard && this.props.messageCard.thankYouText}
									</div>
								)}
								{(this.props.menuInfo.menuChildType === 3 || this.props.menuInfo.menuChildType === 4 || this.props.menuInfo.menuChildType === 5) && (
									<button type="button" onClick={this.handleShowListShops} className="btn btn-outline-green-38 mt-3 align-center fs-13" style={{"height": "30px"}}>
										{t('pages.login.List of stores where this meal voucher can be used')}
									</button>
								)}
							</div>
						}

						<div className="line-horizontal break-ln mt-3 mb-5"></div>

						<div className="content-dark">
							<div className="pm-confirm-div">
								<div className="mt-0 dish-link fs-23 font-weight-bold clr-000000 break-all">
									{Object.entries(this.props.menuInfo).length > 0 ? this.props.menuInfo.menuName : t('pages.menu.Menu Price')}
									{/* {Object.entries(this.props.menuInfo).length > 0 && this.props.menuInfo.menuCombo === 1 && (
										<>
											<br />
											{this.props.menuInfo.menuComboDetail ? this.props.menuInfo.menuComboDetail.priceCombo : 0}円ｘ
											{this.props.menuInfo.menuComboDetail ? this.props.menuInfo.menuComboDetail.quantity + ticketFree : 0}{t('pages.login.sheet')}
											{ticketFree !== 0 ? `(${ticketFree}${t('pages.invite.Free of charge')})` : null}
										</>
									)} */}
								</div>
								<div>
									{this.props.menuInfo.menuChildType !== 5 && (
										<>
											<span className="fs-16">
												{t('pages.invite.Menu valid until', {year: menuExpiration.getFullYear(), month: `${menuExpiration.getMonth() + 1 < 10 ? '0' : ''}${menuExpiration.getMonth() + 1}`, day: menuExpiration.getDate()})}
											</span>
											<br/>
										</>
									)}
									<span className="fs-15">
										・{t('pages.invite.Number of sheets')}:
											{Object.entries(this.props.menuInfo).length > 0 ? 
												this.props.menuInfo.updatedMenuInfo ?
													this.props.menuInfo.updatedMenuInfo.quantity :
													(this.props.menuInfo.menuNCount && this.props.menuInfo.menuNCount.count ? this.props.menuInfo.menuNCount.count : 1)
												: 
												this.props.shopInfo.updatedMenuPriceInfo ? 
												this.props.shopInfo.updatedMenuPriceInfo.quantity : 1
											}
											{t('pages.login.sheet')}
									</span>

								{this.props.menuInfo.subscription === 1 && (
								<div className="d-flex">
									<span className="fs-15 mr-1">・{t('pages.invite.subscription interval')}{t('pages.invite.colon')}</span>
									<span className="fs-15">{`${t('pages.invite.per')}${this.props.menuInfo.subscriptionIntervalCount}${
								this.props.menuInfo.subscriptionInterval === 'WEEK' ? t('pages.invite.week') : t('pages.invite.month')
								}${t('pages.invite.one time')}`}</span>
									</div>
								)}
								</div>
								<div className="form-group mt-2 mb-2">
									<div className="d-flex justify-content-between fs-18 clr-000000 align-items-end" style={{borderBottom: "1px solid #000000"}}>
										<div className="mr-2" style={{lineHeight: "36px"}}>{isNoPayment2 ? '申込額' : t('pages.invite.Bill amount')}</div>
										<div className="fs-30 font-weight-bold">
											{Object.entries(this.props.menuInfo).length > 0 ? 
												this.props.menuInfo.updatedMenuInfo ? this.props.menuInfo.updatedMenuInfo.totalAmount.toLocaleString() : Object.entries(this.props.menuInfo).length !== 0 && this.props.menuInfo.initTotalAmount && this.props.menuInfo.initTotalAmount.toLocaleString()
												: 
												this.props.shopInfo.updatedMenuPriceInfo ? this.props.shopInfo.updatedMenuPriceInfo.totalAmount.toLocaleString() : this.props.shopInfo.initTotalAmount && this.props.shopInfo.initTotalAmount.toLocaleString()
											}
											<span className="fs-16">円</span>
										</div>
									</div>
								</div>
								{this.state.seeMorePriceDetail && (
									<>
										<div className="form-group mt-2 mb-1">
											<div className="d-flex justify-content-between fs-16 clr-000000">
												<div className="mr-2">{t('pages.invite.Subtotal')}</div>
												<div className="fs-17 font-weight-bold">
													{Object.entries(this.props.menuInfo).length > 0 ? 
														this.props.menuInfo.updatedMenuInfo && this.props.menuInfo.updatedMenuInfo.priceIncludedTax ? this.props.menuInfo.updatedMenuInfo.priceIncludedTax.toLocaleString() : (this.props.menuInfo && this.props.menuInfo.includedTaxPrice && this.props.menuInfo.includedTaxPrice.toLocaleString())
														:
														this.props.shopInfo.updatedMenuPriceInfo && this.props.shopInfo.updatedMenuPriceInfo.priceIncludedTax ? this.props.shopInfo.updatedMenuPriceInfo.priceIncludedTax.toLocaleString() : (this.props.shopInfo && this.props.shopInfo.includedTaxMenuPrice && this.props.shopInfo.includedTaxMenuPrice.toLocaleString())
													}
													<span className="fs-10">{t('pages.invite.Yen Unit')}</span>
												</div>
											</div>
										</div>
										<div className="form-group mt-2">
											<div className="d-flex justify-content-between fs-16 clr-000000">
												<div className="mr-2">{t('pages.invite.Sales commission')}</div>
												<div className="fs-17 font-weight-bold">
													{Object.entries(this.props.menuInfo).length > 0 ? 
														this.props.menuInfo.updatedMenuInfo && this.props.menuInfo.updatedMenuInfo.saleCommission ? this.props.menuInfo.updatedMenuInfo.saleCommission.toLocaleString(): (this.props.menuInfo && this.props.menuInfo.feeRate && this.props.menuInfo.includedTaxPrice && Math.round(this.props.menuInfo.feeRate * this.props.menuInfo.includedTaxPrice))
														:
														this.props.shopInfo.updatedMenuPriceInfo && this.props.shopInfo.updatedMenuPriceInfo.saleCommission ? this.props.shopInfo.updatedMenuPriceInfo.saleCommission.toLocaleString() : (this.props.shopInfo && this.props.shopInfo.feeRate && Math.round(this.props.shopInfo.feeRate * this.props.shopInfo.includedTaxMenuPrice).toLocaleString())
													}
													<span className="fs-10">{t('pages.invite.Yen Unit')}</span>
												</div>
											</div>
										</div>
										{displayFeeBurden && (
											<div className="form-group mt-2">
												<div className="d-flex justify-content-between fs-16 clr-000000">
													<div className="mr-2">{t('pages.invite.Fee burden')}</div>
													<div className="fs-17 font-weight-bold">
														-
														{Object.entries(this.props.menuInfo).length > 0 ? 
															this.props.menuInfo.updatedMenuInfo && this.props.menuInfo.updatedMenuInfo.feeBurden ? this.props.menuInfo.updatedMenuInfo.feeBurden.toLocaleString(): (this.props.menuInfo && this.props.menuInfo.feeSystem && this.props.menuInfo.includedTaxPrice && Math.round(this.props.menuInfo.feeSystem * this.props.menuInfo.includedTaxPrice))
															:
															this.props.shopInfo.updatedMenuPriceInfo && this.props.shopInfo.updatedMenuPriceInfo.feeBurden ? this.props.shopInfo.updatedMenuPriceInfo.feeBurden.toLocaleString() : (this.props.shopInfo && this.props.shopInfo.feeSystem && Math.round(this.props.shopInfo.feeSystem * this.props.shopInfo.includedTaxMenuPrice).toLocaleString())
														}
														<span className="fs-10">{t('pages.invite.Yen Unit')}</span>
													</div>
												</div>
											</div>
										)}
										{displayCampaignDiscount && (
											<div className="form-group mt-2">
												<div className="d-flex justify-content-between fs-16 clr-000000">
													<div className="mr-2">{t('pages.invite.discount')}</div>
													<div className="fs-17 font-weight-bold">
														-
														{Object.entries(this.props.menuInfo).length > 0 ? 
															this.props.menuInfo.updatedMenuInfo && this.props.menuInfo.updatedMenuInfo.discount ? this.props.menuInfo.updatedMenuInfo.discount.toLocaleString(): (this.props.menuInfo && this.props.menuInfo.discountRate && this.props.menuInfo.includedTaxPrice && Math.round(this.props.menuInfo.discountRate * this.props.menuInfo.includedTaxPrice))
															:
															this.props.shopInfo.updatedMenuPriceInfo && this.props.shopInfo.updatedMenuPriceInfo.discount ? this.props.shopInfo.updatedMenuPriceInfo.discount.toLocaleString() : (this.props.shopInfo && this.props.shopInfo.discountRate && Math.round(this.props.shopInfo.discountRate * this.props.shopInfo.includedTaxMenuPrice).toLocaleString())
														}
														<span className="fs-10">{t('pages.invite.Yen Unit')}</span>
													</div>
												</div>
											</div>
										)}
										{feePaidByShop && typeof feePaidByShop !== 'undefined' && (
											<div className="form-group mt-2">
												<div className="d-flex justify-content-between fs-16 clr-000000">
													<div className="mr-2 text-red">{t('pages.invite.feePaidByShop')}</div>
													<div className="fs-17 font-weight-bold text-red">
														-{Object.entries(this.props.menuInfo).length > 0 ? 
															this.props.menuInfo.updatedMenuInfo && this.props.menuInfo.updatedMenuInfo.feeShopAmount ? this.props.menuInfo.updatedMenuInfo.feeShopAmount.toLocaleString(): (this.props.menuInfo && this.props.menuInfo.feeShopRate && this.props.menuInfo.includedTaxPrice && Math.round(this.props.menuInfo.feeShopRate * this.props.menuInfo.includedTaxPrice))
															:
															this.props.shopInfo.updatedMenuPriceInfo && this.props.shopInfo.updatedMenuPriceInfo.feeShopAmount ? this.props.shopInfo.updatedMenuPriceInfo.feeShopAmount.toLocaleString() : (this.props.shopInfo && this.props.shopInfo.feeShopRate && Math.round(this.props.shopInfo.feeShopRate * this.props.shopInfo.includedTaxMenuPrice).toLocaleString())
														}
														<span className="fs-10">{t('pages.invite.Yen Unit')}</span>
													</div>
												</div>
											</div>
										)}
									</>
								)}
								<div className="align-center mt-1">
									<button type="button" className="btn btn-see-price-detail" onClick={this.handleSeeMorePriceDetail}>
										{!this.state.seeMorePriceDetail ? t('pages.invite.See the price detail'): t('pages.invite.Close the price detail')}
									</button>
								</div>
								{/* hide payment confirm when menu is gochi charge menu_child_type = 5 */}
								{!(isGochiCharge && chargeAmountZero) && (
								<div className="pm-info">
									{this.props.userInfo.isLogined && !this.props.userInfo.isGuest ? 
										(this.props.paymentMethod && (this.state.paymentType === constants.PAYMENT_TYPE_STRIPE || this.state.paymentType === constants.PAYMENT_TYPE_VERITRANS)) ?
											<div className="dish-infor text-center">
												<label className="ttl-label fs-16 clr-040404">
													{t('pages.invite.paymentMethodConfirm')}
												</label>

												<div className="payment-info">
													<div className="fs-16 clr-040404">{this.props.paymentMethod.brand}****-****-****-{this.props.paymentMethod.last4}</div>
													<div className="fs-16 font-weight-bold">{this.props.paymentMethod.holder}</div>
													<div className="payment-date">
														<div className="">
															<span>{t('pages.invite.expiredDate')}: </span>
															<span>{this.props.paymentMethod.expireMonth && this.props.paymentMethod.expireMonth.toString().padStart(2, '0')}/{this.props.paymentMethod.expireYear}</span>
														</div>
													</div>
												</div>
											</div>
											:
											<div className="dish-infor text-center">
												<label className="ttl-label fs-16 clr-040404">
													{t('pages.invite.paymentMethodConfirm')}
												</label>
												<div className="payment-info">
													<div className="fs-16 font-weight-bold">
														{this.state.paymentType === constants.PAYMENT_TYPE_PAYPAY && t('pages.invite.payment paypay')}
														{this.state.paymentType === constants.PAYMENT_TYPE_ONEPAY_ALIPAY && t('pages.invite.payment alipay')}
														{this.state.paymentType === constants.PAYMENT_TYPE_WECHATPAY && t('pages.invite.payment wechatpay')}
														{this.state.paymentType === constants.PAYMENT_TYPE_ONEPAY_LINEPAY && t('pages.invite.payment linepay')}
														{this.state.paymentType === constants.PAYMENT_TYPE_ONEPAY_MERUPAY && t('pages.invite.payment merupay')}
														{this.state.paymentType === constants.PAYMENT_TYPE_ATONE && t('pages.invite.payment atone')}
														{this.state.paymentType === constants.PAYMENT_TYPE_SELF_CHECKOUT && this.props.menuInfo?.noPayment === 1 && t('pages.invite.payment bank transfer')}
														{this.state.paymentType === constants.PAYMENT_TYPE_SELF_CHECKOUT && this.props.menuInfo?.noPayment === 2 && t('pages.invite.payment form submit')}
													</div>
												</div>
											</div>
										:
										this.props.guestInfo && 
										<>
											{(this.state.paymentType) ?
												<div className="dish-infor text-center mb-2">
													<label className="ttl-label fs-16 clr-040404">
														{t('pages.invite.paymentMethodConfirm')}
													</label>

													<div className="payment-info">
														<div className="fs-16 font-weight-bold">
															{this.state.paymentType === constants.PAYMENT_TYPE_PAYPAY && t('pages.invite.payment paypay')}
															{this.state.paymentType === constants.PAYMENT_TYPE_ONEPAY_ALIPAY && t('pages.invite.payment alipay')}
															{this.state.paymentType === constants.PAYMENT_TYPE_WECHATPAY && t('pages.invite.payment wechatpay')}
															{this.state.paymentType === constants.PAYMENT_TYPE_ONEPAY_LINEPAY && t('pages.invite.payment linepay')}
															{this.state.paymentType === constants.PAYMENT_TYPE_ONEPAY_MERUPAY && t('pages.invite.payment merupay')}
															{this.state.paymentType === constants.PAYMENT_TYPE_ATONE && t('pages.invite.payment atone')}
															{this.state.paymentType === constants.PAYMENT_TYPE_STRIPE && t('pages.invite.payment other')}
															{this.state.paymentType === constants.PAYMENT_TYPE_VERITRANS && t('pages.invite.payment other')}
															{this.state.paymentType === constants.PAYMENT_TYPE_SELF_CHECKOUT && this.props.menuInfo?.noPayment === 1 && t('pages.invite.payment bank transfer')}
															{this.state.paymentType === constants.PAYMENT_TYPE_SELF_CHECKOUT && this.props.menuInfo?.noPayment === 2 && t('pages.invite.payment form submit')}
														</div>
													</div>
												</div>
												:
												<div className="dish-infor text-center mb-2">
													<label className="ttl-label fs-16 clr-040404">
														{t('pages.invite.paymentMethodConfirm')}
													</label>
													<div className="payment-info">
														<div className="fs-16 font-weight-bold">
															{t('pages.invite.payment other')}
														</div>
													</div>
												</div>
											}
										</>
									}

									{this.state.redirectUrl &&
										<div className="text-recreate-paypay">
											{t('pages.invite.link recreate paypay')}
											<a onClick={this.goFinish} target="_blank" rel="noopener noreferrer" className="link-recreate-paypay">
												{t('pages.invite.link detail')}
											</a>
										</div>
									}
									{this.state.redirectUrlStripe &&
										<div className="text-recreate-paypay">
											{t('pages.invite.link recreate paypay')}
											<a onClick={this.goFinish} target="_blank" rel="noopener noreferrer" className="link-recreate-paypay">
												{t('pages.invite.link detail')}
											</a>
										</div>
									}
								</div>
								)}
							</div>
						</div>
						{/* redmine 115253: open ranking, hard code shop id = 26586 */}
						{this.state.isMogugori && (constants.SHOP_ID_SHOW_RANK_MOGUGORI.includes(parseInt(this.props.menuInfo.shopId)) || Object.keys(this.props.groupInfo).length !== 0 && constants.SHOP_ID_SHOW_RANK_MOGUGORI.some(shopId => this.props.groupInfo.shopIds.includes(shopId))) && (
							<div className="ranking-box text-center">
								<div className="mt-3 gochi-policy-check custom-control custom-checkbox custom-checkbox-inline">
									<input checked={this.state.joinMogugoriRank} onChange={this.handleJoinRankCheckBox} id="create-ranking" type="checkbox" className="custom-control-input create-rank" />
									<label htmlFor="policy-check" className="custom-control-label text-left">
										{t('pages.invite.agree create rank')}
									</label>
								</div>
							</div>
						)}
						{(this.props.menuInfo.taxType !== 'DONATION' && this.props.menuInfo.menuChildType !== 5) && (
							<div className="dish-infor mt-4">
								<table className="table table-infor">
									<tbody className="invite-confirm">
										{/* { this.props.menuInfo.updatedMenuInfo ?
											<tr>
												<th>{t('pages.invite.price')}</th>
												<td>{this.props.menuInfo.updatedMenuInfo.totalAmount.toLocaleString()}円（{this.props.menuInfo.taxType === 'DONATION' ? t('pages.invite.Tax-fee included donate') : t('pages.invite.Tax-fee included')}）</td>
											</tr>
										:
											(Object.entries(this.props.menuInfo).length !== 0) &&
												<tr>
													<th>{t('pages.invite.price')}</th>
													<td>{this.props.menuInfo.initTotalAmount && this.props.menuInfo.initTotalAmount.toLocaleString()}円（{this.props.menuInfo.taxType === 'DONATION' ? t('pages.invite.Tax-fee included donate') : t('pages.invite.Tax-fee included')}）</td>
												</tr>
										} */}

										<tr>
											<th className="pl-0">{t('pages.invite.How to dust')}</th>
											<td>
											<div className="selected-friend pl-0">
												{(this.props.friend.gochiUserType !== constants.GOCHI_TYPE_NON_USER && friend) ?
													<div className="media">
														{this.props.friend.gochiUserType === constants.GOCHI_TYPE_USER &&
															<div className="">
																<div className="fs-19 mb-1 font-weight-bold">{t('pages.invite.chooseSysFriend')}</div>
																<div className="media-body d-flex">
																	<img className="mr-1 friend-ava"
																		src={ friend.userImage ? friend.userImage[0].image : require("../../../assets/img/icons/gochi_noimg-ver4.jpg") }
																		alt={ friend.displayName } width="20" height="20"
																	/>
																	<div className="link-friend">{ friend.displayName }</div>
																	<span>{t('pages.invite.Feast on')}</span>
																</div>
															</div>
														}
														{this.props.friend.gochiUserType === constants.GOCHI_TYPE_ANYONE && 
															<div className="media-body">
																<div className="fs-19 font-weight-bold">
																	{t('pages.invite.noChooseSysFriend')}
																	{friend.situation &&
																		"("+t('pages.invite.Condition') +")"
																	}
																</div>
																<div className="link-friend d-flex align-items-center space-pre-line">
																	{friend.situation &&
																		<img className="mr-2 friend-ava"
																			src={ friend.userImage ? friend.userImage[0].image : require("../../../assets/img/icons/gochi_noimg-ver4.jpg") }
																			alt={ friend.displayName } width="20" height="20"
																		/>
																	}
																	{ friend.situation }
																</div>
															</div>
														}
													</div>
												:	
													<div>
														{this.props.friend.gochiUserType === constants.GOCHI_TYPE_NON_USER &&
														<div className="media-body">
															<div className="fs-19 font-weight-bold">{t('pages.invite.chooseSysFriend')}</div>
															{!this.props.userInfo.isGuest &&
															<div className="">{ReactHtmlParser(t('pages.invite.selectNoneUser'))}</div>
															}
														</div>
														}
													</div>
												}
												{this.props.friend.gochiUserType === constants.GOCHI_TYPE_YOURSELF &&
													<div className="media-body">
														<div className="fs-19 font-weight-bold">{t('pages.invite.chooseYourSelf')}</div>
													</div>
												}
											</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						)}
						

						{(this.props.comment || (this.props.messageShop && this.props.messageShop.messageText) || (this.props.friend && this.props.friend.friendInfo && this.props.friend.friendInfo.situation)) &&
							<>
								<div className="dish-infor mt-3">
									{/* <label className="ttl-label fs-16">
										{t('pages.invite.message confirm')}
									</label> */}
									{this.props.comment &&
										<div className="fs-18 space-pre-line clr-000000 mb-1">
											<span className="font-weight-bold">{t('pages.invite.title comment')}</span> <br/>
											<span>{this.props.comment}</span>
										</div>
									}
									{(this.props.friend && this.props.friend.friendInfo && this.props.friend.friendInfo.situation) &&
										<div className="fs-18 space-pre-line clr-000000">
											<span className="font-weight-bold">{t('pages.invite.title situation')}</span> <br/>
											<span>{this.props.friend.friendInfo.situation}</span>
										</div>
									}
									{(this.props.messageShop && this.props.messageShop.messageText) &&
										<div className="fs-18 space-pre-line break-word clr-000000 mt-1">
											<span className="font-weight-bold">{t('pages.invite.title message')}</span> <br/>
											<span>{this.props.messageShop.messageText}</span>
										</div>
									}
								</div>
								<div className="line-horizontal break-ln mt-3"></div>
							</>
						}

						{this.props.userInfo.isLogined && this.props.userInfo.isGuest && this.props.guestInfo && (
							<div className="mt-4">
								{this.props.guestInfo.customerName !== "" && (
									<div className="dish-infor mb-2">
										<label className="ttl-label fs-18 font-weight-bold clr-000000">
											{isNoPayment2 ? '受付完了メールに記載されるお名前' : t('pages.invite.Customer name confirm')}
										</label>

										<div className="space-pre-line fs-18">
											{this.props.guestInfo.customerName}
										</div>
									</div>
								)}
								{this.props.guestInfo.customerEmail !== "" && (
									<div className="dish-infor mb-2">
										<label className="ttl-label fs-18 font-weight-bold clr-000000">
											{t('pages.invite.Email address')}
										</label>

										<div className="space-pre-line fs-18">
											{this.props.guestInfo.customerEmail}
										</div>
										{/* <div className="fs-14 space-pre-line">
											{selectPaymentStr}
										</div> */}
									</div>
								)}
							</div>
						)}
						<div className="mt-4 text-left fs-12 text-policy-legal">
							※ {t('pages.invite.text policy firt')}<a href="https://gochimeshi.com/policy/legal/" target="_blank" className="link-policy-legal">{t('pages.invite.link policy legal')}</a>{t('pages.invite.text policy end')}
						</div>
						<div className="mt-4 text-center">
							<button onClick={this.submitInviteRequest} className="btn btn-green-38 mb-2" disabled={this.state.buttonDisabled}>
								{this.props.menuInfo?.noPayment === 2 ? t('pages.invite.Apply') : t('pages.invite.Order this menu')}
							</button>
							<Link 
								to={!this.state.menuId && this.state.shopId ? addParamUrl(`/invite?shopId=`+this.state.shopId + srcParam + this.state.paramShashoku, [isSiteParam]) : addParamUrl(`/invite?id=`+this.state.menuId + srcParam + this.state.paramShashoku, [isSiteParam])} 
								className="btn btn btn-outline-green-38 align-center" 
								disabled={this.state.buttonDisabled}
							>
								{t('pages.invite.Return to input screen')}
							</Link>
						</div>

						<div className="mt-3 fs-13" style={{"lineHeight": "21px"}}>
							{t('pages.invite.Please tap the button once')}
						</div>

						<div className="fs-13 mt-3 space-pre-line font-weight-bold" style={{"lineHeight": "21px"}}>
							{t('pages.invite.Cancellation policy')}
						</div>
						
					</div>}

				</div>
				{this.state.showListShopPopup && (
					<ListShop
						showListShopPopup={this.state.showListShopPopup}
						handleCloseListShopPopup={this.handleCloseListShopPopup}
					/>
				)}
        {this.state.isMogugori && (
          <Modal
            size="sm"
            show={this.state.isOpenJoinMogugoriRankPopup}
            onHide={() => this.setState({isOpenJoinMogugoriRankPopup: false})}
            className="join-ranking-popup"
            centered
          >
            <Modal.Header closeButton className="history-modal-header"></Modal.Header>
            <Modal.Body className="space-pre-line">
              {this.props.menuInfo.shopId && (
                <div className="image-shop">
                  {this.props?.shopInfo?.shopImage && this.props.shopInfo.shopImage[1] && (
                    <img
                      src={this.props.shopInfo.shopImage[1].image}
                      alt=""
                    />
                  )}
                </div>
              )}
              <div className="text-1">月間おごりユーザーに 参加する</div>
              <div className="text-2">月間おごりユーザーには、この名前で登録されます。 </div>
              <div className="name-user">（{this.props.userInfo.isGuest ? this.props.guestInfo.customerName : userInfo.displayName}）</div>
            </Modal.Body>
          </Modal>
        )}
        {this.state.isShowErrorGochiCharge && (
          <PopupErrorGochiCharge
            showing={this.state.isShowErrorGochiCharge}
            onHide={() => this.setState({isShowErrorGochiCharge: false})}
            title={this.state.titleErrorGochiCharge}
            content={this.state.contentErrorGochiCharge}
            gotoUrl={this.state.urlErrorGochiCharge}
            buttonName={this.state.textButtonErrorGochiCharge}
          />
        )}
        {this.state.isShowClearCacheInstructionPopup && (
          <ClearCacheInstructionPopup
            showing={this.state.isShowClearCacheInstructionPopup}
            onHide={() => this.setState({isShowClearCacheInstructionPopup: false})}
          />
        )}
			</>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	showLoading: (showing) => dispatch(showLoading(showing)),
	showError: (showing, title, content, buttonName, gotoUrl) =>dispatch(showError(showing, title, content, buttonName, gotoUrl)),
	savePaymentMethod: (paymentMethod) => dispatch(savePaymentMethod(paymentMethod)),
	saveInvite: (inviteContent) => dispatch(saveInvite(inviteContent)),
	saveMenuInfo: (menuInfo) => dispatch(saveMenuInfo(menuInfo)),
	updateQuantityMenu: (updatedMenuInfo) => dispatch(updateQuantityMenu(updatedMenuInfo)),
	updateMenuPriceInfo: (updatedMenuPriceInfo) => dispatch(updateMenuPriceInfo(updatedMenuPriceInfo)),
	commentInvite: (comment) => dispatch(commentInvite(comment)),
	addMessageShop: (messageText, publicMessage) => dispatch(addMessageShop(messageText, publicMessage)),
	selectFriend: (selectedFriendId, friendType, friendInfo, gochiUserType) => dispatch(selectFriend(selectedFriendId, friendType, friendInfo, gochiUserType)),
	saveInitTotalAmount: (totalAmount) => dispatch(saveInitTotalAmount(totalAmount)),
	saveInitTotalAmountMenuPrice: (totalAmount) => dispatch(saveInitTotalAmountMenuPrice(totalAmount)),
	saveInitMenuPrice: (initMenuPrice) => dispatch(saveInitMenuPrice(initMenuPrice)),
	saveInitAmount: (initAmount) => dispatch(saveInitAmount(initAmount)),
	saveGuestInfo: (guestInfo) => dispatch(saveGuestInfo(guestInfo)),
	addMessageCard: (thankYouText, thankYouImage, giftCardId) => dispatch(addMessageCard(thankYouText, thankYouImage, giftCardId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InviteConfirm)); 