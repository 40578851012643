import React, { useState } from "react";
import UpdateImageInputSurveyGochiKid from "../../common/UpdateImageInputSurveyGochiKid";
import loadImage from "blueimp-load-image";
import MenuService from "../../../../api/services/MenuService";
import { SURVEY_QUESTION_TYPE } from "../../../../constants";

export function GochiKidSurveyModal(props) {
  const { answers, questions, setAnswers, surveyDetails, handleSubmitGochiKidSurvey } = props;
  const [arrImage, setArrImage] = useState(() => {
    let newArr = [];
    questions.forEach((q, index) => {
      if (q.questionType === SURVEY_QUESTION_TYPE.IMAGE) {
        newArr[index] = [];
      }
    });
    return newArr;
  });
  const [previewImage, setPreviewImage] = useState(() => {
    let newArr = [];
    questions.forEach((q, index) => {
      if (q.questionType === SURVEY_QUESTION_TYPE.IMAGE) {
        newArr[index] = [];
      }
    });
    return newArr;
  });
  const [errors, setErrors] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [arrImageSubmit, setArrImageSubmit] = useState(null);

  function onChangeTextAnwers(index, value) {
    const newState = { ...answers };
    newState[index] = value;
    setAnswers(newState);
  }

  function onChangeRadioAnswer(index, value) {
    const newState = { ...answers };
    newState[index] = value;
    setAnswers(newState);
  }

  function onChangeCheckboxAnswer(index, checked, label) {
    const newState = { ...answers };

    if (!newState[index]) {
      newState[index] = new Set();
    }

    if (checked) {
      newState[index].add(label);
    } else {
      newState[index].delete(label);
    }

    setAnswers(newState);
  }

  const updateArrImage = (file, indexQ, indexA) => {
    const updateArrImages = [...arrImage];
    updateArrImages[indexQ][indexA] = file;
    setArrImage(updateArrImages);
  };

  const updateArrImagePreview = (file, indexQ, indexA) => {
    const updateArrImagePreviews = [...previewImage];
    updateArrImagePreviews[indexQ][indexA] = file;
    setPreviewImage(updateArrImagePreviews);
  };

  const removeImage = (indexQ, indexA) => {
    const newArr = [...arrImage];
    newArr[indexQ].splice(indexA, 1);
    setArrImage(newArr);
    const newArrPreview = [...previewImage];
    newArrPreview[indexQ].splice(indexA, 1);
    setPreviewImage(newArrPreview);
  };

  const toBlob = (base64, fileName, reject) => {
    const bin = atob(base64.replace(/^.*,/, ""));
    const buffer = new Uint8Array(bin.length);

    for (let i = 0; i < bin.length; i += 1) {
      buffer[i] = bin.charCodeAt(i);
    }

    try {
      const blob = new Blob([buffer.buffer], {
        type: "image/*",
      });

      blob.name = fileName;
      return blob;
    } catch (e) {
      reject();
      return false;
    }
  };

  const resizeImage = (image) => {
    return new Promise((resolve, reject) => {
      loadImage.parseMetaData(image, (data) => {
        const options = {
          maxWidth: process.env.REACT_APP_RESIZE_MAX_WIDTH,
          canvas: true,
        };

        if (data.exif) {
          options.orientation = data.exif.get("Orientation");
        }

        loadImage(
          image,
          (canvas) => {
            const imageUri = canvas.toDataURL("image/*");
            const resizeImageFile = toBlob(imageUri, image.name, reject);
            resolve({
              resizeImageFile,
            });
          },
          options
        );
      });
    });
  };

  async function validateAnswers() {
    setIsButtonDisabled(true);
    let newErrorState = {};
    let isValid = true;
    let arrImageP = [];

    for (let i = 0; i < questions.length; i++) {
      if (questions[i].questionType === SURVEY_QUESTION_TYPE.IMAGE) {
        arrImageP[i] = [];

        await Promise.all(
          arrImage[i].map(async (image, index) => {
            if (image !== null) {
              if (image.size > Number(process.env.REACT_APP_RESIZE_BORDER)) {
                const { resizeImageFile } = await resizeImage(image);
                image = new File([resizeImageFile], image.name, {
                  type: image.type,
                });
              }

              await MenuService.postImage(image, surveyDetails.surveyId)
                .then((response) => {
                  console.log("response", response);

                  if (response.status === 200) {
                    arrImageP[i][index] = response.data;
                    return arrImageP;
                  } else {
                    alert("画像のアップロードにエラーが発生しました。");
                    isValid = false;
                  }
                })
                .catch((e) => {
                  alert("画像のアップロードにエラーが発生しました。");
                  isValid = false;
                });
            }
            return null;
          })
        );
      }

      if (questions[i].required === true) {
        if (
          questions[i].questionType === SURVEY_QUESTION_TYPE.CHECKBOX &&
          answers[i].size === 0 &&
          questions[i].optionArr?.length > 0
        ) {
          newErrorState[i] = "この項目が必須です。";
          isValid = false;
        } else if (
          questions[i].questionType === SURVEY_QUESTION_TYPE.IMAGE &&
          arrImageP[i].filter(image => !!image).length === 0 &&
          questions[i].imageNumber > 0
        ) {
          newErrorState[i] = "この項目が必須です。";
          isValid = false;
        } else if (
          questions[i].questionType === SURVEY_QUESTION_TYPE.IMAGE &&
          arrImageP[i].filter(image => !!image).length < questions[i].imageNumber &&
          questions[i].imageNumber > 0
        ) {
          newErrorState[i] = "必要な枚数の画像をアップロードしてください。";
          isValid = false;
        } else if (
          (questions[i].questionType === SURVEY_QUESTION_TYPE.TEXT ||
            (questions[i].questionType === SURVEY_QUESTION_TYPE.RADIO 
              && questions[i].optionArr?.length > 0)) &&
          !answers[i]
        ) {
          newErrorState[i] = "この項目が必須です。";
          isValid = false;
        }
      }

      if (
        questions[i].questionType === SURVEY_QUESTION_TYPE.TEXT &&
        questions[i].regex &&
        answers[i]
      ) {
        const regex = new RegExp(questions[i].regex);
        if (!regex.test(answers[i])) {
          isValid = false;
          newErrorState[i] = questions[i].validationMessage;
        }
      }
    }
    setTimeout(() => setIsButtonDisabled(false), 1000);
    console.log("newErrorState", newErrorState);
    setErrors(newErrorState);
    setArrImageSubmit(arrImageP);
    return isValid;
  }

  const handleConfirmSurvey = async () => {
    const valid = await validateAnswers();

    if (valid) {
      setIsConfirm(true);
    }
  };

  const handleBackConfirm = () => {
    setIsConfirm(false);
  }

  const submitSurvey = async () => {
    const submission = questions.map((item, i) => {
      if (item.questionType === SURVEY_QUESTION_TYPE.CHECKBOX) {
        return Array.from(answers[i]).join(','); 
      } else if (item.questionType === SURVEY_QUESTION_TYPE.IMAGE) {
        return Array.from(arrImageSubmit[i]).join(',');
      } else { 
        return answers[i];
      }
    });
    await handleSubmitGochiKidSurvey(JSON.stringify(submission));
  }

  const handleClose = () => {
    if (isConfirm) {
      setIsConfirm(false);
    } else {
      props.onClose();
    }
  }

  return (
    <div
      className={
        props.showing
          ? "custom-modal-container group-special-gochi-kid-modal gochi-kid-preview-image-receipt-modal gochi-kid-survey-modal show-modal"
          : "custom-modal-container group-special-gochi-kid-modal gochi-kid-preview-image-receipt-modal gochi-kid-survey-modal"
      }
      size="sm"
    >
      <div
        className={
          props.showing
            ? "modal modal-action modal-dialog is-show history-modal"
            : "modal modal-action modal-dialog history-modal"
        }
      >
        <div className="modal-header-history">
          <button
            className="modal-btn modal-btn-close"
            id="modalClose"
            onClick={() => handleClose()}
            type="button"
          >
            <svg className="icon">
              <title>閉じる</title>
              <use xlinkHref="/assets/img/common/icon.svg#times" />
            </svg>
          </button>
        </div>
        <div className="title-modal title-modal-charge">
          <div className="title">アンケート回答</div>
        </div>
        <div className={`modal-body`}>
          {isConfirm ? (
            <div className="list-answer">
              {questions.map((q, index) => {
                let elementAnswer = "";
                if (q.questionType === SURVEY_QUESTION_TYPE.TEXT || q.questionType === SURVEY_QUESTION_TYPE.RADIO) {
                  elementAnswer = (
                    <div className="text-answer">{answers[index]}</div>
                  );
                } else if (q.questionType === SURVEY_QUESTION_TYPE.CHECKBOX) {
                  elementAnswer = (
                    <div className="text-answer">{Array.from(answers[index]).join(', ')}</div>
                  );
                } else if (q.questionType === SURVEY_QUESTION_TYPE.IMAGE) {
                  elementAnswer = (
                    <div className="image-answer-list">
                      {arrImageSubmit[index].map((a, indexA) => (
                        <img src={a} alt="image-answer" key={`${index} - ${indexA}`} />
                      ))}
                    </div>
                  );
                }

                return(
                  <div className="answer-box" key={q.surveyQuestionId}>
                    <div className="title-question">
                      {q.question}
                      {q.required && <span className="text-red">*</span>}
                    </div>
                    {elementAnswer}
                  </div>
                )})}
            </div>
          ) : (
            <>
              <div>
                <div className="survey-name">{surveyDetails.surveyName}</div>
                <div className="survey-description" dangerouslySetInnerHTML={{ __html: surveyDetails.surveyDescription }}></div>
              </div>
              {questions.map((q, index) => {
                let elementAnswer = "";

                if (q.questionType === SURVEY_QUESTION_TYPE.TEXT) {
                  elementAnswer = (
                    <input
                      className="answer-input"
                      type="text"
                      placeholder="回答をご入力ください。"
                      value={answers[index]}
                      onChange={(e) => onChangeTextAnwers(index, e.target.value)}
                    />
                  );
                } else if (
                  q.questionType === SURVEY_QUESTION_TYPE.RADIO &&
                  q.optionArr?.length > 0
                ) {
                  elementAnswer = (
                    <div className="answer-radio-box">
                      {q.optionArr.map((a) => (
                        <label className="radio-custom answer-custom" key={a}>
                          <input
                            type="radio"
                            onChange={(e) =>
                              onChangeRadioAnswer(index, e.target.value)
                            }
                            checked={answers[index] === a}
                            name={q.question}
                            value={a}
                          />
                          <span className="checkmark"></span>
                          <span className="text-answer">{a}</span>
                        </label>
                      ))}
                    </div>
                  );
                } else if (
                  q.questionType === SURVEY_QUESTION_TYPE.CHECKBOX &&
                  q.optionArr?.length > 0
                ) {
                  elementAnswer = (
                    <div className="answer-checkbox-box">
                      {q.optionArr.map((a) => (
                        <label className="checkbox-custom answer-custom" key={a}>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              onChangeCheckboxAnswer(index, e.target.checked, a)
                            }
                            checked={answers[index]?.has(a)}
                            name={q.question}
                            value={a}
                          />
                          <span className="checkmark"></span>
                          <span className="text-answer">{a}</span>
                        </label>
                      ))}
                    </div>
                  );
                } else if (
                  q.questionType === SURVEY_QUESTION_TYPE.IMAGE &&
                  q.imageNumber > 0
                ) {
                  elementAnswer = (
                    <div className="answer-image-box">
                      {[...Array(q.imageNumber)].map((a, indexA) => (
                        <div key={index + "image" + indexA}>
                          <UpdateImageInputSurveyGochiKid
                            label={index + "image" + indexA}
                            setFile={updateArrImage}
                            previewImage={
                              !!previewImage[index] && previewImage[index][indexA]
                            }
                            setPreviewImage={updateArrImagePreview}
                            indexImg={indexA}
                            indexQuestion={index}
                            removeImage={removeImage}
                            isOnlyImage
                          />
                        </div>
                      ))}
                    </div>
                  );
                }

                return (
                  <div className="question-box" key={q.surveyQuestionId}>
                    <div className="text-red">{errors[index]}</div>
                    <div className="title-question">
                      {q.question}
                      {q.required && <span className="text-red">*</span>}
                    </div>
                    {elementAnswer}
                  </div>
                );
              })}
            </>
          )}
          <div className="modal-footer">
            {isConfirm ? (
              <>
                <button
                  className="btn btn-rect btn-submit"
                  onClick={() => submitSurvey()}
                >
                  回答する
                </button>
                <button
                  className="btn btn-outline-rect btn-text"
                  onClick={() => handleBackConfirm()}
                >
                  前の画面に戻る
                </button>
              </>
            ) : (
              <button
                className="btn btn-rect btn-submit"
                onClick={() => handleConfirmSurvey()}
                disabled={isButtonDisabled}
              >
                確認画面へ進む
              </button>
            )}
          </div>
        </div>
      </div>
      <div
        onClick={() => props.onClose()}
        className={`back-drop ${
          props.accessToken && props.accessCode ? "back-drop-bold" : ""
        }`}
      />
    </div>
  );
}
