export const FRIEND_IN_SYS = 1;
export const FRIEND_OUT_SYS = 0;
export const GOCHI_TYPE_USER = "USER";
export const GOCHI_TYPE_NON_USER = "NONUSER";
export const GOCHI_TYPE_ANYONE = "ANYONE";
export const GOCHI_TYPE_DONATE = "USER_DONATE";
export const RESTRICTION_MENTION_ONLY = "MENTION_ONLY";
export const MENU_TYPE_DONATION = "DONATE";
export const SHOP_FOOD_APPROVED = 1;
export const DONATE_TYPE = 1;
export const GOCHI_TYPE_YOURSELF = "YOURSELF";
export const FRIEND_TYPE_YOURSELF = 1;
export const FRIEND_TYPE_USER = 2;
export const FRIEND_TYPE_ANYONE = 3;
export const GOCHI_PLATFORM_WEB = "WEB";
export const SHOP_STATUS_AVAILABLE = "AVAILABLE";
export const LINK_SHARE = "https://sakimeshi.com/detail/";
export const LINK_SHARE_SAKIMESHI = "https://sakimeshi.com";
export const PRE_REGISTER = "PRE_REGISTER";
export const PAYMENT_TYPE_STRIPE = 1;
export const PAYMENT_TYPE_PAYPAY = 2;
export const PAYMENT_TYPE_ONEPAY_ALIPAY = 3;
export const PAYMENT_TYPE_ONEPAY_LINEPAY = 4;
export const PAYMENT_TYPE_ONEPAY_MERUPAY = 5;
export const PAYMENT_TYPE_WECHATPAY = 6;
export const PAYMENT_TYPE_VERITRANS = 7;
export const PAYMENT_TYPE_CREDIT_CARD = process.env.REACT_APP_VERITRANS_ENABLE === 'true' ? PAYMENT_TYPE_VERITRANS : PAYMENT_TYPE_STRIPE;
export const PAYMENT_TYPE_ATONE = 8;
export const PAYMENT_TYPE_SELF_CHECKOUT = 100;
export const GOCHI_KID_PATH = "/gochikid";
export const GOCHI_KID_FOLDER_IMAGE = "gochiKid";

// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const SURVEY_QUESTION_TYPE = {
  TEXT: 1,
  RADIO: 2,
  CHECKBOX: 3,
  IMAGE: 4
};

export const GOCHI_TYPE = {
  MENU: "MENU",
  PRICE: "PRICE"
};

export const TAX_AMOUNT_TYPE = {
  TAX_INCLUDED_AMOUNT: 1,
  TAX_EXCLUDED_AMOUNT: 0
};

// redmine 116707: hard code hide shop gochi kid have id = 23359
export const HIDE_SHOP_GOCHI_KID_ID = 23359;

// redmine #117584: hardcode hide choose gochi user type with menu event id = 127
export const MENU_EVENT_HIDDEN_GOCHI_USER_TYPE = [127];

export const TAX_RATE = {
  TAX_RATE_10_PERCENT: 0.1,
  TAX_RATE_8_PERCENT: 0.08
};

export const MENU_USAGE_TYPE = {
  USAGE_TYPE_MENU: "MENU",
  USAGE_TYPE_VOUCHER: "VOUCHER",
  USAGE_TYPE_COMBO: "COMBO"
};

{/* redmine 115253: open ranking, hard code shop id = 26586 */}
{/* redmine 119867: open ranking, hard code shop id = 1014 */}
export const SHOP_ID_SHOW_RANK_MOGUGORI = [26586, 1014];

export const SCAN_CODE_TYPE = {
  BARCODE: "BARCODE",
  QRCODE: "QRCODE",
};