import React, { useEffect, useState } from "react";
import LoadingOverlay from "../../GiftSite/common/LoadingOverlay";
import AuthService from "../../../api/services/AuthService";
import { HTTP_API } from "../../../api/HttpClient";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";

function ToshiosAuth(props) {
  const history = useHistory();
  let redirectUrl = localStorage.getItem("toshiosAuthRedirectUrl") || "/login";
  if (props.userInfo.isLogined && !props.userInfo.isGuest) {
    redirectUrl = "/mypage";
  }
  const [loading, setLoading] = useState(true);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get('code');

  useEffect(() => {
    document.body.className = "auth signup";
    init(); 
  }, [])

  async function init() {
    setLoading(true);
    const response = await AuthService.getToshiosToken({ code });

    if (response.status === HTTP_API.SUCCESS) {
      localStorage.setItem("toshiosIdToken", response.data.toshiosIdToken);
      if (response.data.optInStatus === 1) {
        window.location.replace("/toshios-optin");
      } else {
        const optInServiceId = 56;
        const optInRedirectUrl = window.location.origin + "/toshios-optin";
        let optInUrl = `${process.env.REACT_APP_URBANOS_SITE_DOMAIN}/serviceAgreement`;
        optInUrl += "?serviceId=" + optInServiceId;
        optInUrl += "&redirectUrl=" + encodeURIComponent(optInRedirectUrl);
        window.location.replace(optInUrl);
      }
    } else {
      setErrorMessage("エラーが発生しました。もう一度試してください。");
      setShowErrorPopup(true);
    }
  }

  const onHideError = () => {
    setShowErrorPopup(false);
    history.replace(redirectUrl);
  };

  return (
    <>
      <Modal show={showErrorPopup} onHide={onHideError} className="modal-error">
        <div className="modal-body">
          <Button variant="secondary" className="close" onClick={onHideError}>&times;</Button>
          <div className="input-infor">
            <div>
              <h5 className="space-pre-line">{errorMessage}</h5>
            </div>
          </div>
        </div>
      </Modal>
      <LoadingOverlay loading={loading && !showErrorPopup} />
    </>
  );
}

const mapStateToProps = state => ({
  userInfo: state.userInfo
});

export default connect(mapStateToProps)(ToshiosAuth);
