import React from 'react';
import { Modal } from 'react-bootstrap';
import Moment from 'react-moment';

function GochiKidUseReceiptConfirmModal(props) {
  return (
    <Modal
      show={props.show}
      centered
      onHide={props.onCancel}
      className="modal-use clr-000000 gochi-kid-receipt-confirm-modal"
    >
      <Modal.Body className="font-gothic">
        <div className="fs-20 font-weight-bold align-center mt-4 mb-4 text-red">
          <i className='icon-warning' />
          レシート受取
        </div>

        <div className='fs-18'>
          <span>
            レシートをお受け取りください。
            <br />
            受け取ったレシートは、次回以降のご利用時にレシート写真の登録が必要です。
          </span>
          <span className='text-red'>登録がない場合、次回以降のご利用ができなくなります。</span>
          <br />
          <span>レシートを受け取った後は、下の「受取済み」ボタンを押して、お支払い完了画面へお進みください。</span>
          <br />
          <br />
          <div className='text-red'>
            ※【お試し企画】ローソンこども食堂」では、お買い物券をご利用の際に、レシートをお受け取りください。
          </div>
        </div>

        <button
          className="btn btn-green-38 bg-red mt-4 mb-4"
          onClick={props.onSubmit}
          disabled={props.disabledSubmitButton}
        >
          確認しました
        </button>
      </Modal.Body>
    </Modal>
  );
}

export default GochiKidUseReceiptConfirmModal;