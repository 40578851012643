/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import queryString from 'query-string';
import { connect, useSelector } from "react-redux";
import GochiService from "../../../../api/services/GochiService";
import UserService from "../../../../api/services/UserService";
import MenuService from "../../../../api/services/MenuService";
import PaymentService from "../../../../api/services/PaymentService";
import GiftService from "../../../../api/services/GiftService";
import {
  useUserDetails,
  useScript,
  getMonth,
  getDate,
  replaceString,
  formatDate,
  useWindowSize,
  addParamUrl,
} from "../../../../utils";
import { useHistory, useLocation, Link } from "react-router-dom";
import { GOCHI_KID_PATH } from '../../../../constants';
import { Footer } from "../../layout/Footer";
import Header from "../../layout/Header";
import { ReceiveGochi } from "./ReceiveGochi";
import { SubscriptionGochi } from "./SubscriptionGochi";
import { Receipt } from "./Receipt";
import { DetailGochi } from "./DetailGochi";
import { Follow } from "./Follow";
import { Ticket } from "./Ticket";
import { MenuInfo } from "../../common/MenuInfo";
import { PaymentInformations } from "./PaymentInformations";
import { Swiper, SwiperSlide } from "swiper/react";
import { getSlidePerView, swiperConfigs } from "../../common/SwiperConfigs";
import { SliderPagination } from "../../common/SliderPagination";
import { ModalGroup } from "../../common/ModalGroup";
import { SituationModal } from "../../common/SituationModal";
import { GiftToModal } from "../../common/GiftToModal";
import { NotLoggedModal } from "../../common/NotLoggedModal";
import { WishListModal } from "../../common/WishListModal";
import HowToModal from "../../common/HowToModal";
import { ExplanationMypage } from "../../layout/ExplanationMypage";
import { GochiCharge } from "./GochiCharge";
import { GochiChargeDetail } from "./GochiChargeDetail";
import { HistoryChargeGochi } from "./HistoryChargeGochi";
import { HistoryUsedGochiCharge } from "./HistoryUsedGochiCharge";
import LoadingOverlay from "../../common/LoadingOverlay";
import { GochiKidUsed } from "./GochiKidUsed";
import { GochiKidSelectShop } from "./GochiKidSelectShop";
import { GochiKidSelectMenu } from "./GochiKidSelectMenu";
import { SendMessageGochiKid } from "./SendMessageGochiKid";
import { SendMessageGochiKidSuccess } from "./SendMessageGochiKidSuccess";
import { GochiKidUsedDetail } from "./GochiKidUsedDetail";
import { GochiKidSupport } from "./GochiKidSupport";
import { GochiKidSupportContinue } from "./GochiKidSupportContinue";
import { GochiKidSupportMessage } from "./GochiKidSupportMessage";
import { RegisterUserKidGochiKid } from "./RegisterUserKidGochiKid";
import { RegisterUserKidGochiKidSuccess } from "./RegisterUserKidGochiKidSuccess";
import { CancelSubscriptionGochiKid } from "./CancelSubscriptionGochiKid";
import { CancelSubscriptionGochiKidSuccess } from "./CancelSubscriptionGochiKidSuccess";
import { SelectUserKidModal } from "../../common/SelectUserKidModal";
import ConfirmModal from "../../common/ConfirmModal";
import { InputGochiKidPriceModal } from "../../common/InputGochiKidPriceModal";
import { PolicyGochiKidUse } from "./PolicyGochiKidUse";
import { GroupSpecialGochiKidModal } from "./GroupSpecialGochiKidModal";
import { PolicyGochiKid } from "./PolicyGochiKid";
import { PolicyGochiKidCampaign } from "./PolicyGochiKidCampaign";
import moment from "moment";
import { ExplainInputGochiKid } from "./ExplainInputGochiKid";
import { AccessPolicyGochiKidReceiptModal } from "./AccessPolicyGochiKidReceiptModal";

export function MyPage(props) {
  useScript("/assets/js/app.js");

  const paramsUrl = queryString.parse(window.location.search);
  const isSite = paramsUrl.isSite;
  const isSiteParam = isSite ? {name: 'isSite', value: isSite} : {};
  const mogugori = isSite === 'mogugori' ? true : false;
  const history = useHistory();
  const location = useLocation();

  const user = useSelector((state) => state.userInfo);
  const userInfo = queryString.parse(window.location.search).accessToken ? {isGuest: false, isLogined: true, userId: JSON.parse(atob(queryString.parse(window.location.search).accessToken.split(".")[1])).uid} : user;
  const accessToken = queryString.parse(window.location.search).accessToken;
  const accessCode = queryString.parse(window.location.search).accessCode;
  const gochiKidGroupId = queryString.parse(window.location.search).gochiKidGroupId;
  const shopIdGochiKidQr = queryString.parse(window.location.search).shopIdQr;
  const checkTokenExpired = accessToken ? 1 : null;

  if (!(userInfo && userInfo.userId)) {
    window.location.href = addParamUrl("/login", [isSiteParam]);
  }

  const NO_IMAGE_PROFILE = require("../../../../assets/img/icons/profile_blank.png");
  const NO_IMAGE_MENU = require("../../../../assets/img/icons/square_blank.png");

  const [loadingUserDetail, setLoadingUserDetail] = useState(true);
  const [loadingTopGochi, setLoadingTopGochi] = useState(false);
  const [loadingWishList, setLoadingWishList] = useState(false);
  const [loadingFavList, setLoadingFavList] = useState(false);
  const [loadingTicket, setLoadingTicket] = useState(false);
  const [loadingPaymentInfo, setLoadingPaymentInfo] = useState(false);
  const [loadingReceipt, setLoadingReceipt] = useState(false);
  const [loadingGochiTome, setLoadingGochiTome] = useState(false);
  const [loadingGochiCharge, setLoadingGochiCharge] = useState(false);
  const [loadingGochiChargeDetail, setLoadingGochiChargeDetail] = useState(false);
  const [loadingHistoryCharge, setLoadingHistoryCharge] = useState(false);
  const [loadingHistoryUsedGochiCharge, setLoadingHistoryUsedGochiCharge] = useState(false);
  const [loadingGochiSubscription, setLoadingGochiSubscription] = useState(false);
  const [loadingFollow, setLoadingFollow] = useState(false);
  const [loadingDetailGochi, setLoadingDetailGochi] = useState(false);
  const [followCount, setFollowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingSendMessageGochiKid, setLoadingSendMessageGochiKid] = useState(false);
  const [loadingGochiKidSelectShop, setLoadingGochiKidSelectShop] = useState(false);
  const [loadingGochiKidSelectMenu, setLoadingGochiKidSelectMenu] = useState(false);
  const [loadingSendMessageGochiKidSuccess, setloadingSendMessageGochiKidSuccess] = useState(false);
  const [loadingRegisterUserKidGochiKid, setloadingRegisterUserKidGochiKid] = useState(false);
  const [loadingRegisterUserKidGochiKidSuccess, setloadingRegisterUserKidGochiKidSuccess] = useState(false);
  const [loadingGochiKidUsed, setLoadingGochiKidUsed] = useState(false);
  const [loadingGochiKidUsedDetail, setLoadingGochiKidUsedDetail] = useState(false);
  const [loadingGochiKidSupport, setLoadingGochiKidSupport] = useState(false);
  const [loadingGochiKidSupportContinue, setLoadingGochiKidSupportContinue] = useState(false);
  const [loadingGochiKidSupportMessage, setLoadingGochiKidSupportMessage] = useState(false);
  const [loadingCancelSubscriptionGochiKid, setLoadingCancelSubscriptionGochiKid] = useState(false);
  const [loadingCancelSubscriptionGochiKidSuccess, setLoadingCancelSubscriptionGochiKidSuccess] = useState(false);
  const [loadingListGroupSpecialGochiKid, setLoadingListGroupSpecialGochiKid] = useState(false);

  const userDetails = useUserDetails(
    userInfo && userInfo.userId ? userInfo.userId : props.userInfo.userId,
    userInfo && userInfo.userId ? userInfo.userId : props.userInfo.userId,
    (data) => {
      setLoadingUserDetail(false);
      setFollowCount(data.followCount);
    },
    null,
    accessToken,
    checkTokenExpired
  );
  const listPrefectures = useSelector((state) => state.prefectures);
  const listCities = useSelector((state) => state.cities);

  const [enableMyPage, setEnableMyPage] = useState(false);
  const [enableTabFollow, setEnableTabFollow] = useState(false);
  const [enableTabTicket, setEnableTabTicket] = useState(false);
  const [enableTabPaymentInfo, setEnableTabPaymentInfo] = useState(false);
  const [enableTabHistory, setEnableTabHistory] = useState(false);
  const [enableTabTome, setEnableTabTome] = useState(false);
  const [enableTabGochiSubscription, setEnableTabGochiSubscription] = useState(false);
  const [enableTabGochiCharge, setEnableTabGochiCharge] = useState(false);
  const [enableTabGochiChargeDetail, setEnableTabGochiChargeDetail] = useState(false);
  const [enableTabGochiKidUsed, setEnableTabGochiKidUsed] = useState(false);
  const [enableTabGochiKidSelectShop, setEnableTabGochiKidSelectShop] = useState(false);
  const [enableTabGochiKidSelectMenu, setEnableTabGochiKidSelectMenu] = useState(false);
  const [enableTabGochiKidSupport, setEnableTabGochiKidSupport] = useState(false);
  const [enableTabGochiKidSupportContinue, setEnableTabGochiKidSupportContinue] = useState(false);
  const [enableTabGochiKidSupportMessage, setEnableTabGochiKidSupportMessage] = useState(false);
  const [seeMore, setSeeMore] = useState(true);
  const [flagCanOpenHowToModal, setCanFlagOpenHowToModal] = useState(null);

  const [topGochiYourSelf, setTopGochiYourSelf] = useState([]);
  const [wishList, setWishList] = useState([]);
  const [favoriteList, setFavoriteList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);

  const [keywordUserName, setKeywordUserName] = useState("");
  const [isFollowTabActive, setIsFollowTabActive] = useState(true);

  const LIMIT = 10;
  const [page, setPage] = useState(1);
  const [searchResults, setSearchResults] = useState([]);
  const [totalResult, setTotalResults] = useState(0);

  const [filterPurchaseHistory, setFilterPurchaseHistory] = useState(1);

  const [sortPastOrder, setSortPastOrder] = useState("lastAddTimeDesc");
  const [filterGochi, setFilterGochi] = useState("all");
  const [showPopupDetailGochi, setShowPopupDetailGochi] = useState(false);
  const [gochiData, setGochiData] = useState(null);
  const [isFromPurchaseHistory, setIsFromPurchaseHistory] = useState(false);

  const [gochiBillIds, setGochiBillIds] = useState([]);
  const [receiptAddress, setReceiptAddress] = useState("");
  const [showPopupEmail, setShowPopupEmail] = useState(false);
  const [showPopupSendMailSuccess, setShowPopupSendMailSuccess] =
    useState(false);
  const [tempFollowList, setTempFollowList] = useState({});
  const [showHowtoModal, setShowHowToModal] = useState(false);
  const [shopInfo, setShopInfo] = useState({});

  //Gochi charge
  const [showPopupHistoryChargeGochi, setShowPopupHistoryChargeGochi] = useState(false);
  const [pageHistoryChargeGochi, setPageHistoryChargeGochi] = useState(1);
  const [listHistoryChargeGochi, setListHistoryChargeGochi] = useState([]);
  const [totalHistoryChargeGochi, setTotalHistoryChargeGochi] = useState(0);
  const LIMIT_HISTORY_CHARGE_GOCHI = 5;
  const [showPopupHistoryUsedGochiCharge, setShowPopupHistoryUsedGochiCharge] = useState(false);
  const [pageHistoryUsedGochiCharge, setPageHistoryUsedGochiCharge] = useState(1);
  const [listHistoryUsedGochiCharge, setListHistoryUsedGochiCharge] = useState([]);
  const [totalHistoryUsedGochiCharge, setTotalHistoryUsedGochiCharge] = useState(0);
  const LIMIT_HISTORY_USED_GOCHI_CHARGE = 5;
  
  const [currentGochiBillId, setCurrentGochiBillId] = useState(null);
  const [showPopupGochiKidUsedDetail, setShowPopupGochiKidUsedDetail] = useState(false);
  const [showPopupSendMessageGochiKid, setShowPopupSendMessageGochiKid] = useState(false);
  const [showPopupSendMessageGochiKidSuccess, setShowPopupSendMessageGochiKidSuccess] = useState(false);
  const [showPopupRegisterUserKidGochiKid, setShowPopupRegisterUserKidGochiKid] = useState(false);
  const [showPopupRegisterUserKidGochiKidSuccess, setShowPopupRegisterUserKidGochiKidSuccess] = useState(false);
  const [showPopupCancelSubscriptionGochiKid, setShowPopupCancelSubscriptionGochiKid] = useState(false);
  const [showPopupCancelSubscriptionGochiKidSuccess, setShowPopupCancelSubscriptionGochiKidSuccess] = useState(false);
  const [itemGochiKidUsed, setItemGochiKidUsed] = useState({});
  const [userKidData, setUserKidData] = useState({});
  const [showSelectUserKidModal, setShowSelectUserKidModal] = useState(false);
  const [listUserKids, setListUserKids] = useState([]);
  const [choiceShopId, setChoiceShopId] = useState(null);
  const [choiceMenuId, setChoiceMenuId] = useState(null);
  const [priceChoiceMenu, setPriceChoiceMenu] = useState(null);
  const [gochiKidUnlimitShopChoice, setGochiKidUnlimitShopChoice] = useState(0);
  const [gochiKidReceiptShopChoice, setGochiKidReceiptShopChoice] = useState(0);
  const [gochiKidUseCodeShopChoice, setGochiKidUseCodeShopChoice] = useState(0);
  const [choiceUserKidId, setChoiceUserKidId] = useState(null);
  const [choiceUserKid, setChoiceUserKid] = useState(null);
  const [choiceUserKidIds, setChoiceUserKidIds] = useState([]);
  const [choiceUserKids, setChoiceUserKids] = useState([]);
  const [numUserKidUse, setNumUserKidUse] = useState(null);
  const [numberUserKidInit, setNumberUserKidInit] = useState(0);
  const [isGochiKidUsed, setIsGochiKidUsed] = useState(false);
  const [itemSubscriptionGochiKid, setItemSubscriptionGochiKid] = useState({});
  const [userKidStaticData, setUserKidStaticData] = useState({});
  const [showPopupErrorGochiKidUse, setShowPopupErrorGochiKidUse] = useState(false);
  const [errorMessageGochiKidUse, setErrorMessageGochiKidUse] = useState('');
  const [showMoreText, setShowMoreText] = useState(false);
  const [shopName, setShopName] = useState('');
  const [isRegisterUserKidError, setIsRegisterUserKidError] = useState(false);
  const [messageRegisterUserKidError, setMessageRegisterUserKidError] = useState('');
  const [hideButtonOnHeader, setHideButtonOnHeader] = useState(false);
  const [thanksMessageIsNullGochiKid, setThanksMessageIsNullGochiKid] = useState(true);
  const [retryUploadReceiptGochiKid, setRetryUploadReceiptGochiKid] = useState(0);
  const [isReceiptApproveRequired, setIsReceiptApproveRequired ] = useState(true);
  const [surveyIsNullGochiKid, setSurveyIsNullGochiKid] = useState(false);
  const [gochiKidPriceUseAmount, setGochiKidPriceUseAmount] = useState(null);
  const [showInputGochiKidPriceModal, setShowInputGochiKidPriceModal] = useState(false);
  const [showPolicyGochiKidUseModal, setShowPolicyGochiKidUseModal] = useState(false);
  const [showPolicyGochiKidModal, setShowPolicyGochiKidModal] = useState(false);
  const [showPolicyGochiKidCampaignModal, setShowPolicyGochiKidCampaignModal] = useState(false);
  const [showExplainInputGochiKidModal, setShowExplainInputGochiKidModal] = useState(false);
  const [showAcceptPolicyGochiKidReceiptModal, setShowAcceptPolicyGochiKidReceiptModal] = useState(false);

  const [showListGroupSpecialGochiKidModal, setShowListGroupSpecialGochiKidModal] = useState(false);
  const [pageListGroupSpecialGochiKid, setPageListGroupSpecialGochiKid] = useState(1);
  const [pageListGroupSpecialGochiKidAll, setPageListGroupSpecialGochiKidAll] = useState(1);
  const [listGroupSpecialGochiKid, setListGroupSpecialGochiKid] = useState([]);
  const [listGroupSpecialGochiKidAll, setListGroupSpecialGochiKidAll] = useState([]);
  const [totalGroupSpecialGochiKid, setTotalGroupSpecialGochiKid] = useState(0);
  const LIMIT_LIST_GROUP_SPECIAL_GOCHI_KID = 3; // limit to pageable by front-end
  const LIMIT_LIST_GROUP_SPECIAL_GOCHI_KID_ALL = 99; // limit to get list group from api
  const [numberSlotCanUseAll, setNumberSlotCanUseAll] = useState(0);
  const [gochiKidGroupIdChoose, setGochiKidGroupIdChoose] = useState(0);

  const [numberTabThankMessageDonator, setNumberTabThankMessageDonator] = useState(0);
  const [pageMessageDonatorGochiKid, setPageMessageDonatorGochiKid] = useState(1);
  const [totalMessageDonatorGochiKid, setTotalMessageDonatorGochiKid] = useState(0);
  const [listMessageDonatorGochiKid, setListMessageDonatorGochiKid] = useState([]);
  const [pageReportShopGochiKid, setPageReportShopGochiKid] = useState(1);
  const [totalReportShopGochiKid, setTotalReportShopGochiKid] = useState(0);
  const [listReportShopGochiKid, setListReportShopGochiKid] = useState([]);
  const [listReceiptImageGochiKid, setListReceiptImageGochiKid] = useState([]);
  const [indexGochiKidUsedChoose, setIndexGochiKidUsedChoose] = useState(null);

  function isSameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  async function checkOpenHowToModal() {
    const lastVisited = localStorage.getItem('lastVisited');
    const today = new Date();
    const lastVisitedDate = new Date(lastVisited);
    if ((!lastVisited || !isSameDay(today, lastVisitedDate)) && !mogugori && !accessToken && flagCanOpenHowToModal) {
      setShowHowToModal(true);
    }
    localStorage.setItem('lastVisited', today.toString());
  }

  function handleUpdate(e) {
    var data = [...searchResults];
    for (let i = 0; i < data.length; i++) {
      if (data[i].menuId && data[i].menuId === e.menuId) {
        data[i].menu.isFavourite = e.tempFavorite;
        data[i].menu.isWishList = e.tempWishList; 
      }
    }
    setSearchResults(data);
  }

  function handleUpdateWishListMenu(e) {
    var data = [...wishList];
    let tmp = e.menuData;

    var result = data.find(({ menuId }) => menuId === e.menuId);
    if (result) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].menuId && data[i].menuId === e.menuId) {
          data[i].isFavourite = e.tempFavorite;
          data[i].isWishList = e.tempWishList;
          break;
        }
      }
      data.sort(function (x, y) {
        return x.menuId === e.menuId ? -1 : y.menuId === e.menuId ? 1 : 0;
      });
    } else {
      tmp.isWishList = true;
      data.unshift(tmp);
    }
    setWishList(data);
  }

  function handleUpdateFavoriteMenu(e) {
    var dataFav = [...favoriteList];
    let tmp = e.menuData;

    var resultFav = dataFav.find(({ menuId }) => menuId === e.menuId);
    if (resultFav) {
      for (let i = 0; i < dataFav.length; i++) {
        if (dataFav[i].menuId && dataFav[i].menuId === e.menuId) {
          dataFav[i].isFavourite = e.tempFavorite;
          dataFav[i].isWishList = e.tempWishList;
          break;
        }
      }
      dataFav.sort(function (x, y) {
        return x.menuId === e.menuId ? -1 : y.menuId === e.menuId ? 1 : 0;
      });
    } else {
      tmp.isFavourite = true;
      dataFav.unshift(tmp);
    }
    setFavoriteList(dataFav);
  }

  function handleUpdateWishListShop(e) {
    var data = [...wishList];
    var dataFav = [...favoriteList];
    let tmp = e.shopData;

    var result = data.find((item) => item.shopId === e.shopId && !item.menuId);
    var resultFav = dataFav.find(
      (item) => item.shopId === e.shopId && !item.menuId
    );
    if (result) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].shopId && data[i].shopId === e.shopId && !data[i].menuId) {
          data[i].isFavourite = e.tempFavorite;
          data[i].isWishList = e.tempWishList;
          break;
        }
      }
      data.sort(function (x, y) {
        return x.shopId === e.shopId && !x.menuId
          ? -1
          : y.shopId === e.shopId && !y.menuId
          ? 1
          : 0;
      });
    } else {
      tmp.isWishList = true;
      data.unshift(tmp);
    }
    if (resultFav) {
      for (let i = 0; i < dataFav.length; i++) {
        if (
          dataFav[i].shopId &&
          dataFav[i].shopId === e.shopId &&
          !dataFav[i].menuId
        ) {
          dataFav[i].isFavourite = e.tempFavorite;
          dataFav[i].isWishList = e.tempWishList;
          break;
        }
      }
      // dataFav.sort(function(x,y){ return (x.shopId === e.shopId && !x.menuId) ? -1 : (y.shopId === e.shopId && !y.menuId) ? 1 : 0; });
    } else {
    }
    setWishList(data);
    setFavoriteList(dataFav);
  }

  function handleUpdateFavoriteShop(e) {
    var data = [...wishList];
    var dataFav = [...favoriteList];
    let tmp = e.shopData;

    var result = data.find((item) => item.shopId === e.shopId && !item.menuId);
    var resultFav = dataFav.find(
      (item) => item.shopId === e.shopId && !item.menuId
    );
    if (resultFav) {
      for (let i = 0; i < dataFav.length; i++) {
        if (
          dataFav[i].shopId &&
          dataFav[i].shopId === e.shopId &&
          !dataFav[i].menuId
        ) {
          dataFav[i].isFavourite = e.tempFavorite;
          dataFav[i].isWishList = e.tempWishList;
          break;
        }
      }
      dataFav.sort(function (x, y) {
        return x.shopId === e.shopId && !x.menuId
          ? -1
          : y.shopId === e.shopId && !y.menuId
          ? 1
          : 0;
      });
    } else {
      tmp.isFavourite = true;
      dataFav.unshift(tmp);
    }
    if (result) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].shopId && data[i].shopId === e.shopId && !data[i].menuId) {
          data[i].isFavourite = e.tempFavorite;
          data[i].isWishList = e.tempWishList;
          break;
        }
      }
    }
    setWishList(data);
    setFavoriteList(dataFav);
  }

  function getPrefectureName(prefectures, id) {
    const result = prefectures.find(({ prefectureId }) => prefectureId === id);
    return result && result.prefectureName ? result.prefectureName : "";
  }

  function getCityName(cities, id) {
    const result = cities.find(({ cityId }) => cityId === id);
    return result && result.cityName ? result.cityName : "";
  }

  async function getTopGochis() {
    var filter = "usable";
    try {
      setLoadingTopGochi(true);
      var list = [];
      let data = await getListGochis(
        props.userInfo.userId,
        "lastAddTimeDesc",
        1,
        3,
        filter
      );
      if (data.count > 0) {
        data.list.map((item) => {
          return list.push(item);
        });

        setTopGochiYourSelf(list);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingTopGochi(false);
    }
  }

  async function getDetailGochi(gochiBillId) {
    try {
      const { data } = await GochiService.getGochi({
        gochiBillId,
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async function getDetailGochiCharge(menuId, gochiUserId, gochiBillId) {
    try {
      const { data, status } = await MenuService.getMenuLimitTotal(
        menuId,
        gochiUserId,
        gochiBillId
      );
      if (status !== 200) {
        alert("エラーが発生しました。");
      }
      return data;
    } catch (error) {
      console.log(error);
      alert("エラーが発生しました。");
    }
  }

  async function handleShowDetailGochi(gochiBillId, fromScreen) {
    if (fromScreen === "purchaseHistory") {
      setIsFromPurchaseHistory(true);
    } else {
      setIsFromPurchaseHistory(false);
    }
    setShowPopupDetailGochi(true);
    document.getElementsByTagName("html")[0].classList.add("active-modal");
    try {
      setLoadingDetailGochi(true);
      let data = await getDetailGochi(gochiBillId);
      if (Object.entries(data).length > 0) {
        setGochiData(data);
      } else {
        setGochiData(null);
        alert("エラーが発生しました。");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingDetailGochi(false);
    }
  }

  function handleClosePopupDetailGochi() {
    setShowPopupDetailGochi(false);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }

  async function fetchDataGochis(page) {
    try {
      setLoadingGochiTome(true);
      let data = await getListGochis(
        props.userInfo.userId,
        "lastAddTimeDesc",
        page,
        LIMIT,
        filterGochi
      );
      var list = [];
      var count = 0;
      if (data.count > 0) {
        list = data.list;
        count = data.count;
      }
      setSearchResults(list);
      setTotalResults(count);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingGochiTome(false);
    }
  }

  async function fetchDataGochiCharge(page) {
    try {
      setLoadingGochiTome(true);
      let data = await getListGochiCharges(
        props.userInfo.userId,
        page,
        LIMIT
      );
      var list = [];
      var count = 0;
      if (data.count > 0) {
        list = data.list;
        count = data.count;
      }
      setSearchResults(list);
      setTotalResults(count);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingGochiTome(false);
    }
  }

  async function fetchDataGochiChargeDetail(menuId, gochiUserId, gochiBillId) {
    try {
      setLoadingGochiChargeDetail(true);
      let data = await getDetailGochiCharge(menuId, gochiUserId, gochiBillId);
      let feeRate = await getFeeRate();
      if (Object.entries(data).length > 0) {
        let newData = {...data, feeRate: feeRate};
        setGochiData(newData);
      } else {
        setGochiData(null);
        alert("エラーが発生しました。");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingGochiChargeDetail(false);
    }
  }

  async function onFilterListGochis(e) {
    setFilterGochi(e.target.value);
    var filter = e.target.value;
    setPage(1);
    try {
      setLoadingGochiTome(true);
      var list = [];
      var count = 0;
      let data = await getListGochis(
        props.userInfo.userId,
        "lastAddTimeDesc",
        1,
        LIMIT,
        filter
      );
      if (data.count > 0) {
        list = data.list;
        count = data.count;
      }
      setSearchResults(list);
      setTotalResults(count);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingGochiTome(false);
    }
  }

  async function getListGochis(gochiUserId, sort, page, limit, filter) {
    const gochiUserType = ["YOURSELF", "ANYONE", "USER", "NONUSER"];
    const couponCountGte = 1;
    try {
      const { data } = await GiftService.getGochiToMe({
        gochiUserType,
        couponCountGte,
        gochiUserId,
        sort,
        page,
        limit,
        filter,
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async function getListGochiCharges(gochiUserId, page, limit) {
    try {
      const { data } = await GiftService.getGochiChargeHistory({
        gochiUserId,
        page,
        limit,
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchDataPurchaseHistories(page) {
    try {
      setLoadingTicket(true);
      let data = await getListPurchaseHistories(
        page,
        LIMIT,
        filterPurchaseHistory
      );
      var list = [];
      var count = 0;
      if (data.total > 0) {
        list = data.list;
        count = data.total;
      }
      setSearchResults(list);
      setTotalResults(count);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingTicket(false);
    }
  }

  async function onFilterListPurchaseHistories(e) {
    setFilterPurchaseHistory(e.target.value);
    var filter = e.target.value;
    setPage(1);
    try {
      setLoadingTicket(true);
      var list = [];
      var count = 0;
      let data = await getListPurchaseHistories(1, LIMIT, filter);
      if (data.total > 0) {
        list = data.list;
        count = data.total;
      }
      setSearchResults(list);
      setTotalResults(count);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingTicket(false);
    }
  }

  async function getListPurchaseHistories(page, perPage, filterMode) {
    try {
      const { data } = await GiftService.getPurchaseHistory({
        page,
        perPage,
        filterMode,
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchDataGochiSubscription(page) {
    try {
      setLoadingGochiSubscription(true);
      let data = await getListGochiSubscription(
        page,
        LIMIT
      );
      var list = [];
      var count = 0;
      if (data.count > 0) {
        list = data.list;
        count = data.count;
      }
      setSearchResults(list);
      setTotalResults(count);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingGochiSubscription(false);
    }
  }

  async function getListGochiSubscription(page, perPage) {
    try {
      const { data } = await GiftService.getGochiSubscription({
        page,
        perPage
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  function handleChangePage(toPage) {
    setPage(toPage);
    if (enableTabTome === true) {
      fetchDataGochis(toPage);
    } else if (enableTabHistory === true) {
      fetchDataPastOrders(toPage);
    } else if (enableTabFollow === true) {
      fetchDataListFollow(toPage, isFollowTabActive);
    } else if (enableTabTicket === true) {
      fetchDataPurchaseHistories(toPage);
    } else if (enableTabGochiSubscription === true) {
      fetchDataGochiSubscription(toPage);
    } else if (enableTabGochiCharge === true) {
      fetchDataGochiCharge(toPage);
    } else if (enableTabGochiKidUsed === true) {
      getUserKidGochiKid(toPage, 'latest');
    } else if (enableTabGochiKidSupport === true) {
      getGochiKidSupport(toPage);
    } else if (enableTabGochiKidSupportContinue) {
      getGochiKidSupportContinue(toPage, 'created_at');
    } else if (enableTabGochiKidSupportMessage === true) {
      if (numberTabThankMessageDonator === 0) {
        setPageMessageDonatorGochiKid(toPage);
        getGochiKidSupportMessage(toPage, 'created_at');
      } else if (numberTabThankMessageDonator === 1) {
        setPageReportShopGochiKid(toPage);
        getGochiKidReportShopMessage(toPage, 'created_at');
      }
    }

    var element = document.getElementById("scrollToElementId");
    element.scrollIntoView();
  }

  async function fetchDataPastOrders(page) {
    try {
      setLoadingReceipt(true);
      var list = [];
      var count = 0;
      let data = await getPastOrders(
        props.userInfo.userId,
        sortPastOrder,
        page,
        LIMIT
      );
      if (data.count > 0) {
        list = data.list;
        count = data.count;
      }
      setSearchResults(list);
      setTotalResults(count);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingReceipt(false);
    }
  }

  async function onSortListPastOrders(e) {
    setSortPastOrder(e.target.value);
    var sort = e.target.value;
    setPage(1);
    try {
      setLoadingReceipt(true);
      var list = [];
      var count = 0;
      let data = await getPastOrders(props.userInfo.userId, sort, 1, LIMIT);
      if (data.count > 0) {
        list = data.list;
        count = data.count;
      }
      setSearchResults(list);
      setTotalResults(count);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingReceipt(false);
    }
  }

  async function getPastOrders(gochiRegisterId, sort, page, limit) {
    try {
      const { data } = await GochiService.getPastOrders({
        gochiRegisterId,
        sort,
        page,
        limit,
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async function getFeeRate() {
    try {
      const { data } = await MenuService.getFeeRate();
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  function handleInputUsername(keyword) {
    const value = keyword.trim();
    setKeywordUserName(value);
  }

  async function onSearchListFollow(isFollowTabActive) {
    setPage(1);
    fetchDataListFollow(1, isFollowTabActive);
  }

  async function handleChangeListFollowTab(isFollowTabActive) {
    setPage(1);
    setIsFollowTabActive(isFollowTabActive);
    fetchDataListFollow(1, isFollowTabActive);
  }

  async function fetchDataListFollow(page, isFollowTabActive) {
    var sort = "displayName";
    var loginUserId = props.userInfo.userId;
    var followerUserId = keywordUserName ? undefined : props.userInfo.userId;
    var followUserId = keywordUserName ? undefined : props.userInfo.userId;
    try {
      setLoadingFollow(true);
      var list = [];
      var count = 0;
      var data = [];
      if (isFollowTabActive === true) {
        data = await getListFollow(
          loginUserId,
          followerUserId,
          null,
          sort,
          keywordUserName,
          page,
          LIMIT
        );
      } else {
        data = await getListFollow(
          loginUserId,
          null,
          followUserId,
          sort,
          keywordUserName,
          page,
          LIMIT
        );
      }
      setTempFollowList({});
      if (data.count > 0) {
        list = data.list;
        count = data.count;
      }
      setSearchResults(list);
      setTotalResults(count);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingFollow(false);
    }
  }

  async function getListFollow(
    loginUserId,
    followerUserId,
    followUserId,
    sort,
    keyword,
    page,
    limit
  ) {
    try {
      const { data } = await UserService.getListFollow({
        loginUserId,
        followerUserId,
        followUserId,
        sort,
        keyword,
        page,
        limit,
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  function getUserKidCanUses(gochiKidGroupId) {
    setLoading(true);
    if (gochiKidGroupId) {
      GiftService.getUserKidCanUsesGroupSpecial(gochiKidGroupId, accessToken, checkTokenExpired)
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            setListUserKids(response.data.list);
            let arrNum = 0;
            response.data.list.forEach(item => {
              if (item.canUse) {
                arrNum++;
              }
            });
            setNumUserKidUse(arrNum);
            if (arrNum === 1 && response.data.list.length === 1) {
              setChoiceUserKidId(response.data.list.filter(e => e.canUse)[0].userKidId);
              setChoiceUserKidIds([response.data.list.filter(e => e.canUse)[0].userKidId]);
              setChoiceUserKids([response.data.list.filter(e => e.canUse)[0]]);
              setChoiceUserKid(response.data.list.filter(e => e.canUse)[0]);
            } else if (arrNum === 1 && response.data.list.length !== 1) {
              setNumUserKidUse(response.data.list.length);
            }
          } else if (response.status === 404) {
            //history.push("/404");
          }
        })
        .catch((e) => {
          setLoading(false);
          console.error("GET USER KIDS CAN USE ERROR: ", e);
        });
    } else {
      GiftService.getUserKidCanUses(accessToken, checkTokenExpired)
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            setListUserKids(response.data.list);
            let arrNum = 0;
            response.data.list.forEach(item => {
              if (item.canUse) {
                arrNum++;
              }
            });
            setNumUserKidUse(arrNum);
            if (arrNum === 1 && response.data.list.length === 1) {
              setChoiceUserKidId(response.data.list.filter(e => e.canUse)[0].userKidId);
              setChoiceUserKidIds([response.data.list.filter(e => e.canUse)[0].userKidId]);
              setChoiceUserKids([response.data.list.filter(e => e.canUse)[0]]);
              setChoiceUserKid(response.data.list.filter(e => e.canUse)[0]);
            } else if (arrNum === 1 && response.data.list.length !== 1) {
              setNumUserKidUse(response.data.list.length);
            }
          } else if (response.status === 404) {
            //history.push("/404");
          }
        })
        .catch((e) => {
          setLoading(false);
          console.error("GET USER KIDS CAN USE ERROR: ", e);
        });
    }
  }

  function handleInputChange(e) {
    var value = e.target.value;
    let listIds = gochiBillIds;
    if (e.target.checked) {
      listIds.push(parseInt(value));
    } else {
      listIds = listIds.filter((item) => item !== parseInt(value));
    }
    setGochiBillIds(listIds);
  }

  function handleShowPopupEmail() {
    if (gochiBillIds.length > 0) {
      setShowPopupEmail(true);
      document.getElementsByTagName("html")[0].classList.add("active-modal");
    } else {
      alert("領収書を発行したいごちを選択ください。");
    }
  }

  function handleClosePopupEmail() {
    setShowPopupEmail(false);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }

  function handleClosePopupSendMailSuccess() {
    setShowPopupSendMailSuccess(false);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }

  async function handleSendMail() {
    // var pattern = new RegExp(
    //   /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    // );
    // if (pattern.test(receiptAddress))
    if (
      receiptAddress &&
      receiptAddress !== "" &&
      receiptAddress.length <= 100
    ) {
      var value = receiptAddress.trim();
      try {
        const data = await GochiService.sendMailGochiBill(value, gochiBillIds);
        if (data.status === 200) {
          setShowPopupEmail(false);
          setShowPopupSendMailSuccess(true);
        } else {
          alert("エラーが発生しました。");
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      // Invalid value
      alert("100文字以内ご入力ください。");
    }
  }

  async function getWishList(userId) {
    setLoadingWishList(true);
    GiftService.getUserWishList(userId, 1, 10000).then((response) => {
      if (response.status === 200) {
        setWishList(response.data.list);
      } else {
        setWishList([]);
      }
      setLoadingWishList(false);
    });
  }

  async function getFavoriteList(userId) {
    setLoadingFavList(true);
    GiftService.getUserFavoriteList(userId, 1, 10000).then((response) => {
      if (response.status === 200) {
        setFavoriteList(response.data.list);
      } else {
        setFavoriteList([]);
      }
      setLoadingFavList(false);
    });
  }

  async function getListPayments(userId) {
    setLoadingPaymentInfo(true);
    PaymentService.getPaymentList(userId).then((response) => {
      if (response.status === 200) {
        setPaymentList(response.data.list);
      } else {
        setPaymentList([]);
      }
      setLoadingPaymentInfo(false);
    });
  }

  async function getUserKidData(userId) {
    await GiftService.getUserKidData(
      userId,
      accessToken,
      checkTokenExpired
    ).then((response) => {
      if (response.status === 200) {
        setUserKidData(response.data);
      } else {
        setUserKidData({});
        alert("エラーが発生しました。");
      }
    });
  }

  async function getUserKidGochiKid(page, sortBy) {
    setLoadingGochiKidUsed(true);
    try {
      let data = await getListGochiKidUsed(
        page,
        LIMIT,
        sortBy
      );
      var list = [];
      var count = 0;
      if (data.count > 0) {
        list = data.list;
        count = data.count;
      }
      setSearchResults(list);
      setTotalResults(count);
      setThanksMessageIsNullGochiKid(data.thanksMessageIsNull);
      setRetryUploadReceiptGochiKid(data.gochiKidRetryUploadReceipt);
      setIsReceiptApproveRequired(data.receiptApproveRequired);
      setSurveyIsNullGochiKid(data.surveyIsNull);

      if (list && list.length > 0) {
        let listReceiptImage = [];

        list.forEach(async (item, index) => {
          if (item.receipt) {
            listReceiptImage.push({
              image: item.receipt?.receiptImage,
              imageBase64: null,
            });

            convertImageToBase64(item.receipt?.receiptImage, function(dataUrl) {
              listReceiptImage[index].imageBase64 = dataUrl
            });
          } else {
            listReceiptImage.push(null);
          }
        });

        setListReceiptImageGochiKid(listReceiptImage);
      } else {
        setListReceiptImageGochiKid([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingGochiKidUsed(false);
    }
  }

  async function getListGochiKidUsed(page, limit, sortBy) {
    try {
      const { data } = await GiftService.getUserKidGochiKidData({
        page,
        limit,
        sortBy: sortBy,
      }, accessToken, checkTokenExpired);
      return data;
    } catch (error) {
      console.log(error);
    }
  }


  async function getGochiKidSupport(page, sortBy) {
    setLoadingGochiKidSupport(true);
    try {
      let data = await getListGochiKidSupport(
        page,
        LIMIT,
        sortBy
      );
      var list = [];
      var count = 0;
      if (data.count > 0) {
        list = data.list;
        count = data.count;
      }
      setSearchResults(list);
      setTotalResults(count);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingGochiKidSupport(false);
    }
  }

  async function getListGochiKidSupport(page, limit, sortBy) {
    try {
      const { data } = await GiftService.getGochiKidSupportData({
        page,
        limit,
        sortBy: sortBy,
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async function getGochiKidSupportContinue(page, sortBy) {
    setLoadingGochiKidSupportContinue(true);
    try {
      let data = await getListGochiKidSupportContinue(
        page,
        LIMIT,
        sortBy
      );
      var list = [];
      var count = 0;
      if (data.count > 0) {
        list = data.list;
        count = data.count;
      }
      setSearchResults(list);
      setTotalResults(count);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingGochiKidSupportContinue(false);
    }
  }

  async function getListGochiKidSupportContinue(page, limit, sortBy) {
    try {
      const { data } = await GiftService.getGochiKidSubscriptionData({
        page,
        limit,
        sortBy: sortBy,
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async function getGochiKidSupportMessage(page, sortBy) {
    setLoading(true);
    try {
      let data = await getListGochiKidSupportMessage(
        page,
        LIMIT,
        sortBy
      );
      var list = [];
      var count = 0;
      if (data.count > 0) {
        list = data.list;
        count = data.count;
      }
      setListMessageDonatorGochiKid(list);
      setTotalMessageDonatorGochiKid(count);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  async function getListGochiKidSupportMessage(page, limit, sortBy) {
    try {
      const { data } = await GiftService.getGochiKidThankMessageData({
        page,
        limit,
        sortBy: sortBy,
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async function getGochiKidReportShopMessage(page, sortBy) {
    setLoading(true);
    try {
      let data = await getListGochiKidReportShopMessage(
        page,
        LIMIT,
        sortBy
      );
      var list = [];
      var count = 0;
      if (data.count > 0) {
        list = data.list;
        count = data.count;
      }
      setListReportShopGochiKid(list);
      setTotalReportShopGochiKid(count);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  async function getListGochiKidReportShopMessage(page, limit, sortBy) {
    try {
      const { data } = await GiftService.getGochiKidReportShopMessageData({
        page,
        limit,
        sortBy: sortBy,
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async function getGochiKidDonateStatic(userId) {
    GiftService.getGochiKidDonateStaticData(
      userId
    ).then((response) => {
      if (response.status === 200) {
        setUserKidStaticData(response.data);
      } else {
        setUserKidStaticData({});
      }
    });
  }

  async function getShopDetails(shopId, userIdForGochiKid, gochiKidGroupId) {
    try {
      const data = await GiftService.getShopDetails(shopId, 1, userIdForGochiKid, gochiKidGroupId);
      if (data && data.data && data.data.status && data.data.status !== "AVAILABLE") {
        window.location.href = "/404";
      }
      if (data && data.data && data.data.shopName) {
        setShopName(data.data.shopName);
      }
    return data;
    } catch (error) {
      console.log(error);
    }
  }


  function handleEnableFollowTab() {
    setEnableMyPage(false);
    setEnableTabFollow(true);
    setEnableTabTicket(false);
    setEnableTabPaymentInfo(false);
    setEnableTabHistory(false);
    setEnableTabTome(false);
    setEnableTabGochiSubscription(false);
    setEnableTabGochiCharge(false);
    setEnableTabGochiKidUsed(false);
    setEnableTabGochiKidSelectShop(false);
    setEnableTabGochiKidSelectMenu(false);
  }

  function displayBirthDay(birthdayStr) {
    let birth = new Date(birthdayStr);
    let isCheckNotShowBirthYear = false;

    birth.setHours(0, 0, 0, 0);
    if (birth.getFullYear() < 1900) {
      birth.setFullYear(birth.getFullYear() + 900);
      isCheckNotShowBirthYear = true;
    }
    let bYear = birth.getFullYear();
    let bMonth = birth.getMonth() + 1;
    let bDay = birth.getDate();
    if (bMonth < 10) {
      bMonth = `0${bMonth}`;
    }
    if (bDay < 10) {
      bDay = `0${bDay}`;
    }

    return isCheckNotShowBirthYear
      ? `${bMonth}月${bDay}日`
      : `${bYear}年${bMonth}月${bDay}日`;
  }

  function handleCloseModalGroup() {
    setShowPopupDetailGochi(false);
    setShowPopupEmail(false);
    setShowPopupSendMailSuccess(false);
    setShowPopupHistoryChargeGochi(false);
    resetValueHistoryChargeGochi();
    setShowPopupHistoryUsedGochiCharge(false);
    resetValueHistoryUsedChargeGochi();
    setShowPopupSendMessageGochiKid(false);
    setShowPopupSendMessageGochiKidSuccess(false);
    setShowPopupRegisterUserKidGochiKid(false);
    setShowPopupRegisterUserKidGochiKidSuccess(false);
    setShowPopupCancelSubscriptionGochiKid(false);
    setShowPopupCancelSubscriptionGochiKidSuccess(false);
    setShowInputGochiKidPriceModal(false);
    setShowSelectUserKidModal(false);
    setShowPopupGochiKidUsedDetail(false);
    setShowPolicyGochiKidUseModal(false);
    setShowPolicyGochiKidModal(false);
    setShowPolicyGochiKidCampaignModal(false);
    setShowAcceptPolicyGochiKidReceiptModal(false);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }

  const windowSize = useWindowSize();
  const innerWidth = windowSize[0];

  useEffect(() => {
    sessionStorage.removeItem("gochiKidUsed");
    if (isSite) {
      document.body.className = "mypage " + isSite;
      window.scrollTo(0, 0);
    } else {
      document.body.className = "mypage";
      window.scrollTo(0, 0);
    }
    var isFollowTab =
      location.data && location.data.isFollowTabActive
        ? location.data.isFollowTabActive
        : true;

    var urlPath = history.location.pathname;
    var gochiChargeId = history.location.pathname.split('/').length > 0 ? history.location.pathname.split('/')[3] : ''; 
    let urlhost = window.location.search;
		let paramUrl = queryString.parse(urlhost);

    if (
      urlPath === "/mypage/purchase_histories" ||
      urlPath === "/mypage/purchase_histories/"
    ) {
      setCanFlagOpenHowToModal(true);
      setEnableTabTicket(true);
      setHideButtonOnHeader(false);
      fetchDataPurchaseHistories(page);
    } else if (
      urlPath === "/mypage/mygochi" ||
      urlPath === "/mypage/mygochi/"
    ) {
      setCanFlagOpenHowToModal(true);
      setEnableTabTome(true);
      setHideButtonOnHeader(false);
      fetchDataGochis(page);
    } else if (
      urlPath === "/mypage/mygochi_charge" ||
      urlPath === "/mypage/mygochi_charge/"
    ) {
      setCanFlagOpenHowToModal(false);
      setEnableTabGochiCharge(true);
      setHideButtonOnHeader(false);
      fetchDataGochiCharge(page);
    } else if (
      urlPath.match(new RegExp('mypage/mygochi_charge_detail/*', 'g')) && urlPath.match(new RegExp('mypage/mygochi_charge_detail/*', 'g'))[0] === 'mypage/mygochi_charge_detail/'
    ) {
      setCanFlagOpenHowToModal(false);
      setEnableTabGochiChargeDetail(true);
      setHideButtonOnHeader(false);
      fetchDataGochiChargeDetail(paramUrl.menuId, props.userInfo.userId, gochiChargeId);
    } else if (
      urlPath === "/mypage/kodomo_use" ||
      urlPath === "/mypage/kodomo_use/"
    ) {
      const userId = props.userInfo.userId || accessCode && Number(window.atob(accessCode).slice(0, -2));
      setCanFlagOpenHowToModal(false);
      setEnableTabGochiKidUsed(true);
      setHideButtonOnHeader(false);
      fetchDataKodomoUse(userId, page, 'latest');
      if (accessToken && accessCode) {
        window.scroll(0, 0);
        document.getElementsByTagName('html')[0].classList.add('hide-scroll-background-gochi-kid');
      }

      if (moment().isBefore(moment('2024/09/01', "YYYY/MM/DD")) && !(accessToken || accessCode)) {
        setShowPolicyGochiKidCampaignModal(true);
        document.getElementsByTagName("html")[0].classList.add("active-modal");
      }
    } else if (
      urlPath === "/mypage/kodomo_use/search_shop" ||
      urlPath === "/mypage/kodomo_use/search_shop/"
    ) {
      setCanFlagOpenHowToModal(false);
      setHideButtonOnHeader(false);
      setEnableTabGochiKidSelectShop(true);
    } else if (
      urlPath.match(new RegExp('mypage/kodomo_use/search_shop/*', 'g')) && urlPath.match(new RegExp('mypage/kodomo_use/search_shop/*', 'g'))[0] === 'mypage/kodomo_use/search_shop/'
    ) {
      setCanFlagOpenHowToModal(false);
      getUserKidCanUses(gochiKidGroupId);
      setEnableTabGochiKidSelectMenu(true);
      if (paramUrl.accessToken) {
        setHideButtonOnHeader(true);
      } else {
        setHideButtonOnHeader(false);
      }
    } else if (
      urlPath === "/mypage/kodomo_support" ||
      urlPath === "/mypage/kodomo_support/"
    ) {
      setCanFlagOpenHowToModal(false);
      setHideButtonOnHeader(false);
      setEnableTabGochiKidSupport(true);
      getGochiKidDonateStatic(props.userInfo.userId);
      getGochiKidSupport(page, 'created_at');
    } else if (
      urlPath === "/mypage/kodomo_support/continue" ||
      urlPath === "/mypage/kodomo_support/continue/"
    ) {
      setCanFlagOpenHowToModal(false);
      setHideButtonOnHeader(false);
      setEnableTabGochiKidSupportContinue(true);
      getGochiKidSupportContinue(1, 'created_at');
    } else if (
      urlPath === "/mypage/kodomo_support/message" ||
      urlPath === "/mypage/kodomo_support/message/"
    ) {
      setCanFlagOpenHowToModal(false);
      setHideButtonOnHeader(false);
      setEnableTabGochiKidSupportMessage(true);
      handleChangeTabMessageDonatorGochiKid(0);
    } else if (
      urlPath === "/mypage/payment_informations" ||
      urlPath === "/mypage/payment_informations/"
    ) {
      setCanFlagOpenHowToModal(true);
      setHideButtonOnHeader(false);
      setEnableTabPaymentInfo(true);
      getListPayments(props.userInfo.userId);
    } else if (
      urlPath === "/mypage/receipt" ||
      urlPath === "/mypage/receipt/"
    ) {
      setCanFlagOpenHowToModal(true);
      setHideButtonOnHeader(false);
      setEnableTabHistory(true);
      fetchDataPastOrders(page);
    } else if (
      urlPath === "/mypage/gochi_subscription" ||
      urlPath === "/mypage/gochi_subscription/"
    ) {
      setCanFlagOpenHowToModal(true);
      setHideButtonOnHeader(false);
      setEnableTabGochiSubscription(true);
      fetchDataGochiSubscription(page);
    } else if (urlPath === "/mypage/follow" || urlPath === "/mypage/follow/") {
      setCanFlagOpenHowToModal(true);
      setHideButtonOnHeader(false);
      setEnableTabFollow(true);
      if (isFollowTab) {
        fetchDataListFollow(page, true);
        setIsFollowTabActive(true);
      } else {
        fetchDataListFollow(page, isFollowTabActive);
        setIsFollowTabActive(false);
      }
      // fetchDataListFollow(page, isFollowTabActive);
    } else {
      setCanFlagOpenHowToModal(true);
      setHideButtonOnHeader(false);
      setEnableMyPage(true);
      getTopGochis();
      getWishList(props.userInfo.userId);
      getFavoriteList(props.userInfo.userId);
    }
  }, []);

  useEffect(() => {
    if (flagCanOpenHowToModal !== null) {
      checkOpenHowToModal();
    }
  }, [flagCanOpenHowToModal])

  function getTitle() {
    return "マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス";
  }

  function getMetaDescription() {
    return "マイページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。";
  }

  async function handleShowPopupHistoryChargeGochi(gochiBillId) {
    setLoading(true);
    setCurrentGochiBillId(gochiBillId);
    document.getElementsByTagName("html")[0].classList.add("active-modal");
    await getListHistoryChargeGochi(gochiBillId, pageHistoryChargeGochi, LIMIT_HISTORY_CHARGE_GOCHI);
    setLoading(false);
    setShowPopupHistoryChargeGochi(true);
  }

  async function loadMoreListHistoryChargeGochi(gochiBillId) {
    let nextPage = pageHistoryChargeGochi + 1;
    setLoadingHistoryCharge(true);
    if (nextPage <= Math.ceil(totalHistoryChargeGochi/LIMIT_HISTORY_CHARGE_GOCHI)) {
      setPageHistoryChargeGochi(nextPage);
      await getListHistoryChargeGochi(gochiBillId, nextPage, LIMIT_HISTORY_CHARGE_GOCHI);
    }
    setLoadingHistoryCharge(false);
  }

  async function getListHistoryChargeGochi(gochiBillId, page, limit) {
    let arrTemp = listHistoryChargeGochi;
    try {
        const dataHistory = await getListHistoryChargeGochiData(gochiBillId, page, limit);
        arrTemp = arrTemp.concat(dataHistory.list);
        setListHistoryChargeGochi(arrTemp);
        setTotalHistoryChargeGochi(dataHistory.count);
    } catch (error) {
        console.log(error);
    }
  }

  async function getListHistoryChargeGochiData(gochiBillId, page, limit) {
    try {
        const { data } = await GiftService.getListHistoryChargeGochi(gochiBillId, page, limit);
        return data;
    } catch(error) {
        console.log(error);
    }
  }

  function resetValueHistoryChargeGochi() {
    setCurrentGochiBillId(null);
    setPageHistoryChargeGochi(1);
    setListHistoryChargeGochi([]);
    setTotalHistoryChargeGochi(0);
  }

  function handleClosePopupHistoryChargeGochi() {
    resetValueHistoryChargeGochi();
    setShowPopupHistoryChargeGochi(false);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }

  async function handleShowPopupHistoryUsedGochiCharge(gochiBillId) {
    setLoading(true);
    setCurrentGochiBillId(gochiBillId);
    document.getElementsByTagName("html")[0].classList.add("active-modal");
    await getListHistoryUsedGochiCharge(gochiBillId, pageHistoryUsedGochiCharge, LIMIT_HISTORY_USED_GOCHI_CHARGE);
    setLoading(false);
    setShowPopupHistoryUsedGochiCharge(true);
  }

  async function handleShowPopupSendMessageGochiKid(item) {
    setLoading(true);
    setItemGochiKidUsed(item);
    document.getElementsByTagName("html")[0].classList.add("active-modal");
    setLoading(false);
    setShowPopupSendMessageGochiKid(true);
  }

  async function handleShowPopupGochiKidUsedDetail(item, index) {
    setLoading(true);
    setItemGochiKidUsed(item);
    setIndexGochiKidUsedChoose(index);
    document.getElementsByTagName("html")[0].classList.add("active-modal");
    setLoading(false);
    setIsGochiKidUsed(true);
    setShowPopupGochiKidUsedDetail(true);
  }

  async function handleShowPopupSendMessageGochiKidSuccess() {
    setLoading(true);
    document.getElementsByTagName("html")[0].classList.add("active-modal");
    setLoading(false);
    setShowPopupSendMessageGochiKidSuccess(true);
  }

  async function handleShowPopupRegisterUserKidGochiKid() {
    setLoading(true);
    await GiftService.getUserKidData(props.userInfo.userId).then((response) => {
      if (response.status === 200) {
        setUserKidData(response.data);
        document.getElementsByTagName("html")[0].classList.add("active-modal");
        setShowPopupRegisterUserKidGochiKid(true);
        setLoading(false);
      } else {
        setUserKidData({});
        setLoading(false);
        alert("エラーが発生しました。");
      }
    });
  }

  async function handleShowPopupRegisterUserKidGochiKidSuccess(isError, messageError) {
    setShowPopupRegisterUserKidGochiKid(false);
    setLoading(true);
    setIsRegisterUserKidError(isError);
    setMessageRegisterUserKidError(messageError);
    document.getElementsByTagName("html")[0].classList.add("active-modal");
    setLoading(false);
    setShowPopupRegisterUserKidGochiKidSuccess(true);
  }

  async function handleShowPopupCancelSubscriptionGochiKid(item) {
    setLoading(true);
    setItemSubscriptionGochiKid(item);
    document.getElementsByTagName("html")[0].classList.add("active-modal");
    setLoading(false);
    setShowPopupCancelSubscriptionGochiKid(true);
  }

  async function handleShowPopupCancelSubscriptionGochiKidSuccess(item) {
    setLoading(true);
    setItemSubscriptionGochiKid(item);
    document.getElementsByTagName("html")[0].classList.add("active-modal");
    setLoading(false);
    setShowPopupCancelSubscriptionGochiKidSuccess(true);
  }

  async function loadMoreListHistoryUsedGochiCharge(gochiBillId) {
    let nextPage = pageHistoryUsedGochiCharge + 1;
    setLoadingHistoryUsedGochiCharge(true);
    if (nextPage <= Math.ceil(totalHistoryUsedGochiCharge/LIMIT_HISTORY_USED_GOCHI_CHARGE)) {
      setPageHistoryUsedGochiCharge(nextPage);
      await getListHistoryUsedGochiCharge(gochiBillId, nextPage, LIMIT_HISTORY_USED_GOCHI_CHARGE);
    }
    setLoadingHistoryUsedGochiCharge(false);
  }

  async function getListHistoryUsedGochiCharge(gochiBillId, page, limit) {
    let arrTemp = listHistoryUsedGochiCharge;
    try {
        const dataHistory = await getListHistoryUsedGochiChargeData(gochiBillId, page, limit);
        if(dataHistory.list) {
          arrTemp = arrTemp.concat(dataHistory.list);
          setListHistoryUsedGochiCharge(arrTemp);
          setTotalHistoryUsedGochiCharge(dataHistory.count);
        }
    } catch (error) {
        console.log(error);
    }
  }

  async function getListHistoryUsedGochiChargeData(gochiBillId, page, limit) {
    try {
        const { data } = await GiftService.getListHistoryUsedGochiCharge(gochiBillId, page, limit);
        return data;
    } catch(error) {
        console.log(error);
    }
  }

  function resetValueHistoryUsedChargeGochi() {
    setCurrentGochiBillId(null);
    setPageHistoryUsedGochiCharge(1);
    setListHistoryUsedGochiCharge([]);
    setTotalHistoryUsedGochiCharge(0);
  }

  function handleClosePopupHistoryUsedGochiCharge() {
    resetValueHistoryUsedChargeGochi();
    setShowPopupHistoryUsedGochiCharge(false);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }

  function handleClosePopupGochiKidUsedDetail() {
    setIsGochiKidUsed(false);
    setShowPopupGochiKidUsedDetail(false);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }

  function handleClosePopupSendMessageGochiKid() {
    setShowPopupSendMessageGochiKid(false);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }

  function handleClosePopupSendMessageGochiKidSuccess() {
    setShowPopupSendMessageGochiKidSuccess(false);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }

  function handleClosePopupRegisterUserKidGochiKid() {
    setShowPopupRegisterUserKidGochiKid(false);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }

  function handleClosePopupRegisterUserKidGochiKidSuccess() {
    setShowPopupRegisterUserKidGochiKidSuccess(false);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }

  function handleOpenInputGochiKidPriceModal(shopId, gochiKidUnlimit = 0) {
    setLoading(true);
    document.getElementsByTagName("html")[0].classList.add("active-modal");
    setLoading(false);
    setChoiceShopId(shopId);
    setGochiKidUnlimitShopChoice(gochiKidUnlimit);
    setChoiceMenuId(null);
    setPriceChoiceMenu(null);
    setGochiKidPriceUseAmount(null);
    setShowInputGochiKidPriceModal(true);
  }

  function handleCloseInputGochiKidPriceModal() {
    setShowInputGochiKidPriceModal(false);
    if (numUserKidUse > 1) {
      // back to SelectUserKidModal and keep class "active-modal"
      setShowSelectUserKidModal(true);
    } else {
      if (!isGochiKidUsed) {
        document.getElementsByTagName("html")[0].classList.remove("active-modal");
      }
    }
  }

  function handleOpenSelectUserKidModal(shopId, menuId, gochiKidUnlimit = 0, price, gochiKidReceipt = 0) {
    setLoading(true);
    document.getElementsByTagName("html")[0].classList.add("active-modal");
    setLoading(false);
    setChoiceShopId(shopId);
    setChoiceMenuId(menuId);
    setPriceChoiceMenu(price);

    if (gochiKidGroupId) {
      setGochiKidUnlimitShopChoice(0);
    } else {
      setGochiKidUnlimitShopChoice(gochiKidUnlimit);
    }

    setGochiKidReceiptShopChoice(gochiKidReceipt);
    if (menuId) {
      setGochiKidPriceUseAmount(null);
    }
    setShowSelectUserKidModal(true);
  }

  function handleCloseSelectUserKidModal() {
    if (!isGochiKidUsed) {
      document.getElementsByTagName("html")[0].classList.remove("active-modal");
    }
    setShowSelectUserKidModal(false);
  }

  function handleClosePopupCancelSubscriptionGochiKid() {
    setShowPopupCancelSubscriptionGochiKid(false);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }

  function handleClosePopupCancelSubscriptionGochiKidSuccess() {
    setShowPopupCancelSubscriptionGochiKidSuccess(false);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }

  function handShowErrorUseGochiKidPopup(errorMessage) {
    setErrorMessageGochiKidUse(errorMessage);
    setShowPopupErrorGochiKidUse(true);
  }
  
  async function fetchDataKodomoUse(userId, page, createdAt) {
    setLoading(true);
    await getUserKidData(userId);
    await getUserKidGochiKid(page, createdAt);
    if (accessToken && accessCode) {
      await handleShowListGroupSpecialGochiKidModal(shopIdGochiKidQr);
    } else {
      setLoading(false);
    }
  }

  function handleShowPolicyGochiKidUseModal() {
    if (enableTabGochiKidUsed || enableTabGochiKidSelectShop || enableTabGochiKidSelectMenu) {
      return;
    }
    document.getElementsByTagName("html")[0].classList.add("active-modal");
    setShowPolicyGochiKidUseModal(true);
  }

  function handleClosePolicyGochiKidUseModal() {
    setShowPolicyGochiKidUseModal(false);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }

  function handleShowPolicyGochiKidModal() {
    document.getElementsByTagName("html")[0].classList.add("active-modal");
    setShowPolicyGochiKidModal(true);
  }

  function handleClosePolicyGochiKidModal() {
    setShowPolicyGochiKidModal(false);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }

  function handleClosePolicyGochiKidCampaignModal() {
    setShowPolicyGochiKidCampaignModal(false);
    document.getElementsByTagName("html")[0].classList.remove("active-modal");
  }

  async function handleShowListGroupSpecialGochiKidModal(shopId) {
    setLoading(true);
    const response = await getListGroupSpecialGochiKid(pageListGroupSpecialGochiKidAll, LIMIT_LIST_GROUP_SPECIAL_GOCHI_KID_ALL, shopId);
    if (response?.list?.length > 0) {
      const list = [{}, ...response.list];
      const listGroupSpecialGochiKidFirst = list.filter((item, index) => index < LIMIT_LIST_GROUP_SPECIAL_GOCHI_KID);
      setListGroupSpecialGochiKid(listGroupSpecialGochiKidFirst);
      setGochiKidGroupIdChoose(listGroupSpecialGochiKidFirst[1].gochiKidGroupId);
      setLoading(false);
    } else if (response?.list?.length === 0 && accessToken && accessCode) {
      return;
    } else {
      setLoading(false);
    }
  }

  async function loadMoreListGroupSpecialGochiKid(shopId) {
    if (listGroupSpecialGochiKid.length !== 0 && listGroupSpecialGochiKidAll.length !== 0) {
      let arrTemp = listGroupSpecialGochiKid;
      const page = pageListGroupSpecialGochiKid;
      const limit = LIMIT_LIST_GROUP_SPECIAL_GOCHI_KID;
      let listAll = [{}, ...listGroupSpecialGochiKidAll];
      if ((listGroupSpecialGochiKid.length - 1 < listGroupSpecialGochiKidAll.length && listGroupSpecialGochiKid.length !== listGroupSpecialGochiKidAll.length) || 
          (listGroupSpecialGochiKid.length - 1 < listGroupSpecialGochiKidAll.length && listGroupSpecialGochiKid.length === listGroupSpecialGochiKidAll.length && listGroupSpecialGochiKidAll.length === totalGroupSpecialGochiKid - 1)) {
        let arrPush = listAll && listAll.length > 0 && listAll.filter((item, index) => index >= page * limit && index < (page + 1) * limit);
        arrTemp = arrTemp.concat(arrPush);
        setListGroupSpecialGochiKid(arrTemp);
        setPageListGroupSpecialGochiKid(page + 1);
      } else if (pageListGroupSpecialGochiKidAll + 1 <= Math.ceil((totalGroupSpecialGochiKid - 1)/LIMIT_LIST_GROUP_SPECIAL_GOCHI_KID_ALL)) {
        setLoadingListGroupSpecialGochiKid(true);
        const response = await getListGroupSpecialGochiKid(pageListGroupSpecialGochiKidAll + 1, LIMIT_LIST_GROUP_SPECIAL_GOCHI_KID_ALL, shopId);
        if (response?.list?.length > 0) {
          listAll = listAll.concat(response.list);
          let arrPush = listAll && listAll.length > 0 && listAll.filter((item, index) => index >= page * limit && index < (page + 1) * limit);
          arrTemp = arrTemp.concat(arrPush);
          setListGroupSpecialGochiKid(arrTemp);
          setPageListGroupSpecialGochiKid(page + 1);
        }
        setPageListGroupSpecialGochiKidAll(pageListGroupSpecialGochiKidAll + 1);
        setLoadingListGroupSpecialGochiKid(false);
      }
    }
  }

  async function getListGroupSpecialGochiKid(page, limit, shopId) {
    let arrTemp = listGroupSpecialGochiKidAll;
    try {
      const dataGroup = await getListGroupSpecialGochiKidData(page, limit, shopId);
      if (dataGroup.count > 0) {
        arrTemp = arrTemp.concat(dataGroup.list);
        setListGroupSpecialGochiKidAll(arrTemp);
        setTotalGroupSpecialGochiKid(dataGroup.count + 1);
        setNumberSlotCanUseAll(dataGroup.numberSlotCanUseAll);
        setShowListGroupSpecialGochiKidModal(true);
      } else {
        setListGroupSpecialGochiKidAll([]);
        setTotalGroupSpecialGochiKid(0);
        setNumberSlotCanUseAll(0);
        setShowListGroupSpecialGochiKidModal(false);
        if (shopId && accessToken && accessCode) {
          window.location.href = addParamUrl(
            `/mypage/kodomo_use/search_shop/${shopId}?accessToken=${accessToken}&accessCode=${accessCode}`,
            [isSiteParam]
          );
        } else {
          window.location.href = addParamUrl(
            `/mypage/kodomo_use/search_shop`,
            [isSiteParam]
          );
        }
      }
      return dataGroup;
    } catch (error) {
      console.log(error);
    }
  }

  async function getListGroupSpecialGochiKidData(page, limit, shopId) {
    try {
        const parameters = {
          page,
          limit,
          shopId
        };
        const { data } = await GiftService.getGroupSpecialGochiKid(parameters, accessToken, checkTokenExpired)
        return data;
    } catch(error) {
        console.log(error);
    }
  }

  function handleCloseListGroupSpecialGochiKidModal() {
    if (accessToken && accessToken) {
      return;
    } else {
      document.getElementsByTagName("html")[0].classList.remove("hide-scroll-background-gochi-kid");
      setListGroupSpecialGochiKid([]);
      setListGroupSpecialGochiKidAll([]);
      setPageListGroupSpecialGochiKid(1);
      setPageListGroupSpecialGochiKidAll(1);
      setTotalGroupSpecialGochiKid(0);
      setNumberSlotCanUseAll(0);
      setShowListGroupSpecialGochiKidModal(false);
      setGochiKidGroupIdChoose(0);
    }
  }

  async function handleChangeTabMessageDonatorGochiKid(numberTab) {
    if (numberTab === 0) {
      setNumberTabThankMessageDonator(0);
      setPageReportShopGochiKid(1);
      setTotalReportShopGochiKid(0);
      setListReportShopGochiKid([]);
      setPageMessageDonatorGochiKid(1);
      await getGochiKidSupportMessage(1, 'created_at');
    } else if (numberTab === 1) {
      setNumberTabThankMessageDonator(1);
      setPageMessageDonatorGochiKid(1);
      setTotalMessageDonatorGochiKid(0);
      setListMessageDonatorGochiKid([]);
      setPageReportShopGochiKid(1);
      await getGochiKidReportShopMessage(1, 'created_at');
    }
  }

  function handleShowPopupAcceptPolicyGochiKidReceipt(shopId, gochiKidUnlimit = 0, newChoiceMenuId, gochiKidUseCode) {
    setChoiceShopId(shopId);
    setGochiKidUnlimitShopChoice(gochiKidUnlimit);
    setGochiKidUseCodeShopChoice(gochiKidUseCode || 0);

    if (newChoiceMenuId) {
      setChoiceMenuId(newChoiceMenuId);
    }

    setShowAcceptPolicyGochiKidReceiptModal(true);
    document.getElementsByTagName("html")[0].classList.add("active-modal");
  }

  function handleClosePopupAcceptPolicyGochiKidReceipt(isClose) {
    setShowAcceptPolicyGochiKidReceiptModal(false);

    if (isClose) {
      if (numUserKidUse > 1 && gochiKidUseCodeShopChoice !== 1) {
        setShowSelectUserKidModal(true);
      } else {
        if (!isGochiKidUsed) {
          document.getElementsByTagName("html")[0].classList.remove("active-modal");
        }
      }
    }
  }

  function getSakaiPlusLoginUrl() {
    const clientId = encodeURIComponent(process.env.REACT_APP_TOSHIOS_CLIENT_ID);
    const scope = encodeURIComponent(process.env.REACT_APP_TOSHIOS_SCOPE);
    const redirectUri = encodeURIComponent(`${window.location.origin}/toshios-auth`);
    const url = `${process.env.REACT_APP_TOSHIOS_DOMAIN}?p=b2c_1a_signup_signin&client_id=${clientId}&nonce=defaultNonce&response_type=code&scope=${scope}&prompt=login&redirect_uri=${redirectUri}`;
    return url;
  }

  function convertImageToBase64(url, callback) {
    if (!url) {
      return null;
    }

    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  // console.log('props myPage ===', props);

  return (
    <>
      <MetaTags>
        <title>{getTitle()}</title>
        <meta
          name="keywords"
          content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト"
        />
        <meta name="description" content={getMetaDescription()} />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={getMetaDescription()} />
        <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content={getTitle()} />
        <meta property="og:title" content={getTitle()} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta property="og:description" content={getMetaDescription()} />
      </MetaTags>
      {!mogugori && <Header hideButtonOnHeader={hideButtonOnHeader}/>}


      <main className={enableTabGochiChargeDetail ? 'bg-white border-top-charge-detail' : ''}>
        <div className="breadcrumb">
          <div className="container">
            <ol className="breadcrumb-list">
              {enableMyPage && (
                <li>
                  <span>GOCHIマイページ</span>
                </li>
              )}
              {!enableMyPage && (
                <li>
                  <a href={addParamUrl("/mypage", [isSiteParam])}>GOCHIマイページ</a>
                </li>
              )}
              {enableTabFollow && (
                <li>
                  <span>フォロー・フォロワー</span>
                </li>
              )}
              {enableTabTicket && (
                <li>
                  <span>購入履歴</span>
                </li>
              )}
              {enableTabPaymentInfo && (
                <li>
                  <span>支払い情報</span>
                </li>
              )}
              {enableTabHistory && (
                <li>
                  <span>領収書一覧</span>
                </li>
              )}
              {enableTabTome && (
                <li>
                  <span>自分宛てごち</span>
                </li>
              )}
              {(enableTabGochiCharge) && (
                <li>
                  <span>地域通貨チケット</span>
                </li>
              )}
              {enableTabGochiKidUsed && (
                <li>
                  <span>こどもごちめし食堂利用</span>
                </li>
              )}
              {(enableTabGochiKidSelectShop || enableTabGochiKidSelectMenu) && (
                <li>
                  <a href={addParamUrl("/mypage/kodomo_use", [isSiteParam])}>こどもごちめし食堂利用</a>
                </li>
              )}
              {enableTabGochiKidSelectShop && (
                <li>
                  <span>利用店舗を探す</span>
                </li>
              )}
              {enableTabGochiKidSelectMenu && (
                <li>
                  <a href={addParamUrl("/mypage/kodomo_use/search_shop", [isSiteParam])}>利用店舗を探す</a>
                </li>
              )}
              {enableTabGochiKidSelectMenu && (
                <li>
                  <span>{shopName}のメニューを利用する</span>
                </li>
              )}
              {enableTabGochiKidSupport && (
                <li>
                  <span>こどもごちめし食堂支援</span>
                </li>
              )}
              {(enableTabGochiKidSupportContinue || enableTabGochiKidSupportMessage) && (
                <li>
                  <a href={addParamUrl("/mypage/kodomo_support", [isSiteParam])}>こどもごちめし食堂支援</a>
                </li>
              )}
              {(enableTabGochiKidSupportContinue) && (
                <li>
                  <span>継続支援状況</span>
                </li>
              )}
              {(enableTabGochiKidSupportMessage) && (
                <li>
                  <span>感謝メッセージ</span>
                </li>
              )}
              {enableTabGochiChargeDetail && (
                <li>
                  <a href="/mypage/mygochi_charge">地域通貨チケット</a>
                </li>
              )}
              {enableTabGochiChargeDetail && (history.location.pathname.split('/').length > 0 && history.location.pathname.split('/')[4] !== 'qrCode') && (
                <li>
                  <span>決済方法でチャージする</span>
                </li>
              )}
              {enableTabGochiChargeDetail && (history.location.pathname.split('/').length > 0 && history.location.pathname.split('/')[4] === 'qrCode') && (
                <li>
                  <span>QRコードでチャージする</span>
                </li>
              )}
              {enableTabGochiSubscription && (
                <li>
                  <span>サブスクリプション</span>
                </li>
              )}
            </ol>
          </div>
        </div>
        {!enableTabGochiKidSelectShop && !enableTabGochiKidSelectMenu && (
        <div className="box-text-gray">
          <div className="container">
            <div className={showMoreText ? "open-more text-content" : "text-content"}>
              <p>
                GOCHIマイページとは、GOCHIアカウントをお持ちの方がさまざまなオンラインサービスで利用できる
                <span className="font-weight-700">共通マイページ</span>
                です。本マイページは
                <span className="font-weight-700">ごちめし、さきめし、こどもごちめし、地域通貨など</span>
                でご利用いただけます。
              </p>
              <p>
                <span className="font-weight-700">GOCHIアカウント</span>
                とは、さまざまなオンラインサービスで利用できる共通アカウントです。本サービスでは、
                <span className="font-weight-700">ごちめし、さきめし、こどもごちめし、地域通貨</span>
                などでご利用いただけます。
              </p>
              <p className="read-more"><span onClick={() => setShowMoreText(!showMoreText)}>{showMoreText ? "閉じる" : "もっと見る"}</span></p>
            </div>
          </div>
        </div>
        )}
        {!enableTabGochiChargeDetail && !enableTabGochiKidSelectShop && !enableTabGochiKidSelectMenu && (
        <div className="container">
          {/* プロフィール */}
          <section className="section">
            <div className="section-body">
              <div className="profile-group">
                <div className="profile">
                  <div
                    className={`profile-header ${
                      loadingUserDetail ? "loading" : ""
                    }`}
                  >
                    <div className="profile-thumbnail">
                      <div className="profile-thumbnail-avatar">
                        <div className="avatar">
                          <img
                            id="image-result"
                            src={
                              userDetails &&
                              userDetails.userImage &&
                              userDetails.userImage.length > 0 &&
                              userDetails.userImage[0].image
                                ? userDetails.userImage[0].image
                                : NO_IMAGE_PROFILE
                            }
                            loading="lazy"
                            alt={
                              userDetails && userDetails.displayName
                                ? userDetails.displayName
                                : ""
                            }
                          />
                        </div>
                      </div>

                      <a href={"/mypage/edit"} className="link profile-edit-link">
                        編集する
                      </a>
                      <a
                        href={`/mypage/user/${props.userInfo.userId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link profile-edit-link"
                      >
                        公開ページ
                        <br className="under-tab" />
                        プレビュー
                      </a>
                    </div>
                    <section className="profile-header-section">
                      <div className="heading-lg">
                        <h2 className="profile-name">
                          {userDetails && userDetails.displayName
                            ? userDetails.displayName
                            : ""}
                        </h2>
                      </div>
                      <div className="profile-id">
                          @
                          {userDetails && userDetails.accountId
                            ? userDetails.accountId
                            : ""}
                        </div>
                        <dl className="profile-info-list">
                          <div className="profile-info-item">
                            <dt className="mark">
                              <svg className="icon">
                                <use xlinkHref="/assets/img/common/icon.svg#map-marker" />
                              </svg>
                            </dt>
                            <dd className="text">
                              {userDetails &&
                              userDetails.userLocation &&
                              userDetails.userLocation.prefectureId
                                ? listPrefectures &&
                                  listPrefectures.length > 0 &&
                                  getPrefectureName(
                                    listPrefectures,
                                    userDetails.userLocation.prefectureId
                                  )
                                : ""}
                            </dd>
                          </div>
                          <div className="profile-info-item">
                            <dt className="mark">
                              <svg className="icon">
                                <use xlinkHref="/assets/img/common/icon.svg#link" />
                              </svg>
                            </dt>
                            <dd className="text">
                              <a
                                href={
                                  userDetails && userDetails.webPage
                                    ? userDetails.webPage
                                    : ""
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {userDetails && userDetails.webPage
                                  ? userDetails.webPage
                                  : ""}
                              </a>
                            </dd>
                          </div>
                      </dl>
                    </section>
                  </div>
                  {/* <UserInfo /> */}
                  <div className="profile-body">
                    <div className="profile-friends">
                      <Link
                        // href="/mypage/follow"
                        className="link"
                        to="#"
                        onClick={() => {
                          setIsFollowTabActive(true);
                          handleChangeListFollowTab(true);
                          handleEnableFollowTab();
                          history.push({
                            pathname: "/mypage/follow",
                            data: { isFollowTabActive: true },
                          });
                        }}
                      >
                        フォロー
                        <span className="text-underline number">
                          {followCount}
                        </span>
                      </Link>
                      <Link
                        // href="/mypage/follow"
                        className="link"
                        to="#"
                        onClick={() => {
                          setIsFollowTabActive(false);
                          handleChangeListFollowTab(false);
                          handleEnableFollowTab();
                          history.push({
                            pathname: "/mypage/follow",
                            data: { isFollowTabActive: false },
                          });
                        }}
                      >
                        フォロワー
                        <span className="text-underline number">
                          {userDetails && userDetails.followerCount
                            ? userDetails.followerCount
                            : 0}
                        </span>
                      </Link>
                    </div>
                    
                    <div className="mypage-text-display-name">
                      {userDetails && userDetails.displayName ? userDetails.displayName + '様へのサービス' : ''}
                    </div>
                    <div className="mypage-tab-main">
                      <a href={addParamUrl("/mypage/purchase_histories", [isSiteParam])} className={`btn btn-sm btn-outline btn-rect btn-black sub-mypage-tab-item tab1 ${(enableTabTicket || enableTabPaymentInfo || enableTabHistory || enableTabTome) ? "selected" : ""}`}></a>
                      {process.env.REACT_APP_SHOW_GOCHI_KID === "true" ? (
                        <div onClick={() => {handleShowPolicyGochiKidUseModal()}} className={`btn btn-sm btn-outline btn-rect btn-black sub-mypage-tab-item tab3 ${(enableTabGochiKidUsed || enableTabGochiKidSelectShop || enableTabGochiKidSelectMenu) ? "selected" : ""}`}></div>
                      ) : (
                        <div className={`btn btn-sm btn-outline btn-rect btn-black sub-mypage-tab-item tab3 gochi-kid-use-coming-soon ${(enableTabGochiKidUsed || enableTabGochiKidSelectShop || enableTabGochiKidSelectMenu) ? "selected" : ""}`}>
                          {(process.env.REACT_APP_SHOW_GOCHI_KID === 'false' || process.env.REACT_APP_SHOW_GOCHI_KID === undefined) && (<div>Coming Soon</div>)}
                        </div>
                      )}
                      {userDetails?.showGochiKidDonate && process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === "true" && (
                        <a href={addParamUrl("/mypage/kodomo_support", [isSiteParam])} className={`btn btn-sm btn-outline btn-rect btn-black sub-mypage-tab-item tab2 ${(enableTabGochiKidSupport || enableTabGochiKidSupportContinue || enableTabGochiKidSupportMessage) ? "selected" : ""}`}></a>
                      )}
                      {userDetails?.showMenuChildType5 && (
                        <a href={addParamUrl("/mypage/mygochi_charge", [isSiteParam])} className={`btn btn-sm btn-outline btn-rect btn-black sub-mypage-tab-item tab4 ${(enableTabGochiCharge || enableTabGochiChargeDetail) ? "selected" : ""}`}></a>
                      )}
                      <a href={getSakaiPlusLoginUrl()} className={`btn btn-sm btn-outline btn-rect btn-black sub-mypage-tab-item tab5 ${userDetails && !userDetails.toshiosAuthId ? '' : 'disable-tab5'}`}></a>
                    </div>
                    
                      {/*自分以外のユーザーにはみせない*/}
                      <div className="btn-group mt-30 profile-nav-btn-group">
                        {(enableTabTicket || enableTabPaymentInfo || enableTabHistory || enableTabTome) && (
                        <>
                          <a
                            href={addParamUrl("/mypage/purchase_histories", [isSiteParam])}
                            className={`btn btn-sm btn-outline btn-rect btn-black profile-nav-btn ${
                              enableTabTicket ? "selected" : ""
                            }`}
                          >
                            購入履歴
                          </a>
                          <a
                            href={addParamUrl("/mypage/payment_informations", [isSiteParam])}
                            className={`btn btn-sm btn-outline btn-rect btn-black profile-nav-btn ${
                              enableTabPaymentInfo ? "selected" : ""
                            }`}
                          >
                            お支払い情報
                          </a>
                          <a
                            href={addParamUrl("/mypage/receipt", [isSiteParam])}
                            className={`btn btn-sm btn-outline btn-rect btn-black profile-nav-btn ${
                              enableTabHistory ? "selected" : ""
                            }`}
                          >
                            領収書一覧
                          </a>
                          <a
                            href={addParamUrl("/mypage/mygochi", [isSiteParam])}
                            className={`btn btn-sm btn-outline btn-rect btn-black profile-nav-btn ${
                              enableTabTome ? "selected" : ""
                            }`}
                          >
                            受け取った商品一覧
                          </a>
                        </>
                        )}
                        {(enableTabGochiKidSupport || enableTabGochiKidSupportContinue || enableTabGochiKidSupportMessage) && (
                          <>
                          <a
                            href={addParamUrl("/mypage/kodomo_support", [isSiteParam])}
                            className={`btn btn-sm btn-outline btn-rect btn-black profile-nav-btn ${
                              enableTabGochiKidSupport ? "selected" : ""
                            }`}
                          >
                            支援履歴
                          </a>
                          <a
                            href={addParamUrl("/mypage/kodomo_support/continue", [isSiteParam])}
                            className={`btn btn-sm btn-outline btn-rect btn-black profile-nav-btn ${
                              enableTabGochiKidSupportContinue ? "selected" : ""
                            }`}
                          >
                            継続支援状況
                          </a>
                          <a
                            href={addParamUrl("/mypage/kodomo_support/message", [isSiteParam])}
                            className={`btn btn-sm btn-outline btn-rect btn-black profile-nav-btn ${
                              enableTabGochiKidSupportMessage ? "selected" : ""
                            }`}
                          >
                            感謝メッセージ
                          </a>
                          <div className="profile-nav-btn no-background"></div>
                          </>
                        )}
                        {/* <a
                          href="/mypage/gochi_subscription"
                          className={`btn btn-sm btn-outline btn-rect btn-black profile-nav-btn ${
                            enableTabGochiSubscription ? "selected" : ""
                          }`}
                        >
                          サブスクリプション
                        </a> */}
                      </div>

                      {/*User detail*/}
                      {enableMyPage === true && (
                        <>
                          <div
                            className={`profile-about-group ${
                              loadingUserDetail ? "loading" : ""
                            }`}
                          >
                            <dl className="profile-about-list">
                              <div className="profile-about-item">
                                <dt className="title">誕生日</dt>
                                <dd className="text">
                                  {userDetails &&
                                    userDetails.birthday &&
                                    userDetails.birthday !== "" &&
                                    displayBirthDay(userDetails.birthday)}
                                </dd>
                              </div>
                              <div className="profile-about-item">
                                <dt className="title">記念日</dt>
                                <dd className="text">
                                  {userDetails &&
                                    userDetails.userAnniversary &&
                                    userDetails.userAnniversary.length > 0 &&
                                    userDetails.userAnniversary.map(
                                      (item, index) => {
                                        return (
                                          <>
                                            {item.anniversary !== ""
                                              ? `${getMonth(
                                                  item.anniversary
                                                )}月${getDate(
                                                  item.anniversary
                                                )}日`
                                              : ""}
                                            {item.title && item.title !== ""
                                              ? `(${item.title})`
                                              : ""}
                                            {`	`}
                                          </>
                                        );
                                      }
                                    )}
                                </dd>
                              </div>
                              <div className="profile-about-item">
                                <dt className="title">苦手な食べ物</dt>
                                <dd className="text">
                                  {userDetails && userDetails.unFavoriteFood}
                                </dd>
                              </div>
                              <div className="profile-about-item">
                                <dt className="title">よく行くエリア</dt>
                                <dd className="text">
                                  {userDetails &&
                                    userDetails.userFavoriteLocations &&
                                    userDetails.userFavoriteLocations.length >
                                      0 &&
                                    userDetails.userFavoriteLocations.map(
                                      (item, index) => {
                                        let prefName =
                                          listPrefectures &&
                                          listPrefectures.length > 0 &&
                                          getPrefectureName(
                                            listPrefectures,
                                            item.prefectureId
                                          );
                                        let cityName =
                                          listCities &&
                                          listCities.length > 0 &&
                                          getCityName(listCities, item.cityId);
                                        if (
                                          index ===
                                          userDetails.userFavoriteLocations
                                            .length -
                                            1
                                        ) {
                                          return `${prefName} ${cityName}`;
                                        }
                                        return `${prefName} ${cityName}、`;
                                      }
                                    )}
                                </dd>
                              </div>
                            </dl>
                            {userDetails &&
                              userDetails.userMessage &&
                              userDetails.userMessage.length > 0 &&
                              userDetails.userMessage[0].message && (
                                <div
                                  className="profile-comment"
                                  style={{ whiteSpace: "pre-line" }}
                                >
                                  {userDetails.userMessage[0].message.length <=
                                    400 && userDetails.userMessage[0].message}
                                  {userDetails.userMessage[0].message.length >
                                    400 && (
                                    <>
                                      {seeMore
                                        ? replaceString(
                                            userDetails.userMessage[0].message,
                                            400
                                          )
                                        : userDetails.userMessage[0].message}
                                      <a
                                        href
                                        type="button"
                                        onClick={() => {
                                          setSeeMore(!seeMore);
                                        }}
                                      >
                                        {seeMore ? "全てを見る" : "少なく見る"}
                                      </a>
                                    </>
                                  )}
                                </div>
                              )}
                          </div>

                          {/* Top 3 Gochi Tome */}
                          <section className="section profile-message">
                            <div className="section-header">
                              <div className="heading-lg">
                                <h2>自分宛ごち</h2>
                              </div>
                              <div className="profile-message-link">
                                <a href="/mypage/mygochi">
                                  受け取った商品一覧をみる
                                </a>
                              </div>
                            </div>
                            <div
                              className={`section-body ${
                                loadingTopGochi ? "loading" : ""
                              }`}
                            >
                              <dl className="message-list">
                                {topGochiYourSelf &&
                                  topGochiYourSelf.length > 0 &&
                                  topGochiYourSelf.map((item, key) => {
                                    let index = `${item.gochiBillId}-${key}`;
                                    return (
                                      <a
                                        className="message-list-item"
                                        href={item.inviteUrl}
                                        key={index}
                                      >
                                        <dt className="date">
                                          <div className="message-info bullet-red">
                                            <time className="date-area">
                                              {item.createdAt
                                                ? formatDate(item.createdAt)
                                                : ""}
                                            </time>
                                          </div>
                                        </dt>
                                        <dd className="title">
                                          {item.gochiRegister &&
                                          item.gochiRegister.displayName
                                            ? item.gochiRegister.displayName
                                            : ""}
                                          さんからごちが届いています
                                        </dd>
                                        <dd className="text">
                                          {item.shop && item.shop.shopName
                                            ? item.shop.shopName
                                            : item.groupInfo &&
                                              item.groupInfo.groupName
                                            ? item.groupInfo.groupName
                                            : ""}
                                        </dd>
                                        <dd className="ticket-link">
                                          <span className="text-underline">
                                            チケットを見る
                                          </span>
                                        </dd>
                                      </a>
                                    );
                                  })}
                              </dl>
                            </div>
                          </section>
                        </>
                      )}

                      {enableTabTicket === true && (
                        <Ticket
                          NO_IMAGE_MENU={NO_IMAGE_MENU}
                          searchResults={searchResults}
                          totalResult={totalResult}
                          limit={LIMIT}
                          page={page}
                          handleChangePage={handleChangePage}
                          onFilterListPurchaseHistories={
                            onFilterListPurchaseHistories
                          }
                          formatDate={formatDate}
                          handleShowDetailGochi={handleShowDetailGochi}
                          handleUpdate={handleUpdate}
                          loadingTicket={loadingTicket}
                        />
                      )}

                      {enableTabPaymentInfo && (
                        <PaymentInformations
                          paymentList={paymentList}
                          userId={props.userInfo.userId}
                          loadingPaymentInfo={loadingPaymentInfo}
                        />
                      )}

                      {enableTabFollow === true && (
                        <Follow
                          isFollowTabActive={isFollowTabActive}
                          searchResults={searchResults}
                          totalResult={totalResult}
                          limit={LIMIT}
                          page={page}
                          handleChangePage={handleChangePage}
                          handleInputUsername={handleInputUsername}
                          onSearchListFollow={onSearchListFollow}
                          handleChangeListFollowTab={handleChangeListFollowTab}
                          tempFollowList={tempFollowList}
                          setTempFollowList={setTempFollowList}
                          loadingFollow={loadingFollow}
                          setFollowCount={setFollowCount}
                          loginUserId={props.userInfo?.userId}
                        />
                      )}

                      {enableTabHistory === true && (
                        <Receipt
                          searchResults={searchResults}
                          totalResult={totalResult}
                          limit={LIMIT}
                          page={page}
                          handleChangePage={handleChangePage}
                          sortPastOrder={sortPastOrder}
                          onSortListPastOrders={onSortListPastOrders}
                          formatDate={formatDate}
                          handleInputChange={handleInputChange}
                          handleShowPopupEmail={handleShowPopupEmail}
                          loadingReceipt={loadingReceipt}
                        />
                      )}

                      {enableTabTome === true && (
                        <ReceiveGochi
                          NO_IMAGE_MENU={NO_IMAGE_MENU}
                          searchResults={searchResults}
                          totalResult={totalResult}
                          limit={LIMIT}
                          page={page}
                          handleChangePage={handleChangePage}
                          filterGochi={filterGochi}
                          onFilterListGochis={onFilterListGochis}
                          formatDate={formatDate}
                          handleShowDetailGochi={handleShowDetailGochi}
                          handleUpdate={handleUpdate}
                          loadingGochiTome={loadingGochiTome}
                        />
                      )}

                      {enableTabGochiCharge === true && (
                        <GochiCharge
                          NO_IMAGE_MENU={NO_IMAGE_MENU}
                          searchResults={searchResults}
                          totalResult={totalResult}
                          limit={LIMIT}
                          page={page}
                          handleChangePage={handleChangePage}
                          formatDate={formatDate}
                          handleUpdate={handleUpdate}
                          loadingGochiCharge={loadingGochiCharge}
                          handleShowPopupHistoryChargeGochi={handleShowPopupHistoryChargeGochi}
                          handleShowPopupHistoryUsedGochiCharge={handleShowPopupHistoryUsedGochiCharge}
                        />
                      )}

                      {enableTabGochiSubscription === true && (
                        <SubscriptionGochi
                          NO_IMAGE_MENU={NO_IMAGE_MENU}
                          searchResults={searchResults}
                          totalResult={totalResult}
                          limit={LIMIT}
                          page={page}
                          handleChangePage={handleChangePage}
                          formatDate={formatDate}
                          handleUpdate={handleUpdate}
                          loadingGochiSubscription={loadingGochiSubscription}
                        />
                      )}

                      {enableTabGochiKidUsed === true && (
                        <GochiKidUsed
                          NO_IMAGE_MENU={NO_IMAGE_MENU}
                          searchResults={searchResults}
                          totalResult={totalResult}
                          limit={LIMIT}
                          page={page}
                          userKidData={userKidData}
                          handleChangePage={handleChangePage}
                          formatDate={formatDate}
                          handleUpdate={handleUpdate}
                          loadingGochiKidUsed={loadingGochiKidUsed}
                          handleShowPopupGochiKidUsedDetail={handleShowPopupGochiKidUsedDetail}
                          handleShowPopupSendMessageGochiKid={handleShowPopupSendMessageGochiKid}
                          handleShowPopupRegisterUserKidGochiKid={handleShowPopupRegisterUserKidGochiKid}
                          thanksMessageIsNull={thanksMessageIsNullGochiKid}
                          retryUploadReceiptGochiKid={retryUploadReceiptGochiKid}
                          isReceiptApproveRequired={isReceiptApproveRequired}
                          surveyIsNullGochiKid={surveyIsNullGochiKid}
                          handleShowListGroupSpecialGochiKidModal={handleShowListGroupSpecialGochiKidModal}
                          handleShowPolicyGochiKidModal={handleShowPolicyGochiKidModal}
                          getUserKidGochiKid={getUserKidGochiKid}
                          convertImageToBase64={convertImageToBase64}
                          listReceiptImageGochiKid={listReceiptImageGochiKid}
                        />
                      )}

                      {enableTabGochiKidSupport === true && (
                        <GochiKidSupport
                          NO_IMAGE_MENU={NO_IMAGE_MENU}
                          searchResults={searchResults}
                          totalResult={totalResult}
                          limit={LIMIT}
                          page={page}
                          handleChangePage={handleChangePage}
                          formatDate={formatDate}
                          handleUpdate={handleUpdate}
                          userKidStaticData={userKidStaticData}
                          loadingGochiKidSupport={loadingGochiKidSupport}
                          userName={userDetails && userDetails.displayName}
                        />
                      )}

                      {enableTabGochiKidSupportContinue === true && (
                        <GochiKidSupportContinue
                          NO_IMAGE_MENU={NO_IMAGE_MENU}
                          searchResults={searchResults}
                          totalResult={totalResult}
                          limit={LIMIT}
                          page={page}
                          handleChangePage={handleChangePage}
                          formatDate={formatDate}
                          handleUpdate={handleUpdate}
                          loadingGochiKidSupportContinue={loadingGochiKidSupportContinue}
                          handleShowPopupCancelSubscriptionGochiKid={handleShowPopupCancelSubscriptionGochiKid}
                          userName={userDetails && userDetails.displayName}
                        />
                      )}

                      {enableTabGochiKidSupportMessage === true && (
                        <GochiKidSupportMessage
                          NO_IMAGE_MENU={NO_IMAGE_MENU}
                          limit={LIMIT}
                          handleChangePage={handleChangePage}
                          formatDate={formatDate}
                          userName={userDetails && userDetails.displayName}
                          numberTabThankMessageDonator={numberTabThankMessageDonator}
                          handleChangeTabMessageDonatorGochiKid={handleChangeTabMessageDonatorGochiKid}
                          pageMessageDonatorGochiKid={pageMessageDonatorGochiKid}
                          totalMessageDonatorGochiKid={totalMessageDonatorGochiKid}
                          listMessageDonatorGochiKid={listMessageDonatorGochiKid}
                          pageReportShopGochiKid={pageReportShopGochiKid}
                          totalReportShopGochiKid={totalReportShopGochiKid}
                          listReportShopGochiKid={listReportShopGochiKid}
                        />
                      )}
                    </div>
                    {((enableTabGochiKidSupport && process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === "true") || (enableTabGochiKidSupportContinue && process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === "true") || (enableTabGochiKidSupportMessage && process.env.REACT_APP_SHOW_GOCHI_KID_DONATE === "true")) && (
                      <>
                        <a href={addParamUrl(GOCHI_KID_PATH + "/support/choice", [isSiteParam])} className={`btn btn-go-to-donate ${(enableTabGochiKidSupport || enableTabGochiKidSupportContinue || enableTabGochiKidSupportMessage) ? "selected" : ""}`}></a>
                        <div className="donate-hover-box"></div>
                        <div className="donate-press-box"></div>
                      </>
                    )}
                    {/* /.profile-body */}
                  </div>
                  {/* /.profile */}
                </div>
                {/* /.profile-group */}
              </div>
              {/* /.section-body */}
            </section>
            {/* プロフィール */}
          </div>
        )}

        {enableTabGochiKidSelectShop === true && (
          <GochiKidSelectShop
            NO_IMAGE_MENU={NO_IMAGE_MENU}
            searchResults={searchResults}
            totalResult={totalResult}
            limit={LIMIT}
            page={page}
            handleChangePage={handleChangePage}
            formatDate={formatDate}
            handleUpdate={handleUpdate}
            loadingGochiKidSelectShop={loadingGochiKidSelectShop}
            handShowErrorUseGochiKidPopup={handShowErrorUseGochiKidPopup}
            userDetails={userDetails}
          />
        )}

        {enableTabGochiKidSelectMenu === true && (
          <GochiKidSelectMenu
            NO_IMAGE_MENU={NO_IMAGE_MENU}
            searchResults={searchResults}
            totalResult={totalResult}
            limit={LIMIT}
            page={page}
            getShopDetails={getShopDetails}
            handleChangePage={handleChangePage}
            formatDate={formatDate}
            handleUpdate={handleUpdate}
            loadingGochiKidSelectMenu={loadingGochiKidSelectMenu}
            handleOpenInputGochiKidPriceModal={handleOpenInputGochiKidPriceModal}
            handleShowPopupAcceptPolicyGochiKidReceipt={handleShowPopupAcceptPolicyGochiKidReceipt}
            handleOpenSelectUserKidModal={handleOpenSelectUserKidModal}
            accessToken={accessToken}
            accessCode={accessCode}
            checkTokenExpired={checkTokenExpired}
            numUserKidUse={numUserKidUse}
            choiceUserKidId={choiceUserKidId}
            choiceUserKid={choiceUserKid}
            handShowErrorUseGochiKidPopup={handShowErrorUseGochiKidPopup}
            gochiKidGroupId={gochiKidGroupId}
            setGochiKidReceiptShopChoice={setGochiKidReceiptShopChoice}
          />
        )}

        {enableTabGochiChargeDetail === true && (
          <GochiChargeDetail
            setLoading={setLoading}
            NO_IMAGE_MENU={NO_IMAGE_MENU}
            gochiData={gochiData}
            userInfo={userInfo}
            formatDate={formatDate}
            fetchDataGochiChargeDetail={fetchDataGochiChargeDetail}
            handleUpdate={handleUpdate}
            loadingGochiChargeDetail={loadingGochiChargeDetail}
          />
        )}

        {enableMyPage === true && !enableTabGochiChargeDetail && (
          <>
            <aside>
              {/*Wish list*/}
              {wishList.length > 0 &&
                wishList.filter((w) => w.isWishList === true).length > 0 && (
                  <section className="section">
                    <div className="container">
                      <div className="section-header">
                        <div className="heading-lg">
                          <h2 className="title">欲しいものリスト</h2>
                        </div>
                      </div>
                      <div className="section-body">
                        {wishList.filter((w) => w.isWishList === true).length <=
                        getSlidePerView(innerWidth, swiperConfigs.products) ? (
                          <div className="swiper-container disabled" id="sliderProducts">
                            <div
                              className={`swiper-wrapper ${
                                loadingWishList ? "loading" : ""
                              }`}
                            >
                              {wishList.map((item, key) => {
                                var tmpData = { ...item };
                                tmpData.menuCombo = tmpData.menuComboDetail
                                  ? 1
                                  : 0;

                                return tmpData.isWishList ? (
                                  // <div key={key} className="swiper-slide">
                                    <MenuInfo
                                      key={key}
                                      menu={tmpData}
                                      handleUpdateWishListMenu={
                                        handleUpdateWishListMenu
                                      }
                                      handleUpdateFavoriteMenu={
                                        handleUpdateFavoriteMenu
                                      }
                                      handleUpdateWishListShop={
                                        handleUpdateWishListShop
                                      }
                                      handleUpdateFavoriteShop={
                                        handleUpdateFavoriteShop
                                      }
                                    />
                                  // </div>
                                ) : null;
                              })}
                            </div>
                          </div>
                        ) : (
                          <Swiper {...swiperConfigs.products}>
                            <div
                              className={`swiper-wrapper ${
                                loadingWishList ? "loading" : ""
                              }`}
                            >
                              {wishList.map((item, key) => {
                                var tmpData = { ...item };
                                tmpData.menuCombo = tmpData.menuComboDetail
                                  ? 1
                                  : 0;

                                return tmpData.isWishList ? (
                                  <SwiperSlide key={key}>
                                    <MenuInfo
                                      menu={tmpData}
                                      handleUpdateWishListMenu={
                                        handleUpdateWishListMenu
                                      }
                                      handleUpdateFavoriteMenu={
                                        handleUpdateFavoriteMenu
                                      }
                                      handleUpdateWishListShop={
                                        handleUpdateWishListShop
                                      }
                                      handleUpdateFavoriteShop={
                                        handleUpdateFavoriteShop
                                      }
                                    />
                                  </SwiperSlide>
                                ) : null;
                              })}
                            </div>
                            <SliderPagination />
                          </Swiper>
                        )}
                      </div>
                    </div>
                  </section>
                )}

              {/*Favorite list*/}
              {favoriteList.length > 0 &&
                favoriteList.filter((f) => f.isFavourite === true).length >
                  0 && (
                  <section className="section">
                    <div className="container">
                      <div className="section-header">
                        <div className="heading-lg">
                          <h2 className="title">お気に入り店舗・商品</h2>
                        </div>
                      </div>
                      <div className="section-body">
                        {favoriteList.filter((f) => f.isFavourite === true)
                          .length <=
                        getSlidePerView(innerWidth, swiperConfigs.products) ? (
                          <div className="swiper-container disabled" id="sliderProducts">
                            <div
                              className={`swiper-wrapper ${
                                loadingFavList ? "loading" : ""
                              }`}
                            >
                              {favoriteList.map((item, key) => {
                                var tmpData = { ...item };
                                tmpData.menuCombo = tmpData.menuComboDetail
                                  ? 1
                                  : 0;

                                return tmpData.isFavourite ? (
                                  // <div className="swiper-slide" key={key}>
                                    <MenuInfo
                                      key={key}
                                      menu={tmpData}
                                      handleUpdateWishListMenu={
                                        handleUpdateWishListMenu
                                      }
                                      handleUpdateFavoriteMenu={
                                        handleUpdateFavoriteMenu
                                      }
                                      handleUpdateWishListShop={
                                        handleUpdateWishListShop
                                      }
                                      handleUpdateFavoriteShop={
                                        handleUpdateFavoriteShop
                                      }
                                    />
                                  // </div>
                                ) : null;
                              })}
                            </div>
                          </div>
                        ) : (
                          <Swiper {...swiperConfigs.products}>
                            <div
                              className={`swiper-wrapper ${
                                loadingFavList ? "loading" : ""
                              }`}
                            >
                              {favoriteList.map((item, key) => {
                                var tmpData = { ...item };
                                tmpData.menuCombo = tmpData.menuComboDetail
                                  ? 1
                                  : 0;

                                return tmpData.isFavourite ? (
                                  <SwiperSlide key={key}>
                                    <MenuInfo
                                      menu={tmpData}
                                      handleUpdateWishListMenu={
                                        handleUpdateWishListMenu
                                      }
                                      handleUpdateFavoriteMenu={
                                        handleUpdateFavoriteMenu
                                      }
                                      handleUpdateWishListShop={
                                        handleUpdateWishListShop
                                      }
                                      handleUpdateFavoriteShop={
                                        handleUpdateFavoriteShop
                                      }
                                    />
                                  </SwiperSlide>
                                ) : null;
                              })}
                            </div>
                            <SliderPagination />
                          </Swiper>
                        )}
                      </div>
                    </div>
                  </section>
                )}
            </aside>
          </>
        )}
        {!enableTabGochiChargeDetail && (
          <ExplanationMypage />
        )}
      </main>
      {!enableTabGochiChargeDetail && (
        <Footer />
      )}

      <ModalGroup handleClosePopupDetailGochi={handleCloseModalGroup}>
        <SituationModal />
        <GiftToModal />
        <NotLoggedModal />
        <WishListModal />
        {showPopupEmail && (
          <div
            id="sendEmail"
            className={`modal modal-profile modal-profile-02 modal-02 ${
              showPopupEmail ? "is-show" : ""
            }`}
            tabIndex={-1}
          >
            <button
              className="modal-btn modal-btn-close"
              id="modalClose"
              onClick={handleClosePopupEmail}
              type="button"
            >
              <svg className="icon">
                <title>閉じる</title>
                <use xlinkHref="/assets/img/common/icon.svg#times" />
              </svg>
            </button>
            <div className="modal-header heading-lg">
              <div className="title">宛名を編集する</div>
            </div>
            <div className="modal-body">
              <div className="fieldset">
                <div className="fieldset-header">
                  {/* <div className="title">送信するメールアドレス</div> */}
                </div>
                <div className="fieldset-body">
                  <div className="field field-btn-left">
                    <input
                      type="email"
                      // placeholder="例) mail@gochi.online"
                      value={receiptAddress}
                      onChange={(e) => {
                        setReceiptAddress(e.target.value);
                      }}
                    />
                    <button
                      className="btn btn-rect"
                      type="button"
                      // onclick="openModal('sendReceipt')"
                      onClick={handleSendMail}
                    >
                      <div>変更</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {showPopupSendMailSuccess && (
          <div
            id="sendReceipt"
            className={`modal modal-profile modal-profile-05 modal-05 modal-dialog ${
              showPopupSendMailSuccess ? "is-show" : ""
            }`}
            tabIndex={-1}
          >
            <button
              className="modal-btn modal-btn-close"
              id="modalClose"
              // onclick="disappearModal()"
              onClick={handleClosePopupSendMailSuccess}
              type="button"
            >
              <svg className="icon">
                <title>閉じる</title>
                <use xlinkHref="/assets/img/common/icon.svg#times" />
              </svg>
            </button>
            <div className="modal-body">
              <p className="text-bold">送信が完了しました</p>
            </div>
          </div>
        )}

        {showPopupDetailGochi && (
          <DetailGochi
            gochiData={gochiData}
            NO_IMAGE_MENU={NO_IMAGE_MENU}
            showPopupDetailGochi={showPopupDetailGochi}
            handleClosePopupDetailGochi={handleClosePopupDetailGochi}
            handleUpdate={handleUpdate}
            loadingDetailGochi={loadingDetailGochi}
            isFromPurchaseHistory={isFromPurchaseHistory}
          />
        )}

        {showPopupHistoryChargeGochi && (
          <HistoryChargeGochi
            currentGochiBillId={currentGochiBillId}
            showPopupHistoryChargeGochi={showPopupHistoryChargeGochi}
            handleClosePopupHistoryChargeGochi={handleClosePopupHistoryChargeGochi}
            loadingHistoryCharge={loadingHistoryCharge}
            listHistoryChargeGochi={listHistoryChargeGochi}
            pageHistoryChargeGochi={pageHistoryChargeGochi}
            totalHistoryChargeGochi={totalHistoryChargeGochi}
            pageLimit={LIMIT_HISTORY_CHARGE_GOCHI}
            loadMoreListHistoryChargeGochi={loadMoreListHistoryChargeGochi}
          />
        )}

        {showPopupHistoryUsedGochiCharge && (
          <HistoryUsedGochiCharge
            currentGochiBillId={currentGochiBillId}
            showPopupHistoryUsedGochiCharge={showPopupHistoryUsedGochiCharge}
            handleClosePopupHistoryUsedGochiCharge={handleClosePopupHistoryUsedGochiCharge}
            loadingHistoryUsedGochiCharge={loadingHistoryUsedGochiCharge}
            listHistoryUsedGochiCharge={listHistoryUsedGochiCharge}
            pageHistoryUsedGochiCharge={pageHistoryUsedGochiCharge}
            totalHistoryUsedGochiCharge={totalHistoryUsedGochiCharge}
            pageLimit={LIMIT_HISTORY_USED_GOCHI_CHARGE}
            loadMoreListHistoryUsedGochiCharge={loadMoreListHistoryUsedGochiCharge}
          />
        )}

        {showPopupGochiKidUsedDetail && (
          <GochiKidUsedDetail
            item={itemGochiKidUsed}
            showPopupGochiKidUsedDetail={showPopupGochiKidUsedDetail}
            handleClosePopupGochiKidUsedDetail={handleClosePopupGochiKidUsedDetail}
            loadingGochiKidUsedDetail={loadingGochiKidUsedDetail}
            itemGochiKidUsed={itemGochiKidUsed}
            NO_IMAGE_MENU={NO_IMAGE_MENU}
            formatDate={formatDate}
            handleOpenSelectUserKidModal={handleOpenSelectUserKidModal}
            getUserKidCanUses={getUserKidCanUses}
            handShowErrorUseGochiKidPopup={handShowErrorUseGochiKidPopup}
            numUserKidUse={numUserKidUse}
            choiceUserKidId={choiceUserKidId}
            listUserKids={listUserKids}
            choiceUserKidIds={choiceUserKidIds}
            setChoiceUserKidIds={setChoiceUserKidIds}
            useAmount={gochiKidPriceUseAmount}
            setUseAmount={setGochiKidPriceUseAmount}
            thanksMessageIsNull={thanksMessageIsNullGochiKid}
            listReceiptImageGochiKid={listReceiptImageGochiKid}
            indexGochiKidUsedChoose={indexGochiKidUsedChoose}
          />
        )}

        {showPopupSendMessageGochiKid && (
          <SendMessageGochiKid
            item={itemGochiKidUsed}
            showPopupSendMessageGochiKid={showPopupSendMessageGochiKid}
            handleClosePopupSendMessageGochiKid={handleClosePopupSendMessageGochiKid}
            loadingSendMessageGochiKid={loadingSendMessageGochiKid}
            handleShowPopupSendMessageGochiKidSuccess={handleShowPopupSendMessageGochiKidSuccess}
            getUserKidGochiKid={getUserKidGochiKid}
            NO_IMAGE_MENU={NO_IMAGE_MENU}
            formatDate={formatDate}
            handShowErrorUseGochiKidPopup={handShowErrorUseGochiKidPopup}
            page={page}
          />
        )}

        {showPopupSendMessageGochiKidSuccess && (
          <SendMessageGochiKidSuccess
            showPopupSendMessageGochiKidSuccess={showPopupSendMessageGochiKidSuccess}
            handleClosePopupSendMessageGochiKidSuccess={handleClosePopupSendMessageGochiKidSuccess}
            loadingSendMessageGochiKidSuccess={loadingSendMessageGochiKidSuccess}
          />
        )}

        {showPopupCancelSubscriptionGochiKid && (
          <CancelSubscriptionGochiKid
            item={itemSubscriptionGochiKid}
            showPopupCancelSubscriptionGochiKid={showPopupCancelSubscriptionGochiKid}
            handleClosePopupCancelSubscriptionGochiKid={handleClosePopupCancelSubscriptionGochiKid}
            loadingCancelSubscriptionGochiKid={loadingCancelSubscriptionGochiKid}
            handleShowPopupCancelSubscriptionGochiKidSuccess={handleShowPopupCancelSubscriptionGochiKidSuccess}
            getGochiKidSupportContinue={getGochiKidSupportContinue}
            handShowErrorUseGochiKidPopup={handShowErrorUseGochiKidPopup}
          />
        )}

        {showPopupCancelSubscriptionGochiKidSuccess && (
          <CancelSubscriptionGochiKidSuccess
            item={itemSubscriptionGochiKid}
            showPopupCancelSubscriptionGochiKidSuccess={showPopupCancelSubscriptionGochiKidSuccess}
            handleClosePopupCancelSubscriptionGochiKidSuccess={handleClosePopupCancelSubscriptionGochiKidSuccess}
            loadingCancelSubscriptionGochiKidSuccess={loadingCancelSubscriptionGochiKidSuccess}
          />
        )}

        {showPopupRegisterUserKidGochiKid && (
          <RegisterUserKidGochiKid
            userKidData={userKidData}
            userId={props.userInfo.userId}
            showPopupRegisterUserKidGochiKid={showPopupRegisterUserKidGochiKid}
            handleClosePopupRegisterUserKidGochiKid={handleClosePopupRegisterUserKidGochiKid}
            loadingRegisterUserKidGochiKid={loadingRegisterUserKidGochiKid}
            handleShowPopupRegisterUserKidGochiKidSuccess={handleShowPopupRegisterUserKidGochiKidSuccess}
            setNumberUserKidInit={setNumberUserKidInit}
            getUserKidData={getUserKidData}
            setLoading={setLoading}
            getUserKidGochiKid={getUserKidGochiKid}
            listPrefectures={listPrefectures}
            listCities={listCities}
          />
        )}
        {showPopupRegisterUserKidGochiKidSuccess && (
          <RegisterUserKidGochiKidSuccess
            numberUserKidInit={numberUserKidInit}
            showPopupRegisterUserKidGochiKidSuccess={showPopupRegisterUserKidGochiKidSuccess}
            handleClosePopupRegisterUserKidGochiKidSuccess={handleClosePopupRegisterUserKidGochiKidSuccess}
            loadingRegisterUserKidGochiKidSuccess={loadingRegisterUserKidGochiKidSuccess}
            isRegisterUserKidError={isRegisterUserKidError}
            messageRegisterUserKidError={messageRegisterUserKidError}
          />
        )}
        {showSelectUserKidModal && (
          <SelectUserKidModal 
            isShow={showSelectUserKidModal}
            onHide={handleCloseSelectUserKidModal}
            handleOpenInputGochiKidPriceModal={handleOpenInputGochiKidPriceModal}
            handleShowPopupAcceptPolicyGochiKidReceipt={handleShowPopupAcceptPolicyGochiKidReceipt}
            listUserKids={listUserKids}
            setChoiceUserKidIds={setChoiceUserKidIds}
            choiceShopId={choiceShopId}
            choiceMenuId={choiceMenuId}
            priceChoiceMenu={priceChoiceMenu}
            setChoiceUserKids={setChoiceUserKids}
            accessToken={accessToken}
            accessCode={accessCode}
            gochiKidGroupId={gochiKidGroupId}
            gochiKidUnlimitShopChoice={gochiKidUnlimitShopChoice}
            numUserKidUse={numUserKidUse}
            gochiKidReceiptShopChoice={gochiKidReceiptShopChoice}
          />
        )}
        {showInputGochiKidPriceModal && (
          <InputGochiKidPriceModal
            isShow={showInputGochiKidPriceModal}
            onHide={handleCloseInputGochiKidPriceModal}
            choiceShopId={choiceShopId}
            choiceUserKidIds={choiceUserKidIds}
            useAmount={gochiKidPriceUseAmount}
            setUseAmount={setGochiKidPriceUseAmount}
            accessToken={accessToken}
            accessCode={accessCode}
            gochiKidGroupId={gochiKidGroupId}
            gochiKidUnlimitShopChoice={gochiKidUnlimitShopChoice}
            choiceUserKids={choiceUserKids}
            setShowExplainInputGochiKidModal={setShowExplainInputGochiKidModal}
          />
        )}
        {showPolicyGochiKidUseModal && (
          <PolicyGochiKidUse 
            showPolicyGochiKidUseModal={showPolicyGochiKidUseModal}
            handleClosePolicyGochiKidUseModal={handleClosePolicyGochiKidUseModal}
            linkRedirect={addParamUrl("/mypage/kodomo_use", [isSiteParam])}
          />
        )}
        {showPolicyGochiKidModal && (
          <PolicyGochiKid 
            showPolicyGochiKidModal={showPolicyGochiKidModal}
            handleClosePolicyGochiKidModal={handleClosePolicyGochiKidModal}
          />
        )}

        {showPolicyGochiKidCampaignModal && (
          <PolicyGochiKidCampaign
            showPolicyGochiKidCampaignModal={showPolicyGochiKidCampaignModal}
            handleClosePolicyGochiKidCampaignModal={handleClosePolicyGochiKidCampaignModal}
          />
        )}

        {showAcceptPolicyGochiKidReceiptModal && (
          <AccessPolicyGochiKidReceiptModal
            show={showAcceptPolicyGochiKidReceiptModal}
            handleClose={handleClosePopupAcceptPolicyGochiKidReceipt}
            handleOpenInputGochiKidPriceModal={handleOpenInputGochiKidPriceModal}
            choiceShopId={choiceShopId}
            choiceMenuId={choiceMenuId}
            accessToken={accessToken}
            accessCode={accessCode}
            gochiKidGroupId={gochiKidGroupId}
            gochiKidUnlimitShopChoice={gochiKidUnlimitShopChoice}
            choiceUserKidIds={choiceUserKidIds}
            gochiKidUseCodeShopChoice={gochiKidUseCodeShopChoice}
          />
        )}
      </ModalGroup>

      {/* <div className="modal-group" id="modal01">


        <div className="modal-mask" id="modalMask" />
      </div> */}

      <HowToModal isShow={showHowtoModal} onHide={() => setShowHowToModal(false)} />
      <LoadingOverlay loading={loading}/>
      <ConfirmModal
        showing={showPopupErrorGochiKidUse}
        content={errorMessageGochiKidUse}
        handleClose={() => {setShowPopupErrorGochiKidUse(false)}}
      />
      {showListGroupSpecialGochiKidModal && (
        <GroupSpecialGochiKidModal
          showing={showListGroupSpecialGochiKidModal}
          handleClose={handleCloseListGroupSpecialGochiKidModal}
          listGroupSpecialGochiKid={listGroupSpecialGochiKid}
          pageListGroupSpecialGochiKid={pageListGroupSpecialGochiKid}
          totalGroupSpecialGochiKid={totalGroupSpecialGochiKid}
          limit={LIMIT_LIST_GROUP_SPECIAL_GOCHI_KID}
          loadMore={loadMoreListGroupSpecialGochiKid}
          loadingListGroupSpecialGochiKid={loadingListGroupSpecialGochiKid}
          numberSlotCanUseAll={numberSlotCanUseAll}
          isSiteParam={isSiteParam}
          shopIdGochiKidQr={shopIdGochiKidQr}
          accessToken={accessToken}
          accessCode={accessCode}
          setLoading={setLoading}
          gochiKidGroupId={gochiKidGroupIdChoose}
          setGochiKidGroupId={setGochiKidGroupIdChoose}
        />
      )}

      {showExplainInputGochiKidModal && (
        <ExplainInputGochiKid
          showing={showExplainInputGochiKidModal}
          listUserKids={listUserKids}
          choiceUserKids={choiceUserKids}
          handleClose={() => setShowExplainInputGochiKidModal(false)}
          gochiKidUnlimitShopChoice={gochiKidUnlimitShopChoice}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({ props: state.props });

export default connect(mapStateToProps)(MyPage);
